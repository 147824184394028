<h2 mat-dialog-title class="dialog-title">
  <mat-icon class="warning-icon" *ngIf="dialogType === 'warning'">warning</mat-icon><ng-content select="[dialogTitle]"></ng-content>
</h2>
<div mat-dialog-content class="dialog-content">
  <ng-content select="[dialogContent]"></ng-content>
  <ng-content></ng-content>
</div>
<div mat-dialog-actions class="dialog-actions">
  <ng-content select="[dialogActionButton]"></ng-content>
  <ng-content select="[dialogCancelButton]"></ng-content>
</div>
