import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AscConfirmDialogComponent } from 'src/app/shared/components/asc-confirm-dialog/asc-confirm-dialog.component';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { NEXT_DEVICE_STATUS } from '../../device.constants';
import { Device, DeviceAction, NextDeviceStatusProperties } from '../../interfaces/Device';
import { DeviceActionHandler } from '../../interfaces/DeviceActionFactory';
import { DeviceApiService } from '../../services/device-api.service';
import { DeviceDetailsService } from '../../services/device-details.service';
import { AttachDeviceDialogComponent } from '../attach-device-dialog/attach-device-dialog.component';
import { UpdateDeviceDialogComponent, UpdateDialogData } from '../update-device-dialog/update-device-dialog.component';

@Component({
  selector: 'app-device-actions',
  templateUrl: './device-actions.component.html',
  styleUrls: ['./device-actions.component.css'],
})
export class DeviceActionsComponent {
  nextStatus = NEXT_DEVICE_STATUS;

  @Input() device: Device;
  constructor(
    private deviceApi: DeviceApiService,
    private toasts: ToastsService,
    private dialog: MatDialog,
    private deviceDetailsService: DeviceDetailsService,
    private router: Router
  ) {}

  getNextStatus(device: Device) {
    const nexts = this.nextStatus[device.status];
    if (nexts?.length) {
      return (nexts as NextDeviceStatusProperties<typeof device.status>[]).filter((status) => {
        if (status.action === 'ATTACH' && device.parentDeviceId) {
          return false;
        }
        if (status.action === 'DETTACH' && !device.parentDeviceId) {
          return false;
        }
        return true;
      });
    }
    return [];
  }

  deviceAction(action: DeviceAction) {
    switch (action) {
      case 'ACTIVATE':
      case 'CONNECT': {
        const handlers = DeviceActionHandler<'ACTIVATE' | 'CONNECT'>(this.deviceApi, action, this.device);
        handlers
          ?.actionHandler()
          .pipe(
            catchError((err: HttpErrorResponse) => {
              this.toasts.raise({ message: handlers.errorHandler(), title: handlers.title, error: err }, 'ERROR');
              return of();
            })
          )
          .subscribe(() => {
            this.toasts.raise({ title: handlers.title, message: handlers.successHandler() }, 'SUCCESS', false);
            this.deviceDetailsService.reloadDevice();
          });
        break;
      }
      case 'CANCEL':
      case 'DEACTIVATE':
      case 'DETTACH':
      case 'REACTIVATE':
      case 'DISCONNECT': {
        const handlers = DeviceActionHandler<'CANCEL' | 'DETTACH' | 'DISCONNECT' | 'REACTIVATE' | 'DEACTIVATE'>(this.deviceApi, action, this.device);
        const dialogRef = this.dialog.open(AscConfirmDialogComponent, handlers?.dialogConfig);
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp.status === 'SUCCESS') {
            this.deviceDetailsService.reloadDevice();
          }
        });
        break;
      }

      case 'DECOMISSION': {
        const handlers = DeviceActionHandler<'DECOMISSION'>(this.deviceApi, action, this.device);
        const dialogRef = this.dialog.open(AscConfirmDialogComponent, handlers?.dialogConfig);
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp.status === 'SUCCESS') {
            this.deviceDetailsService.reloadDevice();
            // navigate to device list if current page is device details view
            if (this.router.url.includes(this.device.id)) this.router.navigate(['devicelist']);
          }
        });
        break;
      }

      case 'ATTACH': {
        const handlers = DeviceActionHandler<'ATTACH'>(this.deviceApi, action, this.device);
        const dialogRef = this.dialog.open(AttachDeviceDialogComponent, handlers?.dialogConfig);
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'SUCCESS') {
            this.deviceDetailsService.reloadDevice();
          }
        });
        break;
      }

      case 'UPDATE': {
        const handlers = DeviceActionHandler<'UPDATE'>(this.deviceApi, action, this.device);
        const dialogRef = this.dialog.open<UpdateDeviceDialogComponent, UpdateDialogData, DialogResponse>(
          UpdateDeviceDialogComponent,
          handlers?.dialogConfig
        );
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp?.status === 'SUCCESS') {
            this.deviceDetailsService.reloadDevice();
          }
        });
      }
    }
  }
}
