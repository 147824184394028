import { Component, Input } from '@angular/core';
import { MessageCard } from '../../interfaces/MessageCard';
import { ErrorHistoryService } from '../../services/error-history.service';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.css'],
})
export class MessageCardComponent {
  @Input() data: MessageCard;

  constructor(private history: ErrorHistoryService) {}
  deleteCard() {
    this.history.deleteNotification(this.data.id);
  }
  cardToString() {
    const cardData: Partial<MessageCard> = {
      title: this.data.title,
      message: this.data.message,
      type: this.data.type,
      timestamp: this.data.timestamp,
    };
    return JSON.stringify(cardData);
  }

  async copyContent() {
    try {
      await navigator.clipboard.writeText(this.cardToString());
    } catch (error) {
      console.error(error);
    }
  }
}
