<div class="c-login-background" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">
    <img class="c-login-company-logo" src="../../assets/alconLogo.png" style="padding: 25px" />
    <div *ngIf="showError" class="c-login-error-container">
      <mat-card class="c-login-error-notice">
        <p class="c-login-error-notice-text">
          {{ errorMessage }}
        </p>
      </mat-card>
      <button mat-raised-button color="primary" style="margin-top: 25px" (click)="onNavigateToLoginClick()">Back to login page</button>
    </div>

    <div style="margin-top: 20px; font-size: small">Version: {{ ascVersion }}</div>
    <span style="display: none">Build Time: {{ ascBuildTime }}</span>
  </mat-card>
</div>
