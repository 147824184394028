import { Injectable } from '@angular/core';

type StorageValueType = 'string' | 'object' | 'array' | 'number' | 'boolean';
const STORE_INIT_KEY = 'store_init';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  storeInitialized(app?: string): boolean {
    const key = app ? `${STORE_INIT_KEY}_${app}` : STORE_INIT_KEY;
    const init = localStorage.getItem(key);
    return !!init;
  }

  setStoreInitializedDone(app?: string) {
    const key = app ? `${STORE_INIT_KEY}_${app}` : STORE_INIT_KEY;
    localStorage.setItem(key, 'done');
  }

  private createTypeKey(key: string): string {
    return `${key}_type`;
  }
  register<T = any>(key: string, type: StorageValueType, value?: T) {
    if (this.getRegisteredType(key)) {
      console.log('__Local Storage__', `Storage Item with key ${key} is already registered`);
    }
    if (!!value && typeof value !== type) {
      console.error('__Local Storage__', `Type of ${value} and type ${type} are not the same`);
      return;
    }
    localStorage.setItem(this.createTypeKey(key), type);
  }

  private getRegisteredType(key: string): { registered: boolean; type: StorageValueType | null } {
    const value = localStorage.getItem(this.createTypeKey(key));
    return { registered: !!value, type: value as StorageValueType };
  }

  deleteValue(key: string) {
    if (this.getValue(key)) {
      sessionStorage.removeItem(key);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue<T extends StorageValueType, D = any>(key: string, value: D, type?: T, register = false) {
    let latest = this.getRegisteredType(key);
    if (!latest.registered && !type && register === false) {
      console.error('__Local Storage__', `Item with ${key} is not registered`);
      return;
    }
    if (!latest.registered && type && register) {
      this.register(key, type);
      latest = this.getRegisteredType(key);
    }

    switch (latest.type) {
      case 'array':
      case 'object':
        sessionStorage.setItem(key, JSON.stringify(value));
        break;
      case 'string':
        sessionStorage.setItem(key, value as unknown as string);
        break;
      case 'number':
        sessionStorage.setItem(key, JSON.stringify(value));
        break;
      default:
        console.error('__Local Storage__', `Could not set Item with ${JSON.stringify(key)} and value ${JSON.stringify(value)}`);
        break;
    }
  }

  getValue<T = unknown>(key: string): T | null {
    const t = this.getRegisteredType(key);
    if (!t.registered) {
      console.error('__Local Storage__', `Item with ${key} is not registered`);
      return null;
    }
    const item = sessionStorage.getItem(key);
    if (!item || item === 'null') return null;
    switch (t.type) {
      case 'string':
        return item as unknown as T;
      case 'array':
      case 'object':
        return JSON.parse(item) as unknown as T;
      case 'number':
        return +item as unknown as T;
      default:
        return null;
        break;
    }
  }
}
