import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PagedResponse } from 'src/app/shared/interfaces/PagedResponse';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';
import { DeviceConnectionLogResponse } from '../interfaces/DeviceConnectionLogResponse';
import { FileUploadLogResponse } from '../interfaces/FileUploadLogResponse';
import { ParserListResponse } from '../interfaces/ParserListResponse';
import { ParserMonitoringLog } from '../interfaces/ParserMonitoringLog';
import { ParserMonitoringLogResponse } from '../interfaces/ParserMonitoringLogResponse';
import { UserActivity } from '../interfaces/UserActivity';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private httpClient: HttpClient) {}
  getDeviceActivityTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.awsApi.apiBaseUrl}/logs/devices/activityTypes`);
  }

  getParserList(pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string): Observable<ParserListResponse> {
    return this.httpClient.get<ParserListResponse>(`${environment.awsApi.apiBaseUrl}/parsers`, {
      params: createHttpParams({
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      }),
    });
  }

  deactivateParser(parserId: number): Observable<void> {
    return this.httpClient.patch<void>(`${environment.awsApi.apiBaseUrl}/parsers/${parserId}/deactivate`, null);
  }

  reactivateParser(parserId: number): Observable<void> {
    return this.httpClient.patch<void>(`${environment.awsApi.apiBaseUrl}/parsers/${parserId}/activate`, null);
  }

  getDeviceActivityLog(
    deviceId: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string
  ): Observable<DeviceConnectionLogResponse> {
    return this.httpClient.get<DeviceConnectionLogResponse>(`${environment.awsApi.apiBaseUrl}/logs/activities`, {
      params: createHttpParams({
        deviceId: deviceId,
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      }),
    });
  }
  getFileUploadLog(deviceId: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string): Observable<FileUploadLogResponse> {
    return this.httpClient.get<FileUploadLogResponse>(`${environment.awsApi.apiBaseUrl}/logs/files`, {
      params: createHttpParams({
        deviceId: deviceId,
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      }),
    });
  }
  getParsingLogsPerParser(
    parserId: number,
    deviceSerialNumber: string,
    fileName: string,
    eventTimeFrom: string,
    eventTimeUntil: string,
    eventDetectionTimeFrom: string,
    eventDetectionTimeUntil: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string
  ): Observable<PagedResponse<ParserMonitoringLog>> {
    const params = createHttpParams({
      parserId: parserId,
      deviceSerialNumber: deviceSerialNumber,
      fileName: fileName,
      from: eventTimeFrom,
      until: eventTimeUntil,
      detectionFrom: eventDetectionTimeFrom,
      detectionUntil: eventDetectionTimeUntil,
      size: pageSize,
      page: pageIndex,
      sortColumn,
      sortDirection,
    });
    return this.getParsingLogs(params);
  }
  getParsingLogs(queryParams: HttpParams): Observable<ParserMonitoringLogResponse> {
    return this.httpClient.get<ParserMonitoringLogResponse>(`${environment.awsApi.apiBaseUrl}/parsers/parsingEvents`, {
      params: queryParams,
    });
  }

  // get log parser log data per device
  getParsingLogsPerDevice(
    deviceId: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string
  ): Observable<ParserMonitoringLogResponse> {
    const params = createHttpParams({
      deviceId,
      size: pageSize,
      page: pageIndex,
      sortColumn,
      sortDirection,
    });
    return this.getParsingLogs(params);
  }

  getUserActivityTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.awsApi.apiBaseUrl}/logs/user/activityTypes`);
  }

  getUserActivityLog(
    activityType: string | null,
    deviceId: string | null,
    userName: string | null,
    from: Date | null,
    until: Date | null,
    range: number | null,
    limit: number | null
  ): Observable<UserActivity[]> {
    return this.httpClient
      .get<UserActivity[]>(`${environment.awsApi.apiBaseUrl}/logs/user`, {
        params: createHttpParams({
          activityType,
          deviceId,
          userName,
          from: from ? from.toISOString() : null,
          until: until ? until.toISOString() : null,
          range,
          limit,
        }),
      })
      .pipe(map((activities) => activities.map((activity) => ({ ...activity, timestamp: new Date(activity.timestamp) }))));
  }
}
