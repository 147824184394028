import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appClipboardCopy]',
})
export class ClipboardCopyDirective {
  copyIcon: any;
  copiedIcon: any;

  timeout: NodeJS.Timeout;

  constructor(private elemRef: ElementRef, private renderer: Renderer2) {
    this.copyIcon = this.renderer.createElement('mat-icon');
    this.renderer.appendChild(this.copyIcon, this.renderer.createText('content_copy'));
    this.renderer.addClass(this.copyIcon, 'mat-icon');
    this.renderer.addClass(this.copyIcon, 'material-icons');
    this.renderer.setStyle(this.copyIcon, 'margin-left', '5px');
    this.renderer.setStyle(this.copyIcon, 'vertical-align', 'middle');

    this.copiedIcon = this.renderer.createElement('mat-icon');
    this.renderer.appendChild(this.copiedIcon, this.renderer.createText('check'));
    this.renderer.addClass(this.copiedIcon, 'mat-icon');
    this.renderer.addClass(this.copiedIcon, 'material-icons');
    this.renderer.setStyle(this.copiedIcon, 'margin-left', '5px');
    this.renderer.setStyle(this.copiedIcon, 'vertical-align', 'middle');
  }

  @Input() appClipboardCopy: string;
  @Input() clipboardCopyDisabled = false;

  @HostListener('click', ['$event']) async onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.clipboardCopyDisabled) {
      return;
    }

    this.copyTextToclipboard(this.appClipboardCopy);
  }
  @HostListener('mouseenter') onMouseEnter() {
    if (this.clipboardCopyDisabled) {
      return;
    }
    this.addIcon(this.copyIcon);
    this.renderer.setStyle(this.elemRef.nativeElement, 'cursor', 'copy');
  }
  @HostListener('mouseleave') onMouseLeave() {
    if (this.clipboardCopyDisabled) {
      return;
    }
    this.renderer.removeChild(this.elemRef.nativeElement, this.copyIcon);
    this.renderer.removeChild(this.elemRef.nativeElement, this.copiedIcon);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.renderer.setStyle(this.elemRef.nativeElement, 'cursor', 'default');
  }

  async copyTextToclipboard(toCopy: string) {
    try {
      await navigator.clipboard.writeText(toCopy);
      this.renderer.removeChild(this.elemRef.nativeElement, this.copyIcon);
      this.addIcon(this.copiedIcon);
      this.timeout = setTimeout(() => {
        this.renderer.removeChild(this.elemRef.nativeElement, this.copiedIcon);
        this.addIcon(this.copyIcon);
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  }

  addIcon(icon: any) {
    if (this.clipboardCopyDisabled) {
      return;
    }
    const computedStyle = getComputedStyle(this.elemRef.nativeElement);
    const fontSize = computedStyle.getPropertyValue('font-size');
    this.renderer.setStyle(icon, 'width', fontSize);
    this.renderer.setStyle(icon, 'height', fontSize);
    this.renderer.setStyle(icon, 'font-size', fontSize);
    this.renderer.appendChild(this.elemRef.nativeElement, icon);
  }
}
