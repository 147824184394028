import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ErrorHistoryService } from 'src/app/error-handling/services/error-history.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../authentication/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  idleState = 'NOT_IDLE';
  secondsRemaining = environment.inactivityTimeout;
  tooltipTimer: NodeJS.Timeout;
  public username = this.auth.getUsername();
  timeOut: Observable<number>;

  @Output() toggleToastHistory = new EventEmitter();

  constructor(
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    public historyService: ErrorHistoryService
  ) {}

  ngOnInit(): void {
    // set idle parameters
    this.idle.setIdle(5); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(environment.inactivityTimeout); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.secondsRemaining = environment.inactivityTimeout;
      this.cd.detectChanges();
    });
    this.idle.onTimeout.subscribe(async () => {
      this.idleState = 'TIMED_OUT';
      this.auth.oktaLogout();
    });
    this.timeOut = this.idle.onTimeoutWarning;

    this.idle.watch();
  }
  historyButtonClicked() {
    this.toggleToastHistory.emit();
    this.historyService.toggleHistory();
  }

  ngOnDestroy(): void {
    if (this.tooltipTimer) {
      clearTimeout(this.tooltipTimer);
    }
    this.idle.stop();
    this.toastr.clear();
  }

  async logOut(): Promise<void> {
    await this.auth.oktaLogout();
  }

  deviceTypeMgmtBtnClick(): void {
    this.router.navigate(['devicetypemanagement']);
  }

  softwareLibraryBtnClick(): void {
    this.router.navigate(['softwarelibrary']);
  }

  parserMgmtBtnClick(): void {
    this.router.navigate(['logparsers']);
  }

  ongoingUploadsBtnClick(): void {
    this.router.navigate(['ongoinguploads']);
  }

  aboutBtnClick(): void {
    this.router.navigate(['about']);
  }

  goHome(): void {
    this.router.navigate(['devicelist']);
  }

  toNewLifecycle() {
    this.router.navigate(['devices-new-lifecycle']);
  }
  failedJobs(): void {
    this.router.navigate(['failedjobs']);
  }

  userActivitiesBtnClick() {
    this.router.navigate(['user-activities']);
  }
}
