import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable, catchError, finalize, of, switchMap, throwError } from 'rxjs';
import { PreSignedUrlResponse } from 'src/app/device-file/interfaces/PreSignedUrlResponse';
import { FileApiService } from 'src/app/device-file/services/file-api.service';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { Device } from '../../interfaces/Device';
import { DeviceDownloadableContentDataSource } from '../../interfaces/DeviceDownloadableContentDataSource';
import { DownloadableContentClass } from '../../interfaces/DownloadableContent';
import { DownloadableContentDelivery } from '../../interfaces/DownloadableContentDelivery';
import { DeviceApiService } from '../../services/device-api.service';

@Component({
  selector: 'app-device-downloadable-content',
  templateUrl: './device-downloadable-content.component.html',
  styleUrls: ['./device-downloadable-content.component.css'],
})
export class DeviceDownloadableContentComponent implements OnInit, OnDestroy, StorageOperations {
  searchInput = new FormControl<string>('');

  columns: ConfigureableTableColumn<DownloadableContentClass>[] = [
    {
      sortable: true,
      name: 'downloadableContent.displayName',
      header: 'Display Name',
    },
    {
      sortable: true,
      name: 'downloadableContent.fileName',
      header: 'File Name',
    },
    {
      sortable: true,
      name: 'downloadableContent.fileType',
      header: 'File Type',
    },
    {
      sortable: true,
      name: 'downloadableContent.fileVersion',
      header: 'Version',
    },
    {
      sortable: true,
      name: 'downloadableContent.fileSize',
      header: 'Size',
    },
    {
      sortable: false,
      name: 'action',
      header: 'Assigned',
    },
  ];

  dataSource: DeviceDownloadableContentDataSource;

  sort: Sort;
  pageables: ConfigPageEvent;

  disableToggle = false;

  searchControl = new FormControl('', { nonNullable: true });
  caseNumber = new FormControl<string | null>('');

  @Input() device: Device;
  constructor(private deviceApi: DeviceApiService, private store: AppStorageService, private fileApi: FileApiService, private toasts: ToastsService) {
    this.dataSource = new DeviceDownloadableContentDataSource(this.deviceApi, this.fileApi);
    this.getStorageData();
  }
  getStorageData() {
    this.sort = {
      active: this.store.getValue('device_downloadablecontent_sort_column') || '',
      direction: this.store.getValue('device_downloadablecontent_sort_direction') || '',
    };
    this.pageables = {
      pageIndex: 0,
      pageSize: this.store.getValue('device_downloadablecontent_pagination_pagesize') || 10,
      pageSizeOptions: [10, 20, 50],
    };
  }

  ngOnInit(): void {
    if (this.device) {
      this.loadContent();
    }
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  pagChange(pag: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageIndex: pag.pageIndex,
      pageSize: pag.pageSize,
    };
    this.loadContent();
  }
  sortChange(sort: Sort) {
    this.sort = {
      ...this.sort,
      active: sort.active,
      direction: sort.direction,
    };
    this.loadContent();
  }

  loadContent() {
    this.dataSource.loadDownloadableContent(
      this.searchControl.value,
      this.device.id,
      this.pageables.pageSize,
      this.pageables.pageIndex,
      this.sort?.active && this.sort.direction ? this.sort.active : '',
      this.sort?.direction || ''
    );
  }

  saveStorageData() {
    this.store.setValue('device_downloadablecontent_sort_column', this.sort.active, 'string', true);
    this.store.setValue('device_downloadablecontent_sort_direction', this.sort.direction, 'string', true);
    this.store.setValue('device_downloadablecontent_pagination_pagesize', this.pageables.pageSize, 'number', true);
  }

  toggled(dlc: DownloadableContentClass & { assigned: boolean }) {
    if (dlc.assigned) {
      this.revoke(dlc);
    } else {
      this.deliver(dlc);
    }
  }

  revoke(dlc: DownloadableContentClass & { assigned: boolean }) {
    this.disableToggle = true;
    this.deviceApi
      .revokeDownloadableContentDelivery(dlc.fileUuid, this.device.serialNumber, this.device.deviceType, this.caseNumber.value)
      .pipe(
        catchError((err) => {
          this.toasts.raise({ message: `Could not revoke downloadable content`, error: err, title: 'Revoke Downloadable Content' }, 'ERROR');
          dlc.assigned = true;
          return of();
        }),
        finalize(() => {
          this.disableToggle = false;
        })
      )
      .subscribe(() => {
        this.loadContent();
      });
  }

  deliver(dlc: DownloadableContentClass & { assigned: boolean }) {
    this.disableToggle = true;
    const downloadableContentDelivery: DownloadableContentDelivery = {
      deviceIds: [this.device.id],
    };
    this.deviceApi
      .deliverDownloadableContent(dlc.fileUuid, downloadableContentDelivery, this.caseNumber.value)
      .pipe(
        catchError((err) => {
          this.toasts.raise({ message: `Could not deliver downloadable content`, error: err, title: 'Deliver Downloadable Content' }, 'ERROR');
          dlc.assigned = false;
          return of();
        }),
        finalize(() => {
          this.disableToggle = false;
        })
      )
      .subscribe(() => {
        this.loadContent();
      });
  }

  downloadFile(dlc: DownloadableContentClass & { assigned: boolean; downloading: boolean }) {
    dlc.downloading = true;
    this.getDownloadUrl(dlc.fileUuid).subscribe(async (resp) => {
      if (resp === null) {
        dlc.downloading = false;
        return;
      }
      const dwnUrl = resp.preSignedUrl === null ? '' : resp.preSignedUrl;
      const link = document.createElement('a');
      link.href = dwnUrl;
      link.download = dwnUrl.substring(dwnUrl.lastIndexOf('/') + 1);
      try {
        const dlcAvailable = await fetch(link.href, { method: 'get' });
        if (dlcAvailable.status === 200) {
          link.click();
          this.toasts.raise({ title: 'Download started', message: 'Please check your download folder' }, 'SUCCESS', false);
          setTimeout(() => {
            dlc.downloading = false;
          }, 2000);
        } else if (dlcAvailable.status === 404) {
          this.toasts.raise({ title: 'Download file', message: 'Could not find download URL' }, 'ERROR');
          dlc.downloading = false;
        } else if (dlcAvailable.status !== 200) {
          this.toasts.raise({ title: 'Download file', message: 'Could not download file' }, 'ERROR');
          dlc.downloading = false;
        }
      } catch (error) {
        dlc.downloading = false;
        this.toasts.raise({ title: 'Download file', message: 'Could not download file', error: error as HttpErrorResponse }, 'ERROR');
      }
    });
  }

  getDownloadUrl(dlcFileUuid: string): Observable<PreSignedUrlResponse | null> {
    return this.fileApi.getFileByDownloadableContentFileUuid(dlcFileUuid, this.device.id).pipe(
      switchMap((resp) =>
        resp.content && resp.content.length > 0
          ? this.fileApi.getPreSignedUrlForDownload(resp.content[0].fileId)
          : throwError(() => new Error(`No downloadable file with ID ${dlcFileUuid} for device with ID ${this.device.id}`))
      ),
      catchError((err: HttpErrorResponse | Error) => {
        this.toasts.raise({ title: 'Download file', message: 'Could not get dowload URL', error: err }, 'ERROR');
        return of(null);
      })
    );
  }

  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
