export function dateToTimeString(date: Date | null | undefined): string | null {
  return date ? date.toTimeString().split(' ')[0] : null;
}

export function timeStringToDate(time: string | null | undefined): Date | null {
  return time
    ? time
        .split(':')
        .filter((_, index) => index < 3)
        .reduce((prev: Date, curr: string, index: number) => {
          if (index === 0) prev.setHours(+curr);
          else if (index === 1) prev.setMinutes(+curr);
          else if (index === 2) prev.setSeconds(+curr);
          return prev;
        }, new Date())
    : null;
}

export const timePattern = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/; // matches hh:mm:ss
export const isoDatePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[01])$/; // matches yyyy-MM-dd
