import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { ISO_DATE_FORMAT } from 'src/app/utils/date-formats';
import { ParserLogParserMonitoringDataSource } from '../../interfaces/ParserLogParserMonitoringDataSource';
import { ParserMonitoringLog } from '../../interfaces/ParserMonitoringLog';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-log-parser-monitoring',
  templateUrl: './log-parser-monitoring.component.html',
  styleUrls: ['./log-parser-monitoring.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMAT },
  ],
})
export class LogParserMonitoringComponent implements OnInit, OnDestroy, StorageOperations {
  dataSource: ParserLogParserMonitoringDataSource;
  pageables: ConfigPageEvent;
  sort: Sort;

  filterForm: FormGroup<ParsingEventFilterForm> = new FormGroup<ParsingEventFilterForm>({
    deviceSerialNumber: new FormControl(),
    fileName: new FormControl(),
    fromEventDate: new FormControl(),
    toEventDate: new FormControl(),
    fromEventDetectionDate: new FormControl(),
    toEventDetectionDate: new FormControl(),
  });

  columns: ConfigureableTableColumn<ParserMonitoringLog>[] = [
    {
      sortable: true,
      name: 'id',
      header: 'Event ID',
    },
    {
      sortable: true,
      name: 'deviceSerialNumber',
      header: 'Serial number',
    },
    {
      sortable: true,
      name: 'fileName',
      header: 'File Name',
    },
    {
      sortable: true,
      name: 'code',
      header: 'Code',
    },
    {
      sortable: false,
      name: 'message',
      header: 'Message',
    },
    {
      sortable: true,
      name: 'eventType',
      header: 'Event Type',
    },
    {
      sortable: true,
      name: 'eventTimestamp',
      header: 'Event Timestamp',
    },
    {
      sortable: true,
      name: 'eventDetectionTimestamp',
      header: 'Event Detection Timestamp',
    },
  ];

  parserId: number;
  paramSub$ = new Subscription();

  constructor(private loggingApi: LoggingService, private store: AppStorageService, private activeRoute: ActivatedRoute) {
    this.dataSource = new ParserLogParserMonitoringDataSource(this.loggingApi);
    this.getStorageData();
    window.onbeforeunload = () => this.saveStorageData();

    this.paramSub$ = this.activeRoute.paramMap.subscribe((params) => {
      this.parserId = Number(params.get('parserId'));
      this.loadParsingEvents();
    });

    this.listenControlChanges();
  }

  ngOnInit(): void {
    this.loadParsingEvents();
  }

  listenControlChanges() {
    this.filterForm.controls.fromEventDate.valueChanges.subscribe(() => {
      this.loadParsingEvents();
    });
    this.filterForm.controls.toEventDate.valueChanges.subscribe(() => {
      this.loadParsingEvents();
    });
    this.filterForm.controls.fromEventDetectionDate.valueChanges.subscribe(() => {
      this.loadParsingEvents();
    });
    this.filterForm.controls.toEventDetectionDate.valueChanges.subscribe(() => {
      this.loadParsingEvents();
    });
  }

  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('parser_monitoring_sort_column') || '',
      direction: this.store.getValue('parser_monitoring_sort_direction') || '',
    };

    this.pageables = {
      pageSize: this.store.getValue('parser_monitoring_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }

  loadParsingEvents() {
    this.dataSource.loadMonitoringLog(
      this.parserId,
      this.filterForm.controls.deviceSerialNumber.value || '',
      this.filterForm.controls.fileName.value || '',
      this.filterForm.controls.fromEventDate.value?.toISOString() || '',
      this.filterForm.controls.toEventDate.value?.toISOString() || '',
      this.filterForm.controls.fromEventDetectionDate.value?.toISOString() || '',
      this.filterForm.controls.toEventDetectionDate.value?.toISOString() || '',
      this.pageables.pageSize,
      this.pageables.pageIndex,
      this.sort?.active && this.sort.direction ? this.sort.active : '',
      this.sort?.direction || ''
    );
  }

  pageChange(evt: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageIndex: evt.pageIndex,
      pageSize: evt.pageSize,
    };
    if (this.filterForm.valid) this.loadParsingEvents();
  }

  sortChange(evt: Sort) {
    this.sort = {
      active: evt.active || '',
      direction: evt.direction || '',
    };
    if (this.filterForm.valid) this.loadParsingEvents();
  }

  saveStorageData(): void {
    this.store.setValue('parser_monitoring_sort_column', this.sort.active);
    this.store.setValue('parser_monitoring_sort_direction', this.sort.direction);
    this.store.setValue('parser_monitoring_pagination_pagesize', this.pageables.pageSize);
  }

  ngOnDestroy(): void {
    this.saveStorageData();
    this.paramSub$.unsubscribe();
  }

  clearData(type?: 'SERIAL_NUMBER') {
    if (type === 'SERIAL_NUMBER') {
      this.filterForm.controls.deviceSerialNumber.setValue(null);
    }
    this.filterForm.controls.fromEventDate.setValue(null);
    this.filterForm.controls.toEventDate.setValue(null);
    this.filterForm.controls.fromEventDetectionDate.setValue(null);
    this.filterForm.controls.toEventDetectionDate.setValue(null);
    this.filterForm.controls.fileName.setValue(null);

    this.dataSource.clearData();
  }
}

interface ParsingEventFilterForm {
  deviceSerialNumber: FormControl<string | null>;
  fileName: FormControl<string | null>;
  fromEventDate: FormControl<Date | null>;
  toEventDate: FormControl<Date | null>;
  fromEventDetectionDate: FormControl<Date | null>;
  toEventDetectionDate: FormControl<Date | null>;
}
