import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/error-handling/services/error-handler.service';
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { MessageCardType } from '../interfaces/MessageCard';
import { errorMessageFormatter } from '../utils/error-message-transformer';

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  toastQueue$ = new BehaviorSubject<{ toast: ActiveToast<unknown>; type: MessageCardType; errorResponse?: ErrorResponse } | null>(null);
  constructor(private toastr: ToastrService, private errorHandler: ErrorHandlerService) {}

  /**
   *
   * @param props message, title and potential error displayed in toast
   * @param type type of toast: INFO | WARNING | SUCCESS | ERROR
   * @param toQueue if true (default) adds the toast to the queue and adds it to the toast history (set false for less important toast messages)
   * @returns
   */
  raise(
    props: { message: string; title: string; error?: HttpErrorResponse | Error },
    type: MessageCardType,
    toQueue = true
  ): { toast: ActiveToast<unknown>; type: MessageCardType } {
    const errorResponse: ErrorResponse | undefined = props.error ? this.errorHandler.httpErrorToErrorResponse(props.error) : undefined;
    const ctxMessage = errorMessageFormatter(errorResponse?.message || props.message, ...(errorResponse?.parameters || []));
    let ref = null;
    switch (type) {
      case 'SUCCESS':
        ref = { toast: this.toastr.success(ctxMessage, props.title, { timeOut: 2000, extendedTimeOut: 1000, tapToDismiss: true }), type: type };
        break;
      case 'ERROR':
        ref = { toast: this.toastr.error(ctxMessage, props.title, { timeOut: 2000, extendedTimeOut: 1000, tapToDismiss: true }), type: type };
        break;
      case 'WARNING':
        ref = { toast: this.toastr.warning(ctxMessage, props.title, { timeOut: 2000, extendedTimeOut: 1000, tapToDismiss: true }), type: type };
        break;
      case 'INFO':
        ref = { toast: this.toastr.info(ctxMessage, props.title, { timeOut: 2000, extendedTimeOut: 1000, tapToDismiss: true }), type: type };
        break;
      default:
        ref = { toast: this.toastr.info(ctxMessage, props.title, { timeOut: 3000, extendedTimeOut: 1000, tapToDismiss: true }), type: type };
        break;
    }
    if (toQueue) this.toastQueue$.next({ ...ref, errorResponse });
    return ref;
  }
}
