import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, catchError, finalize, map, of, shareReplay } from 'rxjs';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { DialogDefaultActions } from 'src/app/shared/interfaces/DialogDefaultActions';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { DeviceType } from '../../interfaces/DeviceType';
import { DeviceApiService } from '../../services/device-api.service';

@Component({
  selector: 'app-provision-device-dialog',
  templateUrl: './provision-device-dialog.component.html',
  styleUrls: ['./provision-device-dialog.component.css'],
})
export class ProvisionDeviceDialogComponent implements DialogDefaultActions {
  deviceTypeControl = new FormControl<DeviceType | null>(null, [Validators.required]);

  loading = false;

  deviceTypeProps: AscAutocompleteProperties<DeviceType> = {
    humanizeOption: (val) => val.deviceType,
    multiple: false,
    equals: (a, b) => a.deviceTypeId === b.deviceTypeId,
    filterPredicate: (val, search) => val.deviceType.toLowerCase().includes(search.toLowerCase()),
    errorMessage: 'Enter a valid Device type',
    label: 'Device Type',
  };

  formGroup: FormGroup<ProvisionFormGroup> = new FormGroup<ProvisionFormGroup>({
    serialNumber: new FormControl('', { nonNullable: true, validators: Validators.required }),
    deviceType: new FormControl<DeviceType | null>(null, [Validators.required]),
    updateDhs: new FormControl<boolean>(false, { nonNullable: true }),
    allowRemoteDesktopAccess: new FormControl<boolean>(false, { nonNullable: true }),
    trainingDevice: new FormControl<boolean>(false, { nonNullable: true }),
    note: new FormControl('', { nonNullable: true }),
  });

  deviceTypes$: Observable<DeviceType[]>;

  constructor(
    private dialogRef: MatDialogRef<ProvisionDeviceDialogComponent, Partial<DialogResponse<unknown>>>,
    private apiService: SharedApiService,
    private deviceApi: DeviceApiService,
    private toasts: ToastsService
  ) {
    this.deviceTypes$ = this.apiService.getDeviceTypeList().pipe(
      shareReplay(),
      map((resp) => {
        if (!resp.content) {
          this.toasts.raise({ title: 'Get Device Types', message: 'No device types available, add one or more device types' }, 'WARNING');
          return [];
        }
        return resp.content;
      }),
      catchError((err) => {
        this.toasts.raise({ title: 'Get Device Types', message: 'Can not get device types', error: err }, 'ERROR');
        return of();
      })
    );
    this.listenDeviceTypeChange();
  }
  listenDeviceTypeChange() {
    this.formGroup.controls.deviceType.valueChanges.subscribe((dType) => {
      this.formGroup.controls.updateDhs.setValue(dType?.updateDhs || false);
    });
  }
  actionButtonClicked(): void {
    this.loading = true;
    this.deviceApi
      .provisionDevice(
        this.formGroup.controls.deviceType.value?.deviceType || '',
        this.formGroup.controls.serialNumber.value,
        this.formGroup.controls.note.value,
        this.formGroup.controls.updateDhs.value,
        this.formGroup.controls.allowRemoteDesktopAccess.value,
        this.formGroup.controls.trainingDevice.value
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.toasts.raise(
            {
              title: 'Provision a new Device',
              message: `Could not Provision device ${this.formGroup.controls.deviceType.value?.deviceType} • ${this.formGroup.controls.serialNumber.value}`,
              error: err,
            },
            'ERROR'
          );

          this.dialogRef.close();
          return of();
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.toasts.raise(
          {
            message: `Provisioned device ${this.formGroup.controls.serialNumber.value} • ${this.formGroup.controls.deviceType.value?.deviceType}`,
            title: 'Provision a new Device',
          },
          'SUCCESS',
          false
        );
        this.dialogRef.close({ code: 201, status: 'SUCCESS' });
      });
  }
  closeButtonClicked(): void {
    this.dialogRef.close({ status: 'CANCEL' });
  }
}

interface ProvisionFormGroup {
  serialNumber: FormControl<string>;
  deviceType: FormControl<DeviceType | null>;
  updateDhs: FormControl<boolean>;
  allowRemoteDesktopAccess: FormControl<boolean>;
  trainingDevice: FormControl<boolean>;
  note: FormControl<string>;
}
