import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivationStatus, DownloadableContent } from 'src/app/shared/interfaces/DownloadableContent';
import { PagedResponse } from 'src/app/shared/interfaces/PagedResponse';
import { DownloadableContentCountPerDevice } from 'src/app/software-management/interfaces/DownloadableContentPerDevice';
import { UploadGetPartResponse } from 'src/app/software-management/interfaces/UploadGetPartResponse';
import { UploadInitRequest } from 'src/app/software-management/interfaces/UploadInitRequest';
import { UploadInitResponse } from 'src/app/software-management/interfaces/UploadInitResponse';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SoftwareManagementService {
  constructor(private httpClient: HttpClient) {}

  getDownloadableContentCountPerDevice(
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string,
    deviceId: string
  ): Observable<PagedResponse<DownloadableContentCountPerDevice>> {
    const params = createHttpParams({
      size: pageSize,
      page: pageIndex,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      deviceId,
    });

    return this.httpClient.get<PagedResponse<DownloadableContentCountPerDevice>>(`${environment.awsApi.apiBaseUrl}/downloadableContent/devices`, {
      params,
    });
  }

  patchDownloadableContent(fileUuid: string, activationStatus: ActivationStatus): Observable<DownloadableContent> {
    return this.httpClient.patch<DownloadableContent>(encodeURI(`${environment.awsApi.apiBaseUrl}/downloadableContent/${fileUuid}`), {
      status: activationStatus,
    });
  }
  // gets the list of software library files
  getDownloadableContentList(
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string,
    deviceType?: string,
    fileUuid?: string
  ): Observable<PagedResponse<DownloadableContent>> {
    const params = createHttpParams({
      size: pageSize,
      page: pageIndex,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      deviceType: deviceType,
      fileUuid: fileUuid,
    });

    return this.httpClient.get<PagedResponse<DownloadableContent>>(`${environment.awsApi.apiBaseUrl}/downloadableContent`, { params });
  }
  updateDownloadableContent(downloadableContent: DownloadableContent): Observable<unknown> {
    return this.httpClient.put(
      encodeURI(`${environment.awsApi.apiBaseUrl}/downloadableContent/${downloadableContent.fileUuid}`),
      downloadableContent
    );
  }

  initDownloadableContentUpload(uploadInitRequest: UploadInitRequest): Observable<UploadInitResponse> {
    return this.httpClient.post<UploadInitResponse>(`${environment.awsApi.apiBaseUrl}/downloadableContent/upload/init`, uploadInitRequest);
  }

  getDownloadableContentUploadPartUrl(
    fileUuid: string,
    uploadId: string,
    filePart: number,
    partSize: number,
    partChecksum: string
  ): Observable<UploadGetPartResponse> {
    return this.httpClient.get<UploadGetPartResponse>(`${environment.awsApi.apiBaseUrl}/downloadableContent/upload/part`, {
      params: createHttpParams({
        fileUuid,
        uploadId,
        filePart,
        partSize,
        partChecksum,
      }),
    });
  }

  completeDownloadableContentUpload(fileUuid: string, uploadId: string, downloadableContent: DownloadableContent): Observable<DownloadableContent> {
    return this.httpClient.post<DownloadableContent>(`${environment.awsApi.apiBaseUrl}/downloadableContent/upload/complete`, downloadableContent, {
      params: createHttpParams({
        fileUuid,
        uploadId,
      }),
    });
  }

  uploadDownloadableContentPart(preSignedUrl: string, file: File, checksum: string): Observable<HttpEvent<object>> {
    return this.httpClient.put(preSignedUrl, file, {
      headers: {
        'x-no-auth': 'true',
        'x-amz-sdk-checksum-algorithm': 'CRC32',
        'x-amz-checksum-crc32': checksum,
      },
      reportProgress: true,
      observe: 'events',
    });
  }
  deleteDownloadableContent(downloadableContent: DownloadableContent): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/downloadableContent/${downloadableContent.fileUuid}`));
  }
}
