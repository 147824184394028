import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { DeviceType } from './DeviceType';

export class DeviceTypeDataSource extends DataSourceBaseImpl<DeviceType> {
  constructor(private sharedApi: SharedApiService) {
    super();
  }

  loadDeviceTypes(deviceType: string, searchText: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string) {
    this.loadingSubject.next(true);
    this.sharedApi
      .getDeviceTypeList(deviceType, searchText, pageSize, pageIndex, sortColumn, sortDirection)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((resp) => {
        if (resp.content) {
          this.dataSubject.next(resp.content);
        }
        this.totalSizeSubject.next(resp.totalSize);
      });
  }
}
