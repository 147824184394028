<app-asc-dialog-wrapper type="default">
  <ng-container dialogTitle>Edit Device {{ data.device.serialNumber }} • {{ data.device.deviceType }}</ng-container>
  <ng-container dialogContent>
    <div>
      <mat-checkbox [formControl]="formGroup.controls.updateDhs" style="margin: 10px 0"> Update DHS </mat-checkbox><br />
      <mat-checkbox [formControl]="formGroup.controls.allowRemoteDesktopAccess" style="margin: 10px 0"> Allow Remote Access </mat-checkbox>
      <app-asc-text-input
        class="input-field"
        type="textarea"
        [control]="formGroup.controls.note"
        name="noteIn"
        placeholder="Note..."
        label="Note"
      ></app-asc-text-input>
    </div>
    <div class="loading-indicator-wrapper">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  </div>
  </ng-container>
  <app-asc-button
    class="action-button"
    dialogActionButton
    (btnClicked)="actionButtonClicked()"
    [disabled]="formGroup.invalid || loading || formGroup.pristine"
    >Save</app-asc-button
  >

  <app-asc-button class="action-button" dialogCancelButton type="cancel" (btnClicked)="closeButtonClicked()">Cancel</app-asc-button>
</app-asc-dialog-wrapper>
