<app-asc-dialog-wrapper type="default">
  <ng-container dialogTitle>Provision a new Device</ng-container>
  <ng-container dialogContent>
    <div>
      <app-asc-text-input
        class="input-field"
        [control]="formGroup.controls.serialNumber"
        placeholder="Enter Serial Number..."
        label="Serial Number"
        errorMessage="Enter a valid serial number"
        [clear]="true"
      >
      </app-asc-text-input>

      <app-asc-autocomplete [data]="deviceTypes$" [properties]="deviceTypeProps" [control]="formGroup.controls.deviceType"></app-asc-autocomplete>
      <mat-checkbox [formControl]="formGroup.controls.updateDhs" style="margin: 10px 0"> Update DHS </mat-checkbox><br />
      <mat-checkbox [formControl]="formGroup.controls.allowRemoteDesktopAccess" style="margin: 10px 0"> Allow Remote Access </mat-checkbox><br />
      <mat-checkbox [formControl]="formGroup.controls.trainingDevice" style="margin: 10px 0"> Training Device </mat-checkbox>

      <app-asc-text-input
        class="input-field"
        type="textarea"
        [control]="formGroup.controls.note"
        name="noteIn"
        placeholder="Note..."
        label="Note"
      ></app-asc-text-input>
    </div>
  </ng-container>
  <app-asc-button dialogActionButton (btnClicked)="actionButtonClicked()" [disabled]="formGroup.invalid || loading">Provision</app-asc-button>
  <app-asc-button dialogCancelButton type="cancel" (btnClicked)="closeButtonClicked()">Cancel</app-asc-button>
</app-asc-dialog-wrapper>
