<sub-header label="About"> </sub-header>

<div class="c-flex-wrapper">
  <mat-spinner *ngIf="isLoading && !isError" class="c-loading-spinner"></mat-spinner>

  <div class="c-main-container" [hidden]="isLoading">
    <div style="margin: 0 100px 25px 100px">
      <span>Service Cloud server version: {{ ascVersion }}</span>
      <br />
      <span>Service Cloud UI version: {{ frontendVersion }}</span>
      <br />
      <span>Parsing Controller version: {{ parsingControllerVersion || 'N/A' }}</span>
    </div>
  </div>
</div>
