import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { Device } from '../interfaces/Device';
import { DeviceApiService } from './device-api.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetailsService {
  public currentDeviceId = new BehaviorSubject<string>('');

  public getDeviceError$ = new BehaviorSubject<Error | null>(null);

  public currentDevice = new BehaviorSubject<Device | null>(null);

  public reloadDevice$ = new BehaviorSubject<null | 'RELOAD'>(null);

  constructor(private deviceApi: DeviceApiService) {}

  loadDevice(deviceId: string) {
    this.currentDeviceId.next(deviceId);
    this.deviceApi
      .getDevice(deviceId)
      .pipe(
        catchError((err) => {
          this.getDeviceError$.next(err);
          return of();
        })
      )
      .subscribe((resp) => {
        this.currentDevice.next(resp);
      });
  }

  reloadDevice() {
    this.reloadDevice$.next('RELOAD');
    this.reloadDevice$.next(null);
  }
}
