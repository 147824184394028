import { AbstractControl } from '@angular/forms';

export const minLengthArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: any } | null => {
    if (!c.value) {
      return { minLengthArray: { valid: false } };
    }
    if (c.value.length >= min) {
      return null;
    }
    return { minLengthArray: { valid: false } };
  };
};
