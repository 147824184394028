import { Component, Input, OnInit } from '@angular/core';
import { Device } from '../../interfaces/Device';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeviceApiService } from '../../services/device-api.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../../error-handling/services/error-handler.service';

@Component({
  selector: 'app-device-remote-desktop',
  templateUrl: './device-remote-desktop.component.html',
  styleUrls: ['./device-remote-desktop.component.css'],
})
export class DeviceRemoteDesktopComponent {
  @Input() device: Device;

  constructor(private deviceService: DeviceApiService, private errorHandlerService: ErrorHandlerService, private toastr: ToastrService) {}

  toggleAllowRemoteDesktopAccess(event: MatCheckboxChange): void {
    this.device.allowRemoteDesktopAccess = event.checked;
    this.deviceService.updateDevice(this.device, 'v2').subscribe({
      next: () => this.toastr.success(`Remote desktop access is now ${this.device.allowRemoteDesktopAccess ? 'enabled' : 'disabled'}`),
      error: (error) => this.errorHandlerService.handleHttpError(error, 'Could not update device'),
    });
  }
}
