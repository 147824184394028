<sub-header [label]="'Assign ' + ((dlc | async)?.displayName || 'Downloadable Content')"></sub-header>

<div class="search-props">
  <app-asc-autocomplete [properties]="optionControlProps" [data]="optionData" [control]="optionControl"
    [clear]="false"></app-asc-autocomplete>
  <app-asc-text-input prefixIcon="search" placeholder="Search Device" [clear]="true" (keyupEnter)="loadData()"
    [control]="searchControl" (cleared)="loadData()"></app-asc-text-input>
  <app-asc-text-input [placeholder]="'Case Number'" [control]=" caseNumber"
    [appAscTooltip]="'Authorize assign or revoke by a case number'"></app-asc-text-input>
  <app-asc-inline-button [disabled]="selection.isEmpty() || inAction" *ngIf="optionControl.value === 'Not Delivered'"
    (btnClicked)="assignDlc(caseNumber.value)">
    Assign Content
  </app-asc-inline-button>
</div>

<app-asc-configurable-table [pageables]="pageables" (paginatorChange)="onPagChange($event)" [sortProps]="sort"
  (sortChange)="onSortChange($event)" [dataSource]="dataSource" [columns]="columns"
  [columnCells]="[selectTemp, deviceDtTemp, deviceSnTemp]" [headerCells]="[{ index: 0, template: firstHeader }]">
</app-asc-configurable-table>

<ng-template #deviceDtTemp let-tableElem="tableElem">
  <div>{{ tableElem.deviceType }}</div>
</ng-template>

<ng-template #deviceSnTemp let-tableElem="tableElem">
  <div>{{ tableElem.serialNumber }}</div>
</ng-template>

<ng-template #selectTemp let-tableElem="tableElem">
  <mat-checkbox *ngIf="optionControl.value === 'Not Delivered'" #rowCheckbox (click)="$event.stopPropagation()"
    (change)="selectDevice(tableElem)"></mat-checkbox>
  <ng-container *ngIf="optionControl.value === 'Delivered'">
    <app-asc-icon-button appAscTooltip="Revoke" [disabled]="inAction"
      (btnClicked)="revokeDlc(tableElem, caseNumber.value)">block</app-asc-icon-button>
  </ng-container>
</ng-template>

<ng-template #firstHeader>
  <mat-checkbox *ngIf="optionControl.value === 'Not Delivered'" (change)="this.selectPage()"
    [indeterminate]="allIndertimnate" [checked]="allChecked" [disabled]="dataSource.isLoading$ | async"
    appAscTooltip="Select all on current page" class="header-checkbox"></mat-checkbox>
  <ng-container *ngIf="optionControl.value === 'Delivered'"> Action </ng-container>
</ng-template>