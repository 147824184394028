import { Observable, catchError, delay, expand, ignoreElements, of, shareReplay } from 'rxjs';
import { FailedJobsApiService } from '../services/failed-jobs-api.service';
import { JobStatus } from './JobStatus';

export function getDhsJobInfo(status: JobStatus): { icon: string; color: string; message: string; iconClass: string; action: string } {
  switch (status.value) {
    case 'CREATED':
      return {
        icon: 'fiber_new',
        color: '#003595',
        message: "New Failed Job, hasn't been restarted.",
        iconClass: 'created-dhs-jobs',
        action: status.info,
      };
    case 'RUNNING':
      return {
        icon: 'sync',
        color: '#003595',
        message: 'Job is running. Current action: ' + status.info,
        iconClass: 'running-dhs-jobs',
        action: status.info,
      };
    case 'FAILED':
      return { icon: 'report', color: 'red', message: 'Restarting Job failed.', iconClass: 'failed-dhs-jobs', action: status.info };
    case 'DONE':
      return { icon: 'check_circle', color: 'limegreen', message: 'Restarting job succeded', iconClass: 'done-dhs-jobs', action: status.info };
    case 'UPDATED':
      return {
        icon: 'skip_next',
        color: '#003595',
        message: 'Last action has been deleted. Next action loaded.',
        iconClass: 'created-dhs-jobs',
        action: status.info,
      };
  }
}

export class DhsJobStatusHelper {
  constructor(private failedJobsService: FailedJobsApiService) {}
  dhsJobsInfos: Record<number, { jobStatus: Observable<JobStatus>; jobStatusError: Observable<Error> }> = {};
  dhsJobsInfosInterval: Record<number, boolean> = {};

  getJobStatusInterval(jobId: number) {
    this.dhsJobsInfosInterval[jobId] = true;
    const getJobStatus$ = this.failedJobsService.getDhsJobStatus(jobId);
    const dhsJobStatus$ = getJobStatus$.pipe(
      shareReplay(),
      expand((resp) => {
        if (resp.value === 'RUNNING') {
          return getJobStatus$.pipe(delay(5000));
        }
        return of(resp);
      })
    );
    this.dhsJobsInfos[jobId] = {
      jobStatus: dhsJobStatus$,
      jobStatusError: dhsJobStatus$.pipe(
        ignoreElements(),
        catchError((err) => {
          this.dhsJobsInfosInterval[jobId] = false;
          return of(err);
        })
      ),
    };
  }

  getJobStatus(jobId: number) {
    if (this.dhsJobsInfosInterval[jobId]) return;
    const dhsJobStatus$ = this.failedJobsService.getDhsJobStatus(jobId).pipe(shareReplay());
    this.dhsJobsInfos[jobId] = {
      jobStatus: dhsJobStatus$,
      jobStatusError: dhsJobStatus$.pipe(
        ignoreElements(),
        catchError((err) => of(err))
      ),
    };
  }
}
