import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { FileApiService } from '../services/file-api.service';
import { DeviceFile, DeviceFileMetaData } from './DeviceFile';

export class DeviceFileMetaDataSource extends DataSourceBaseImpl<DeviceFileMetaData> {
  public avl = false;

  constructor(private fileApi: FileApiService) {
    super();
  }

  setMetaData(dFile: DeviceFile) {
    this.loadingSubject.next(true);
    if (!dFile.metadata) {
      this.totalSizeSubject.next(0);
      return;
    }
    const metadata = Object.keys(dFile.metadata).reduce(
      (prev: DeviceFileMetaData[], curr: string) => [...prev, { key: curr, value: dFile.metadata[curr] }],
      [] as DeviceFileMetaData[]
    );
    this.avl = true;
    this.totalSizeSubject.next(metadata.length);
    this.dataSubject.next(metadata);
  }
  loadMetaData(dFile: DeviceFile) {
    this.fileApi.getFileById(dFile.fileId).subscribe((resp) => {
      this.setMetaData(resp);
    });
  }
}
