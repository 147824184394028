import { Component, Input, OnInit } from '@angular/core';
import { AscSearchSelectProperties } from '../../interfaces/AscSearchSelectProperties';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css'],
})
export class SearchSelectComponent<T> implements OnInit {
  @Input() data: Observable<T[]>;
  @Input() properties: AscSearchSelectProperties<T>;

  filteredData$: Observable<T[]>;

  filterInputFormControl = new FormControl('');

  ngOnInit(): void {}
}
