import { DatePipe } from '@angular/common';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { DateRangeRelativeResponse } from './DateRangePickerResponse';

export class DateRangeUtils {
  constructor(private datePipe: DatePipe) {}
  getRelativeLabel(dateRange: DateRangeRelativeResponse | null): string {
    if (!dateRange) return '';
    return `Last ${dateRange.label} ${dateRange.unit}(s)`;
  }
  getAbsoluteLabel(dateRange: DateRange<Moment | null>): string {
    if (!dateRange.start || !dateRange.end) {
      return '';
    }
    return `${this.datePipe.transform(dateRange.start.toDate(), 'yyyy-MM-dd HH:mm:ss')} - ${this.datePipe.transform(
      dateRange.end.toDate(),
      'yyyy-MM-dd HH:mm:ss'
    )}`;
  }
}
