import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { AscButtonComponent } from './components/asc-button/asc-button.component';
import { AscConfigurableTableComponent } from './components/asc-configurable-table/asc-configurable-table.component';
import { AscConfirmDialogComponent } from './components/asc-confirm-dialog/asc-confirm-dialog.component';
import { AscDialogWrapperComponent } from './components/asc-dialog-wrapper/asc-dialog-wrapper.component';
import { AscIconButtonComponent } from './components/asc-icon-button/asc-icon-button.component';
import { AscInlineButtonComponent } from './components/asc-inline-button/asc-inline-button.component';
import { AscTabbedPageComponent } from './components/asc-tabbed-page/asc-tabbed-page.component';
import { DetailsDialogComponent } from './components/details-dialog/details-dialog.component';
import { AscTooltipDirective } from './directives/asc-tooltip.directive';
import { ClipboardCopyDirective } from './directives/clipboard-copy.directive';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { HumanizeListPipe } from './pipes/humanize-list.pipe';

@NgModule({
  declarations: [
    AscConfigurableTableComponent,
    AscInlineButtonComponent,
    ClipboardCopyDirective,
    HumanizeListPipe,
    AscIconButtonComponent,
    FormatFileSizePipe,
    AscDialogWrapperComponent,
    AscButtonComponent,
    AscConfirmDialogComponent,
    DetailsDialogComponent,
    AscTabbedPageComponent,
    AscTooltipDirective,
  ],
  imports: [CommonModule, MaterialModule, OverlayModule],
  exports: [
    AscInlineButtonComponent,
    ClipboardCopyDirective,
    HumanizeListPipe,
    AscIconButtonComponent,
    AscConfigurableTableComponent,
    FormatFileSizePipe,
    AscDialogWrapperComponent,
    AscButtonComponent,
    AscConfirmDialogComponent,
    AscTooltipDirective,
  ],
})
export class SharedModule {}
