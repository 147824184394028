import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadableContentCountPerDevice } from 'src/app/software-management/interfaces/DownloadableContentPerDevice';
import { SoftwareManagementService } from '../../services/software-management.service';

@Component({
  selector: 'app-software-per-devices',
  templateUrl: './software-per-devices.component.html',
  styleUrls: ['./software-per-devices.component.css'],
})
export class SoftwarePerDevicesComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private apiService: SoftwareManagementService, private titleService: Title) {}

  displayedColumns: string[] = ['deviceId', 'deviceType', 'serialNumber', 'assigned', 'unassigned', 'actions'];
  dataSource = new MatTableDataSource<DownloadableContentCountPerDevice>();
  isLoading = false;
  isError = false;
  tableInfoText = 'No data';

  // paginator properties
  pageEvent: PageEvent;
  totalSize: number;
  totalPages: number;
  currentPage: number;

  // sort properties
  sortColumn = '';
  sortDirection = '';

  pageSize = 10;
  pageIndex = 0;

  searchText = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    //  init the title
    this.titleService.setTitle('Software per Device List');

    // initialize session variables if not
    if (sessionStorage.getItem('assignableDeviceListPageSize') == null && sessionStorage.getItem('assignableDeviceListPageIndex') == null) {
      sessionStorage.setItem('assignableDeviceListPageSize', '10');
      sessionStorage.setItem('assignableDeviceListPageIndex', '0');
    } else {
      this.pageSize = parseInt(sessionStorage.getItem('assignableDeviceListPageSize') || '10', 10);
      this.pageIndex = parseInt(sessionStorage.getItem('assignableDeviceListPageIndex') || '0', 10);
    }
    // calling the api
    this.getSoftwarePerDeviceList();
  }

  getSoftwarePerDeviceList(): void {
    this.isLoading = true;

    this.apiService
      .getDownloadableContentCountPerDevice(this.pageSize, this.pageIndex, this.sortColumn, this.sortDirection, this.searchText)
      .subscribe({
        next: (data) => {
          console.log('----Received data from SoftwarePerDeviceList api----');
          if (data.content) {
            this.dataSource.data = data.content;
          } else {
            this.dataSource.data = [];
          }
          this.totalSize = data.totalSize;
          this.isLoading = false;
          this.isError = false;
        },
        error: (error) => {
          console.log('Error in device list API', error);
          this.isError = true;
          this.tableInfoText = 'Sorry, something went wrong !!';
          this.isLoading = false;
        },
      });
  }

  searchDeviceTable() {
    this.getSoftwarePerDeviceList();
  }

  paginatorClicked(event?: PageEvent): void {
    (this.pageSize = event?.pageSize !== undefined ? event?.pageSize : 0),
      (this.pageIndex = event?.pageIndex !== undefined ? event?.pageIndex : 0),
      sessionStorage.setItem('assignableDeviceListPageSize', this.pageSize.toString()),
      sessionStorage.setItem('assignableDeviceListPageIndex', this.pageIndex.toString()),
      this.getSoftwarePerDeviceList();
  }

  showDetails(element: any) {
    this.router.navigate(['devicedetails', element.deviceId], {
      queryParams: { t: 2 },
    });
  }

  sortClicked(event: Sort): void {
    if (this.sort.direction === '') {
      (this.sortColumn = ''), (this.sortDirection = '');
    } else {
      (this.sortColumn = this.sort.active), (this.sortDirection = this.sort.direction);
    }

    this.getSoftwarePerDeviceList();
  }

  clearSearchInput() {
    this.searchText = '';
  }
}
