import { Component, OnInit } from '@angular/core';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  public ascVersion: string;

  constructor(private apiService: SharedApiService) {}

  ngOnInit(): void {
    this.apiService.getVersion().subscribe({
      next: (version) => {
        this.ascVersion = version.version;
      },
      error: (error) => {
        console.log('Error in version api', error);
      },
    });
  }
}
