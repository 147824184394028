<sub-header label="Ongoing Uploads"> </sub-header>

<div class="c-flex-wrapper">
  <mat-spinner *ngIf="isLoading && !isError" class="c-loading-spinner"></mat-spinner>

  <div class="c-main-container" [hidden]="isLoading">
    <div class="mat-elevation-z8" style="margin: 0 100px 60px 100px">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortClicked()">
        <!-- Device Type Column -->
        <ng-container matColumnDef="deviceType">
          <mat-header-cell *matHeaderCellDef id="deviceType_header" class="c-table-header-style"> Device Type </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.deviceType }} </mat-cell>
        </ng-container>

        <!-- Device ID Column -->
        <ng-container matColumnDef="deviceId">
          <mat-header-cell *matHeaderCellDef id="deviceId_header" class="c-table-header-style"> Device ID </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.deviceId }}
          </mat-cell>
        </ng-container>

        <!-- Customer Column -->
        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef id="customer_header" class="c-table-header-style"> Customer </mat-header-cell>
          <mat-cell *matCellDef="let element" style="margin-right: 5px">
            {{ element.customer }}
          </mat-cell>
        </ng-container>

        <!--Valid Until Column -->
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef id="fileName" class="c-table-header-style"> File Name </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.fileName.substring(element.fileName.lastIndexOf('/') + 1) }}
          </mat-cell>
        </ng-container>

        <!-- last connected Column -->
        <ng-container matColumnDef="uploadStart">
          <mat-header-cell *matHeaderCellDef id="uploadStart_header" class="c-table-header-style" mat-sort-header>
            Upload Start Time {{ getTimeZone() }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.uploadStart | date : 'yyyy-MM-dd, HH:mm' }}
          </mat-cell>
        </ng-container>

        <!--totalFileSize Column -->
        <ng-container matColumnDef="totalFileSize">
          <mat-header-cell *matHeaderCellDef id="totalFileSize_header" class="c-table-header-style" mat-sort-header>
            <!-- to push the content in right justified way -->
            <div style="flex: 1; justify-content: center; text-align: right; white-space: nowrap">Total File Size (Bytes)</div>
            <div style="flex: 1"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <!-- to push the content in right justified way -->
            <div style="flex: 1; justify-content: center; text-align: right">
              {{ element.totalFileSize.toLocaleString('en') }}
            </div>
            <div style="flex: 3"></div>
          </mat-cell>
        </ng-container>

        <!-- currentUploadSize Column -->
        <ng-container matColumnDef="currentUploadSize">
          <mat-header-cell *matHeaderCellDef id="currentUploadSize_header" class="c-table-header-style">
            <!-- to push the content in right justified way -->
            <div style="flex: 1; justify-content: center; text-align: right; white-space: nowrap">Uploaded Bytes</div>
            <div style="flex: 1"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <!-- to push the content in right justified way -->
            <div style="flex: 1; justify-content: center; text-align: right">
              {{ element.currentUploadSize.toLocaleString('en') }}
            </div>
            <div style="flex: 3"></div>
          </mat-cell>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef id="actions_header" class="c-table-header-style"> Progress </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-progress-bar
              mode="determinate"
              [value]="calculatePercentage(element.totalFileSize, element.currentUploadSize)"
              [appAscTooltip]="calculatePercentage(element.totalFileSize, element.currentUploadSize) + ' %'"
            >
            </mat-progress-bar>
          </mat-cell>
        </ng-container>

        <div [ngClass]="{ 'c-table-info-error': isError }" *matNoDataRow class="c-table-info">
          {{ tableInfoText }}
        </div>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ hovered: row.hovered }"
          (mouseover)="row.hovered = true"
          (mouseout)="row.hovered = false"
        ></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 50]"
        [pageSize]="this.pageSize"
        [pageIndex]="this.pageIndex"
        showFirstLastButtons
        [length]="this.totalSize"
        (page)="paginatorClicked($event)"
        class="c-paginator"
      >
      </mat-paginator>
    </div>
  </div>
</div>
