import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { ErrorHistoryComponent } from './components/error-history/error-history.component';
import { MessageCardComponent } from './components/message-card/message-card.component';

@NgModule({
  declarations: [MessageCardComponent, ErrorHistoryComponent],
  imports: [CommonModule, MatSidenavModule, MatIconModule, MatCardModule, SharedModule, MatTooltipModule],
  exports: [ErrorHistoryComponent],
})
export class ErrorHandlingModule {}
