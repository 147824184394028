import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

declare const ASC_VERSION: string;
declare const ASC_BUILD_TIME: string;

@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-login-callback.component.html',
  styleUrls: ['./okta-login-callback.component.css'],
})
export class OktaLoginCallbackComponent implements OnInit {
  ascVersion = ASC_VERSION;
  ascBuildTime = ASC_BUILD_TIME;
  showError = false;
  errorMessage = '';

  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      await this.auth.oktaLoginCallback(params.code, params.state).catch((err) => {
        if (err.status === 410) {
          this.router.navigate(['login']);
        } else if (err.status === 503) {
          this.showError = true;
          this.errorMessage = 'There was an issue contacting Okta to complete the login process';
        } else {
          this.showError = true;
          this.errorMessage = 'There was an issue completing the login process';
        }
      });
      await this.auth.afterLogin();
    });
  }

  onNavigateToLoginClick(): void {
    this.router.navigate(['login']);
  }
}
