import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AscAutocompleteComponent } from './components/asc-autocomplete/asc-autocomplete.component';
import { AscCatSelectComponent } from './components/asc-cat-select/asc-cat-select.component';
import { AscOptionSelectSearchComponent } from './components/asc-option-select-search/asc-option-select-search.component';
import { AscTextInputComponent } from './components/asc-text-input/asc-text-input.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';

@NgModule({
  declarations: [
    AscOptionSelectSearchComponent,
    AscCatSelectComponent,
    AscTextInputComponent,
    SearchSelectComponent,
    AscAutocompleteComponent,
    DateRangePickerComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule, MatDatepickerModule],
  exports: [AscOptionSelectSearchComponent, AscCatSelectComponent, AscTextInputComponent, AscAutocompleteComponent, DateRangePickerComponent],
  providers: [FormGroupDirective],
})
export class AscFormsModule {}
