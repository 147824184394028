import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { SecondsTransformerPipe } from './pipes/seconds-transformer.pipe';

//Module for header, footer and other wrappers or extremities

@NgModule({
  declarations: [HeaderComponent, SubHeaderComponent, FooterComponent, SecondsTransformerPipe],
  imports: [CommonModule, MaterialModule, MatBadgeModule, SharedModule],
  exports: [HeaderComponent, SubHeaderComponent, FooterComponent],
})
export class MarginalsModule {}
