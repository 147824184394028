import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Version } from 'src/app/shared/interfaces/Version';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';
import { DeviceTypeResponse } from '../interfaces/DeviceTypeResponse';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService {
  constructor(private httpClient: HttpClient) {}

  getDeviceTypeList(
    type?: string,
    searchText?: string,
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: string
  ): Observable<DeviceTypeResponse> {
    const params = createHttpParams({
      size: pageSize,
      page: pageIndex,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      type: type,
      searchText: searchText,
    });

    return this.httpClient.get<DeviceTypeResponse>(`${environment.awsApi.apiBaseUrl}/devices/types`, { params });
  }

  getVersion(): Observable<Version> {
    return this.httpClient.get<Version>(encodeURI(`${environment.awsApi.apiBaseUrl}/version`));
  }
}
