import {} from '@angular/cdk/collections';
import { SortDirection } from '@angular/material/sort';
import { catchError, finalize, of } from 'rxjs';
import { FileApiService } from 'src/app/device-file/services/file-api.service';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { DeviceApiService } from '../services/device-api.service';
import { DownloadableContentClass } from './DownloadableContent';

export class DeviceDownloadableContentDataSource extends DataSourceBaseImpl<DownloadableContentClass & { assigned: boolean }> {
  constructor(private apiService: DeviceApiService, private fileService: FileApiService) {
    super();
  }

  loadDownloadableContent(
    searchText: string,
    deviceId: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: SortDirection
  ) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.totalSizeSubject.next(0);
    this.dataSubject.next([]);

    this.apiService
      .getDownloadableContentPerDevice(searchText, deviceId, pageSize, pageIndex, sortColumn, sortDirection)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((dc) => {
        if (dc.content) {
          this.dataSubject.next(dc.content.map((dlcPerDevice) => ({ assigned: dlcPerDevice.assigned, ...dlcPerDevice.downloadableContent })));
        }
        this.totalSizeSubject.next(dc.totalSize);
      });
  }
}
