<div class="filter-container">
  <app-asc-autocomplete [properties]="activityTypeConfig" [control]="activityTypeControl" *ngIf="activityTypes$ | async"
    [data]="activityTypes$">
  </app-asc-autocomplete>
  <div class="horizontal-spacer"></div>
  <app-date-range-picker [dateRangeIn]="dateRange" [timeRange]="relativeRange" (relativeRange)="getRelativeRange($event)" (absoluteRange)="getAbsoluteRange($event)">
      <app-asc-text-input class="date-input" maxWidth="380px" [control]="timeRangeControl" [useAsTrigger]="true" datePickerTrigger prefixIcon="arrow_drop_down" placeholder="Time Range"></app-asc-text-input>
  </app-date-range-picker>
  <app-asc-inline-button [disabled]="!enableSearchButton()" appAscTooltip="Max. 1000 log entries" class="search-button" (click)="startSearch()">
    Search
    <mat-icon inlineIcon>search</mat-icon>
  </app-asc-inline-button>
</div>

<app-asc-configurable-table class="activity-log-table"
  [noDataMessage]="noDataMessage"
  [dataSource]="dataSource"
  [columns]="tableColumns"
  [columnCells]="[timestampTemp, userTemp, typeTemp, detailsTemp]">
</app-asc-configurable-table>

<ng-template let-tableElem="tableElem" #timestampTemp>
  {{tableElem.timestamp}}
</ng-template>
<ng-template let-tableElem="tableElem" #userTemp>
  {{tableElem.userName}}
</ng-template>
<ng-template let-tableElem="tableElem" #typeTemp>
  {{tableElem.activityType}}
</ng-template>
<ng-template let-tableElem="tableElem" #detailsTemp>
  {{tableElem.activityDetails?.message || ''}}
</ng-template>