import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsTransformer',
})
export class SecondsTransformerPipe implements PipeTransform {
  transform(seconds: number | null): string {
    if (!seconds) return '--:--';
    const mins = Math.floor(seconds / 60);
    const secs = seconds - mins * 60;
    return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
  }
}
