import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CButton } from '../../interfaces/CButton';

@Component({
  selector: 'app-asc-button',
  templateUrl: './asc-button.component.html',
  styleUrls: ['./asc-button.component.css'],
})
export class AscButtonComponent implements CButton {
  @Input() type: 'primary' | 'secondary' | 'cancel' | 'flat' = 'primary';
  @Input() disabled: boolean;
  @Input() active = false;
  @Output() btnClicked: EventEmitter<MouseEvent> = new EventEmitter();

  getButtonClass() {
    return `asc-button-${this.type} ${this.active ? 'active' : ''}`;
  }

  cButtonClicked(evt: MouseEvent): void {
    this.btnClicked.emit(evt);
  }
}
