<ng-container *ngFor="let next of getNextStatus(device)">
  <app-asc-icon-button
    [ngClass]="'action-icon-button'"
    [appAscTooltip]="next.actionName"
    class="small-button"
    size="small"
    (btnClicked)="[$event.stopPropagation(), deviceAction(next.action)]"
    >{{ next.matIcon }}</app-asc-icon-button
  >
</ng-container>
