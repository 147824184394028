import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { ParserMonitoringLog } from './ParserMonitoringLog';
import { LoggingService } from '../services/logging.service';
import { catchError, finalize, of } from 'rxjs';

export class DeviceLogParserMonitoringDataSource extends DataSourceBaseImpl<ParserMonitoringLog> {
  constructor(private loggingApi: LoggingService) {
    super();
  }

  loadMonitoringLog(deviceId: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string) {
    this.errorSubject.next(null);
    this.loadingSubject.next(true);
    this.loggingApi
      .getParsingLogsPerDevice(deviceId, pageSize, pageIndex, sortColumn, sortDirection)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(true);
        })
      )
      .subscribe((resp) => {
        if (resp.content) {
          this.dataSubject.next(resp.content);
        }
        this.totalSizeSubject.next(resp.totalSize);
      });
  }
}
