<mat-grid-list cols="4" rowHeight="100px">
  <mat-grid-tile>
    <div>
      <mat-checkbox [checked]="device.allowRemoteDesktopAccess" (change)="toggleAllowRemoteDesktopAccess($event)" style="margin: 10px 0">
        Allow Remote Access
      </mat-checkbox>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="3"></mat-grid-tile>
  <mat-grid-tile>
    <div>Registration Status:</div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="3">
    <div>{{ device.remoteDesktopRegistrationStatus }}</div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div>Time of last remote desktop registration request</div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="3">
    <div>{{ device.remoteDesktopLastRegistrationTime }}</div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div>Error Code</div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="3">
    <div>{{ device.remoteDesktopLastError }}</div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div>Error Message</div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="3">
    <div>{{ device.remoteDesktopLastErrorMessage }}</div>
  </mat-grid-tile>
</mat-grid-list>
