import { SortDirection } from '@angular/material/sort';
import { catchError, finalize, map, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { FileTransferService, FileTransferStatus } from '../services/app-filetransfer.service';
import { FileApiService } from '../services/file-api.service';
import { DeviceFile } from './DeviceFile';

export class DeviceFileDataSource extends DataSourceBaseImpl<DeviceFile> {
  constructor(private fileApi: FileApiService, private fileTransferService: FileTransferService) {
    super();
  }

  loadDeviceFiles(searchText: string, deviceId: string, pageIndex: number, pageSize: number, sortColumn: string, sortDirection: SortDirection) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);

    let transfers = this.fileTransferService.getTransfers(deviceId, pageSize, pageIndex, '', '').transfers;
    let transfersLength = 0;
    let transfersAsDeviceFiles: DeviceFile[] = [];
    if (transfers) {
      transfers = transfers.filter((trans) => trans.status !== FileTransferStatus.Success);
      transfersLength = transfers.length;
      if (transfersLength > pageSize) pageSize = transfersLength;

      transfersAsDeviceFiles = transfers
        .filter((trans) => trans.status !== FileTransferStatus.Success)
        .map((transfer) => ({
          transfer: transfer,
          fileId: transfer.id,
          name: transfer.file.name,
          size: transfer.file.size,
          inTransfer: true,
          fileType: transfer.type,
          uploadedOn: new Date(),
          metadata: {},
        }));
    }

    this.fileApi
      .getFileList(searchText, deviceId, pageSize - transfersLength < 0 ? 0 : pageSize - transfersLength, pageIndex, sortColumn, sortDirection)
      .pipe(
        map((dfs) => {
          if (!dfs.content) {
            return dfs;
          }
          return dfs;
        }),
        catchError((err) => {
          console.error(err);
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((dfs) => {
        let storage: DeviceFile[] = [];
        if (dfs.content) {
          storage = dfs.content;
        }
        this.dataSubject.next([...transfersAsDeviceFiles, ...storage]);

        this.totalSizeSubject.next(dfs.totalSize + transfersLength);
      });
  }
}
