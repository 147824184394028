import { AuthenticationType } from './AuthenticationType';

export const environment = {
  production: false,
  inactivityTimeout: 600,
  authenticationType: AuthenticationType.OKTA,
  oktaConfig: {
    baseUrl: 'https://dev-00741395.okta.com/oauth2/default',
    issuer: 'https://dev-00741395.okta.com/oauth2/default',
    clientId: '0oa6o3akmxGsJlki55d7',
    callbackUrl: 'https://frontend-dev.poc.asc.syrocon.cloud/login/callback',
    logoutRedirectUrl: 'https://frontend-dev.poc.asc.syrocon.cloud',
  },
  awsApi: {
    apiBaseUrl: 'https://dev-ui.poc.asc.syrocon.cloud',
  },
};
