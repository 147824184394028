<sub-header label="Failed Jobs Management"></sub-header>
<div class="c-main-container">
  <mat-tab-group class="mat-tab-group" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged()">
    <mat-tab label="Failed Jobs">
      <ng-template matTabContent>
        <app-failed-jobs-tab></app-failed-jobs-tab>
      </ng-template>
    </mat-tab>
    <mat-tab label="User Activities">
      <ng-template matTabContent>
        <app-user-activities-tab></app-user-activities-tab>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
