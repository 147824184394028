import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, catchError, finalize, map, Observable, of, startWith, Subscription, switchMap } from 'rxjs';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { DownloadableContent } from 'src/app/shared/interfaces/DownloadableContent';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { SoftwareManagementService } from 'src/app/software-management/services/software-management.service';
import { Device } from '../../interfaces/Device';
import { DeviceListDataSource } from '../../interfaces/DeviceListDataSource';
import { DeviceApiService } from '../../services/device-api.service';

type DeliveredOption = 'Delivered' | 'Not Delivered';

@Component({
  selector: 'app-deliver-downloadable-content',
  templateUrl: './deliver-downloadable-content.component.html',
  styleUrls: ['./deliver-downloadable-content.component.css'],
})
export class DeliverDownloadableContentComponent implements OnDestroy, StorageOperations {
  columns: ConfigureableTableColumn<Device>[] = [
    {
      name: 'selector',
      header: 'Select',
      sortable: false,
    },
    {
      name: 'deviceType',
      header: 'Device Type',
      sortable: true,
    },
    {
      name: 'serialNumber',
      header: 'Serial Number',
      sortable: true,
    },
  ];

  inAction = false;

  selection = new SelectionModel<Device>(true, []);

  @ViewChildren('rowCheckbox') checkboxes: QueryList<MatCheckbox>;

  optionData: Observable<DeliveredOption[]> = of(['Delivered', 'Not Delivered']);
  optionControl = new FormControl<DeliveredOption>('Not Delivered', [Validators.required]);
  searchControl = new FormControl<string>('');
  caseNumber = new FormControl<string | null>('');
  dataSource: DeviceListDataSource;
  allChecked = false;
  allIndertimnate = false;
  optionControlProps: AscAutocompleteProperties<DeliveredOption> = {
    humanizeOption: (k) => k,
    equals: (a, b) => a === b,
    filterPredicate: (k, searchInput) => {
      return k.toLowerCase().startsWith(searchInput.toLowerCase());
    },
    notSearchable: true,
    placeholder: 'Status',
    panelWidth: '280px',
  };

  sort: Sort;
  pageables: ConfigPageEvent;
  fileUid: string;
  dlc = new BehaviorSubject<DownloadableContent | null>(null);

  subs$ = new Subscription();

  constructor(
    private deviceApi: DeviceApiService,
    private store: AppStorageService,
    private activeRoute: ActivatedRoute,
    private toast: ToastsService,
    private softwareApi: SoftwareManagementService
  ) {
    this.dataSource = new DeviceListDataSource(deviceApi);
    this.getStorageData();
    this.subs$.add(
      this.activeRoute.params
        .pipe(
          switchMap((params: Params) => {
            return this.getDlc(params['fileUid']);
          }),
          switchMap((fileUid: string) => {
            this.fileUid = fileUid;
            return this.getData();
          })
        )
        .subscribe()
    );

    this.subs$.add(
      this.dataSource.loadingSubject.subscribe((loading) => {
        if (!loading) {
          this.checkSelection();
        }
      })
    );

    window.onbeforeunload = () => this.saveStorageData();
  }

  getDlc(fileUuid: string): Observable<string> {
    return this.softwareApi.getDownloadableContentList(1, 0, '', '', undefined, fileUuid).pipe(
      map((dlcPage) => this.dlc.next(dlcPage.content?.[0] || null)),
      map(() => fileUuid)
    );
  }

  loadData(option?: DeliveredOption | null) {
    if (!option) option = this.optionControl.value;
    this.dataSource.loadDevices(
      this.searchControl.value || '',
      this.pageables.pageSize,
      this.pageables.pageIndex,
      this.sort.active,
      this.sort.direction,
      {
        assignedDlcUuid: option === 'Delivered' ? this.fileUid : undefined,
        notAssignedDlcUuid: option === 'Not Delivered' ? this.fileUid : undefined,
      },

      this.dlc.getValue()?.deviceTypes
    );
  }

  getData() {
    return this.optionControl.valueChanges.pipe(
      startWith(this.optionControl.value),
      map((val) => {
        this.selection.clear();
        this.loadData(val);
      })
    );
  }

  getStorageData() {
    this.sort = {
      active: this.store.getValue<string>('deliver_dlc_sort_column') || '',
      direction: this.store.getValue<SortDirection>('deliver_dlc_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue<number>('deliver_dlc_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
    this.optionControl.setValue(this.store.getValue<DeliveredOption>('deliver_dlc_filter_option') || 'Not Delivered');
  }
  onPagChange(pagChange: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageIndex: pagChange.pageIndex,
      pageSize: pagChange.pageSize,
    };
    this.selection.clear();
    this.checkSelection();
    this.loadData(this.optionControl.value);
  }
  onSortChange(sort: Sort) {
    this.sort = sort;
    this.selection.clear();
    this.checkSelection();
    this.loadData(this.optionControl.value);
  }

  saveStorageData() {
    this.store.setValue('deliver_dlc_sort_column', this.sort.active ? this.sort.active : null);
    this.store.setValue('deliver_dlc_sort_direction', this.sort.direction);
    this.store.setValue('deliver_dlc_pagination_pagesize', this.pageables.pageSize);
    this.store.setValue('deliver_dlc_filter_searchtext', this.searchControl.value || '', 'string', true);
    this.store.setValue('deliver_dlc_filter_option', this.optionControl.value || '', 'string', true);
  }

  selectDevice(device: Device) {
    this.selection.isSelected(device) ? this.selection.deselect(device) : this.selection.select(device);
    this.selection.isEmpty() ? (this.allIndertimnate = false) : (this.allIndertimnate = true);
    this.checkSelection();
  }

  checkSelection() {
    if (this.selection.selected.length === this.dataSource.dataSubject.getValue().length) {
      this.allChecked = true;
      this.allIndertimnate = false;
    } else if (this.selection.isEmpty()) {
      this.allChecked = false;
      this.allIndertimnate = false;
    } else {
      this.allIndertimnate = true;
      this.allChecked = false;
    }
  }

  revokeDlc(device: Device, caseNumber: string | null) {
    this.inAction = true;
    this.deviceApi
      .revokeDownloadableContentDelivery(this.fileUid, device.serialNumber, device.deviceType, caseNumber)
      .pipe(
        map(() => {
          this.toast.raise(
            {
              message: `Successfully revoke downloadable content from device ${device.deviceType} - ${device.serialNumber}`,
              title: 'Successfully revoked DLC',
            },
            'SUCCESS',
            false
          );
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.raise(
            {
              message: `Cannot revoke downloadable content from device ${device.deviceType} - ${device.serialNumber}`,
              title: 'Revoke DLC failed',
              error: err,
            },
            'ERROR',
            true
          );
          return of();
        }),
        finalize(() => {
          this.selection.clear();
          this.loadData();
          this.inAction = false;
        })
      )
      .subscribe();
  }

  assignDlc(caseNumber: string | null) {
    this.inAction = true;
    this.deviceApi
      .deliverDownloadableContent(this.fileUid, { deviceIds: this.selection.selected.map((device) => device.id) }, caseNumber)
      .pipe(
        map(() => {
          this.toast.raise(
            {
              message: `Successfully assigned downloadable content to ${this.selection.selected.length} devices`,
              title: 'Successfully assigned DLC',
            },
            'SUCCESS',
            false
          );
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.raise(
            {
              message: `Cannot assign downloadable to ${this.selection.selected.length} devices `,
              title: 'Assign DLC failed',
              error: err,
            },
            'ERROR',
            true
          );
          return of();
        }),
        finalize(() => {
          this.selection.clear();
          this.loadData();
          this.inAction = false;
        })
      )
      .subscribe();
  }

  selectPage() {
    let selectAll = false;
    if (!this.allChecked) {
      this.selection.select(...this.dataSource.dataSubject.getValue());
      selectAll = true;
    } else {
      this.selection.clear();
      selectAll = false;
    }
    this.checkSelection();
    this.checkboxes.forEach((cb) => {
      cb.checked = selectAll;
    });
  }
  ngOnDestroy(): void {
    this.saveStorageData();
    this.subs$.unsubscribe();
  }
}
