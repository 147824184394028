import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, map, mergeMap, of } from 'rxjs';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { MessageCard, MessageCardType } from '../interfaces/MessageCard';
import { ToastsService } from './toasts.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHistoryService {
  recentMessagesSubject: BehaviorSubject<MessageCard[]> = new BehaviorSubject<MessageCard[]>([]);

  subs$ = new Subscription();
  unseenMessagesCount$: Observable<number>;
  opened$ = new BehaviorSubject<boolean>(false);
  recentMessages$: Observable<MessageCard[]> = of([]);
  constructor(private store: AppStorageService, private toasts: ToastsService) {
    this.unseenMessagesCount$ = this.recentMessagesSubject.asObservable().pipe(map((messages) => messages.filter((message) => !message.seen).length));
    this.recentMessages$ = this.recentMessagesSubject.asObservable();
    this.subs$.add(
      this.toasts.toastQueue$
        .pipe(
          mergeMap((card) => {
            if (!card) return of([null]);
            return combineLatest([card.toast.onTap, of(this.addNotification(card.toast.title, card.toast.message, card.type))]); //{card : this.addNotification(card.toast.title, card.toast.message, card.type), toast : card.toast};
          })
        )
        .subscribe(([, card]) => {
          if (card)
            this.recentMessagesSubject.next(this.recentMessagesSubject.value.map((mess) => (card.id === mess.id ? { ...mess, seen: true } : mess)));
        })
    );
  }

  loadToastsCards() {
    this.recentMessagesSubject.next(this.store.getValue<MessageCard[]>('__toast_cards__') ?? []);
  }

  saveToastsCards() {
    this.store.setValue('__toast_cards__', this.recentMessagesSubject.value, 'array', true);
  }

  addNotification(title: string, message: string, type: MessageCardType, withToast = true): MessageCard {
    const newMessage: MessageCard = {
      title,
      message,
      type,
      timestamp: new Date(),
      seen: this.opened$.value,
      id: new Date().valueOf() + '-' + Math.round(Math.random() * 1000000),
    };

    this.recentMessagesSubject.next([newMessage].concat(this.recentMessagesSubject.value));
    return newMessage;
  }
  toggleHistory() {
    this.opened$.next(!this.opened$.value);
    if (this.opened$.value) {
      this.recentMessagesSubject.next(this.recentMessagesSubject.value.map((message) => ({ ...message, seen: true })));
    }
  }

  deleteNotification(id: string) {
    const messages = this.recentMessagesSubject.value;
    messages.splice(
      messages.findIndex((val) => val.id === id),
      1
    );
    this.recentMessagesSubject.next(messages);
  }
  clearAll() {
    this.recentMessagesSubject.next([]);
    this.toggleHistory();
  }
  unsubscribe() {
    this.subs$.unsubscribe();
  }
}
