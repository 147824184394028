import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { UploadGetPartResponse } from 'src/app/software-management/interfaces/UploadGetPartResponse';
import { UploadInitResponse } from 'src/app/software-management/interfaces/UploadInitResponse';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';
import { DeviceFile } from '../interfaces/DeviceFile';
import { DownloadPackageStatus } from '../interfaces/DownloadPackageStatus';
import { FileListResponse } from '../interfaces/FileListResponse';
import { ParseFilesRequest } from '../interfaces/ParseFilesRequest';
import { ParseFilesResponse } from '../interfaces/ParseFilesResponse';
import { PreSignedUrlResponse } from '../interfaces/PreSignedUrlResponse';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  constructor(private httpClient: HttpClient) {}

  getFileList(
    searchText: string,
    deviceId: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string
  ): Observable<FileListResponse> {
    return this.httpClient.get<FileListResponse>(`${environment.awsApi.apiBaseUrl}/files`, {
      params: createHttpParams({
        deviceId,
        searchText,
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      }),
    });
  }

  getFileById(fileId: number) {
    return this.httpClient.get<DeviceFile>(`${environment.awsApi.apiBaseUrl}/files/${fileId}`);
  }

  getFileByDownloadableContentFileUuid(dlcFileUuid: string, deviceId: string | null = null): Observable<FileListResponse> {
    return this.httpClient.get<FileListResponse>(`${environment.awsApi.apiBaseUrl}/files`, {
      params: createHttpParams({
        dlcFileUuid,
        deviceId,
      }),
    });
  }
  // gets the pre signed URL for the selected file to download
  getPreSignedUrlForDownload(fileId: number, caseNumber: string | null = null): Observable<PreSignedUrlResponse> {
    let httpHeaders = new HttpHeaders();
    if (caseNumber) {
      httpHeaders = httpHeaders.append('caseNumber', caseNumber);
    }

    return this.httpClient.get<PreSignedUrlResponse>(encodeURI(`${environment.awsApi.apiBaseUrl}/files/${fileId}/downloadUrl`), {
      headers: httpHeaders,
    });
  }

  // deletes the selected file from a device permanently from S3
  deleteFile(fileId: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/files/${fileId}`));
  }

  completeMultiPartUpload(deviceId: string, fileUid: string): Observable<unknown> {
    return this.httpClient.post(encodeURI(`${environment.awsApi.apiBaseUrl}/files/${deviceId}/upload/${fileUid}/complete`), null);
  }

  cancelMultiPartUpload(deviceId: string, fileUid: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/files/${deviceId}/upload/${fileUid}`));
  }

  initMultiPartUpload(deviceId: string, metadata: object): Observable<UploadInitResponse> {
    return this.httpClient.post<UploadInitResponse>(encodeURI(`${environment.awsApi.apiBaseUrl}/files/${deviceId}/upload/init`), metadata);
  }

  getMultiPartUploadUrl(
    deviceId: string,
    fileUid: string,
    filePart: number,
    partSize: number,
    partChecksum: string
  ): Observable<UploadGetPartResponse> {
    return this.httpClient.get<UploadGetPartResponse>(encodeURI(`${environment.awsApi.apiBaseUrl}/files/${deviceId}/upload/${fileUid}/part`), {
      params: createHttpParams({ partSize, part: filePart }),
      headers: { 'x-part-checksum': partChecksum },
    });
  }

  uploadMultiPart(preSignedUrl: string, file: File, md5: string, length: string): Observable<HttpEvent<unknown>> {
    const headers = { 'Content-MD5': md5, 'Content-Length': length };
    return this.httpClient.put(preSignedUrl, file, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  startPackagedFilesDownload(fileIds: number[], withMetadata = true, caseNumber?: string | null): Observable<string> {
    let httpHeaders = new HttpHeaders();
    if (caseNumber) {
      httpHeaders = httpHeaders.append('caseNumber', caseNumber);
    }
    return this.httpClient
      .post<string>(
        encodeURI(`${environment.awsApi.apiBaseUrl}/files/package`),
        {},
        {
          responseType: 'text' as 'json',
          params: createHttpParams({
            fileIds: fileIds,
            withMetadata: withMetadata,
          }),
          headers: httpHeaders,
        }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getPackagedDownloadStatus(taskId: string, packageName: string | null) {
    return this.httpClient
      .get<DownloadPackageStatus>(encodeURI(`${environment.awsApi.apiBaseUrl}/files/package/status`), {
        params: createHttpParams({ taskId, packageName }),
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getBlobFromPreSignedUrl(preSignedUrl: string) {
    return this.httpClient.get(preSignedUrl, { responseType: 'blob', headers: new HttpHeaders().append('x-no-auth', 'no-auth') });
  }

  parseFiles(deviceId: string, fileIds: number[]): Observable<ParseFilesResponse> {
    const request: ParseFilesRequest = {
      deviceId,
      fileIds,
    };
    return this.httpClient.post<ParseFilesResponse>(`${environment.awsApi.apiBaseUrl}/files/parse`, request);
  }
}
