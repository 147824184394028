import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AscConfirmDialogData } from 'src/app/shared/interfaces/AscConfirmDialogData';
import { UpdateDialogData } from '../components/update-device-dialog/update-device-dialog.component';
import { DeviceApiService } from '../services/device-api.service';
import { AttachDeviceDialogData } from './AttachDialogData';
import {
  ACTIVATE_ACTION,
  ATTACH_ACTION,
  CANCEL_ACTION,
  CONNECT_ACTION,
  DEACTIVATE_ACTION,
  DECOMISSION_ACTION,
  DETTACH_ACTION,
  DISCONNECT_ACTION,
  Device,
  DeviceAction,
  REACTIVATE_ACTION,
  UPDATE_ACTION,
} from './Device';

type DeviceActionHandlerReturn<A extends DeviceAction> = A extends ATTACH_ACTION
  ? { action: A; dialogConfig: MatDialogConfig<AttachDeviceDialogData> }
  : A extends DEACTIVATE_ACTION | DETTACH_ACTION | DISCONNECT_ACTION | REACTIVATE_ACTION | DECOMISSION_ACTION
  ? { action: A; dialogConfig: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> }
  : A extends UPDATE_ACTION
  ? { action: A; dialogConfig: MatDialogConfig<UpdateDialogData> }
  : A extends CONNECT_ACTION | ACTIVATE_ACTION
  ? { action: A; actionHandler: () => Observable<unknown>; errorHandler: () => string; successHandler: () => string; title: string }
  : never;

export function DeviceActionHandler<A extends DeviceAction>(
  deviceService: DeviceApiService,
  action: A,
  device: Device
): DeviceActionHandlerReturn<A> | undefined {
  switch (action) {
    case 'ATTACH': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            child: device,
          },
          maxHeight: '90vh',
        },
      } as DeviceActionHandlerReturn<A>;
      return handler;
    }

    case 'DEACTIVATE': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            title: 'Deactivate device',
            dialogType: 'warning',
            actionLabel: 'Deactivate',
            message: `Deactivate device ${device.deviceType} • ${device.serialNumber}?`,
            action: {
              errorHandler: () => {
                return `Could not deactivate device ${device.deviceType} • ${device.serialNumber}`;
              },
              successHandler: () => `Deactivated device ${device.deviceType} • ${device.serialNumber}`,
              call: () => deviceService.blockDevice(device.id),
            },
          },
          minWidth: '400px',
        },
      } as DeviceActionHandlerReturn<DEACTIVATE_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'DECOMISSION': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            title: 'Decommission device',
            dialogType: 'warning',
            message: `Decommission device ${device.deviceType} • ${device.serialNumber}?`,
            actionLabel: 'Decommission',
            action: {
              errorHandler: () => `Could not decommission device ${device.deviceType} • ${device.serialNumber}`,
              successHandler: () => `Decommissioned device ${device.deviceType} • ${device.serialNumber}`,
              call: () => deviceService.decommissionDevice(device.id),
            },
          },
          minWidth: '400px',
        },
      } as DeviceActionHandlerReturn<DECOMISSION_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'CANCEL': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            title: 'Cancel device registration',
            dialogType: 'warning',
            message: `Cancel registration for device ${device.deviceType} • ${device.serialNumber}?`,
            actionLabel: 'Cancel Registration',
            action: {
              errorHandler: () => `Could not cancel registration for device ${device.deviceType} • ${device.serialNumber}`,
              successHandler: () => `Cancelled registration for device ${device.deviceType} • ${device.serialNumber}`,
              call: () => deviceService.cancelDevice(device.id),
            },
          },
          minWidth: '400px',
        },
      } as DeviceActionHandlerReturn<CANCEL_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'DISCONNECT': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            title: 'Disconnect device',
            dialogType: 'warning',
            message: `Disconnect device ${device.deviceType} • ${device.serialNumber}?`,
            actionLabel: 'Disconnect',
            action: {
              errorHandler: () => `Could not disconnect device ${device.deviceType} • ${device.serialNumber}`,
              successHandler: () => `Disconnected device ${device.deviceType} • ${device.serialNumber}`,
              call: () => deviceService.disconnectDevice(device.id),
            },
          },
          minWidth: '400px',
        },
      } as DeviceActionHandlerReturn<DISCONNECT_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'DETTACH': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            title: 'Detach device',
            dialogType: 'warning',
            message: `Detach device ${device.deviceType} • ${device.serialNumber} from its parent?`,
            actionLabel: 'Detach',
            action: {
              errorHandler: () => `Could not detach device ${device.deviceType} • ${device.serialNumber}`,
              successHandler: () => `Detached device ${device.deviceType} • ${device.serialNumber}`,
              call: () => deviceService.dettachDevice(device.id),
            },
          },
          minWidth: '400px',
        },
      } as DeviceActionHandlerReturn<DETTACH_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'ACTIVATE': {
      const handler = {
        action: action,
        actionHandler: () => deviceService.unblockDevice(device.id),
        errorHandler: () => `Could not activate device ${device.deviceType} • ${device.serialNumber}`,
        successHandler: () => `Activated device ${device.deviceType} • ${device.serialNumber}`,
        title: 'Activate Device',
      } as DeviceActionHandlerReturn<ACTIVATE_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'REACTIVATE': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            title: 'Reactivate registration ID for device',
            dialogType: 'warning',
            message: `Reactivate registration ID for device ${device.deviceType} • ${device.serialNumber}`,
            actionLabel: 'Reactivate',
            action: {
              errorHandler: () => `Could not reactivate registration ID for device ${device.deviceType} • ${device.serialNumber}`,
              successHandler: () => `Reactivated registration ID for device ${device.deviceType} • ${device.serialNumber}`,
              call: () => deviceService.reactivateRegId(device.id),
            },
          },
          minWidth: '400px',
        },
      } as DeviceActionHandlerReturn<REACTIVATE_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'CONNECT': {
      const handler = {
        action: action,
        actionHandler: () => deviceService.connectDevice(device.id),
        errorHandler: () => `Could not connect device ${device.deviceType} • ${device.serialNumber}`,
        successHandler: () => `Connected device ${device.deviceType} • ${device.serialNumber}`,
        title: 'Connect Device',
      } as DeviceActionHandlerReturn<CONNECT_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    case 'UPDATE': {
      const handler = {
        action: action,
        dialogConfig: {
          data: {
            device: device,
          },
        },
        minWidth: '400px',
      } as DeviceActionHandlerReturn<UPDATE_ACTION>;
      return handler as DeviceActionHandlerReturn<A>;
    }

    default:
      return undefined;
  }
}
