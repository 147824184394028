import { DeviceCertificate } from './DeviceCertificate';
import { RemoteDesktopSupportStatus } from './RemoteDesktopRegistrationStatus';

export enum DeviceStatus {
  NOT_REGISTERED = 'NOT_REGISTERED', // old cycle
  PENDING_REGISTRATION = 'PENDING_REGISTRATION', // old cycle
  QUARANTINED = 'QUARANTINED', // old cycle
  REGISTERED = 'REGISTERED',
  DE_REGISTERED = 'DE_REGISTERED',
  PROVISIONED = 'PROVISIONED',
  BLOCKED = 'BLOCKED',
  REGISTRATION_ID_ISSUED = 'REGISTRATION_ID_ISSUED',
  CERTIFICATE_ISSUED = 'CERTIFICATE_ISSUED',
  DECOMISSIONED = 'DECOMISSIONED',
}

export type ATTACH_ACTION = 'ATTACH';
export type DETTACH_ACTION = 'DETTACH';
export type PROVISION_ACTION = 'PROVISION';
export type CANCEL_ACTION = 'CANCEL';
export type DECOMISSION_ACTION = 'DECOMISSION';
export type DISCONNECT_ACTION = 'DISCONNECT';
export type DEACTIVATE_ACTION = 'DEACTIVATE';
export type CONNECT_ACTION = 'CONNECT';
export type ACTIVATE_ACTION = 'ACTIVATE';
export type REACTIVATE_ACTION = 'REACTIVATE';
export type UPDATE_ACTION = 'UPDATE';

export type DeviceAction =
  | ATTACH_ACTION
  | DETTACH_ACTION
  | PROVISION_ACTION
  | CANCEL_ACTION
  | DECOMISSION_ACTION
  | DISCONNECT_ACTION
  | DEACTIVATE_ACTION
  | CONNECT_ACTION
  | ACTIVATE_ACTION
  | REACTIVATE_ACTION
  | UPDATE_ACTION;

export type DeviceActionFromStatus<S extends DeviceStatus> = S extends DeviceStatus.BLOCKED
  ? ACTIVATE_ACTION | DECOMISSION_ACTION
  : S extends DeviceStatus.PROVISIONED
  ? DEACTIVATE_ACTION | ATTACH_ACTION | DETTACH_ACTION | CONNECT_ACTION | UPDATE_ACTION
  : S extends DeviceStatus.REGISTRATION_ID_ISSUED
  ? REACTIVATE_ACTION | CANCEL_ACTION | UPDATE_ACTION
  : S extends DeviceStatus.CERTIFICATE_ISSUED
  ? REACTIVATE_ACTION | CANCEL_ACTION | UPDATE_ACTION
  : S extends DeviceStatus.REGISTERED
  ? DISCONNECT_ACTION | UPDATE_ACTION
  : never;

export type NextDeviceStatus<FROM extends DeviceStatus> = FROM extends DeviceStatus.BLOCKED
  ? DeviceStatus.DECOMISSIONED | DeviceStatus.PROVISIONED
  : FROM extends DeviceStatus.PROVISIONED
  ? DeviceStatus.BLOCKED | DeviceStatus.REGISTRATION_ID_ISSUED | DeviceStatus.PROVISIONED | null
  : FROM extends DeviceStatus.REGISTRATION_ID_ISSUED
  ? DeviceStatus.REGISTRATION_ID_ISSUED | DeviceStatus.PROVISIONED
  : FROM extends DeviceStatus.CERTIFICATE_ISSUED
  ? DeviceStatus.REGISTRATION_ID_ISSUED | DeviceStatus.CERTIFICATE_ISSUED | DeviceStatus.PROVISIONED
  : FROM extends DeviceStatus.REGISTERED
  ? DeviceStatus.PROVISIONED | DeviceStatus.REGISTERED
  : never;

export type NextDeviceStatusProperties<FROM extends DeviceStatus> = {
  actionName: string;
  nextStatus: NextDeviceStatus<FROM>;
  deviceOrSatellite?: 'DEVICE' | 'SATELLITE' | null;
  matIcon?: string;
  action: DeviceActionFromStatus<FROM>;
};

export type NextDeviceStatusFromCurrent = {
  [fromStatus in DeviceStatus]?: NextDeviceStatusProperties<fromStatus>[];
};

export interface Device {
  id: string;
  deviceType: string;
  hardwareId: string; //new lifecycle
  parentDeviceId: string; // new lifecycle
  serialNumber: string;
  materialNumber: string;
  location: string;
  customerContact: string;
  customerAddress: string;
  assignedToken: string;
  status: DeviceStatus;
  productionDate: Date;
  installationDate: Date;
  registrationId: string;
  registrationValidUntil: Date;
  currentCertificate: DeviceCertificate;
  lastConnected: Date;
  hovered?: boolean;
  downloading?: boolean;
  connectedDeviceId: string;
  satelliteDevice: boolean;
  updateDHS: boolean;
  note: string;
  unconnectedDevice: boolean;
  allowRemoteDesktopAccess: boolean;
  remoteDesktopRegistrationStatus: RemoteDesktopSupportStatus;
  remoteDesktopLastRegistrationTime: Date;
  remoteDesktopLastError: string;
  remoteDesktopLastErrorMessage: string;
  trainingDevice: boolean;
}
