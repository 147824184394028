import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(): Promise<boolean> {
    if (!this.auth.isAuthenticated()) {
      // force him to login page if the user is not authenticated
      console.log('User is not authenticated');
      await this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}
