import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AscFormsModule } from '../asc-forms/asc-forms.module';
import { DeviceFileModule } from '../device-file/device-file.module';
import { FailedJobManagementModule } from '../failed-job-management/failed-job-management.module';
import { LoggingModule } from '../logging/logging.module';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AddDeviceTypeDialogComponent } from './components/add-device-type-dialog/add-device-type-dialog.component';
import { AttachDeviceDialogComponent } from './components/attach-device-dialog/attach-device-dialog.component';
import { DeviceActionsComponent } from './components/device-actions/device-actions.component';
import { DeviceActivityLogComponent } from './components/device-activity-log/device-activity-log.component';
import { DeviceDetailsCardComponent } from './components/device-details-card/device-details-card.component';
import { DeviceDetailsComponent } from './components/device-details/device-details.component';
import { DeviceDownloadableContentComponent } from './components/device-downloadable-content/device-downloadable-content.component';
import { DeviceHealthComponent } from './components/device-health/device-health.component';
import { DeviceLifecycleComponent } from './components/device-lifecycle/device-lifecycle.component';
import { DeviceRemoteDesktopComponent } from './components/device-remote-desktop/device-remote-desktop.component';
import { DeviceTypeComponent } from './components/device-type/device-type.component';
import { DeviceUserActivityLogComponent } from './components/device-user-activity-log/device-user-activity-log.component';
import { ProvisionDeviceDialogComponent } from './components/provision-device-dialog/provision-device-dialog.component';
import { UpdateDeviceDialogComponent } from './components/update-device-dialog/update-device-dialog.component';
import { UpdateDeviceTypeDialogComponent } from './components/update-device-type-dialog/update-device-type-dialog.component';
import { DeviceActionsPipe } from './pipes/device-actions.pipe';
import { DeviceIdPipe } from './pipes/device-id.pipe';
import { DeviceOnlineOfflinePipe } from './pipes/device-online-offline.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { DeviceApiService } from './services/device-api.service';
import { DeliverDownloadableContentComponent } from './components/deliver-downloadable-content/deliver-downloadable-content.component';

@NgModule({
  declarations: [
    DeviceHealthComponent,
    DeviceTypeComponent,
    HumanizePipe,
    DeviceLifecycleComponent,
    DeviceActionsPipe,
    DeviceOnlineOfflinePipe,
    DeviceIdPipe,
    DeviceDetailsComponent,
    DeviceDetailsCardComponent,
    DeviceDownloadableContentComponent,
    ProvisionDeviceDialogComponent,
    AttachDeviceDialogComponent,
    DeviceActionsComponent,
    UpdateDeviceDialogComponent,
    AddDeviceTypeDialogComponent,
    DeviceRemoteDesktopComponent,
    DeviceActivityLogComponent,
    DeviceUserActivityLogComponent,
    UpdateDeviceTypeDialogComponent,
    DeliverDownloadableContentComponent,
  ],
  imports: [
    CommonModule,
    MarginalsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    AscFormsModule,
    DeviceFileModule,
    LoggingModule,
    FailedJobManagementModule,
  ],
  providers: [DeviceApiService],
})
export class DeviceModule {}
