<h2 mat-dialog-title *ngIf="dialogData.dialogTitle">{{ dialogData.dialogTitle }}</h2>
<div mat-dialog-content>
  <ng-container *ngFor="let item of dialogData.components" [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'table'" [ngTemplateOutlet]="detailsSubTable" [ngTemplateOutletContext]="{ data: item }"> </ng-container>
    <ng-container *ngSwitchCase="'text'" [ngTemplateOutlet]="detailsSubText" [ngTemplateOutletContext]="{ data: item }"> </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close style="text-align: center; margin: 0 auto">Close</button>
</div>

<ng-template #detailsSubTable let-data="data">
  <h3 *ngIf="data.title" class="section-title">{{ data.title }}</h3>
  <mat-table [dataSource]="data.data">
    <ng-container [matColumnDef]="data.keyColumnId">
      <mat-header-cell *matHeaderCellDef> {{ data.keyColumnLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.label }} </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="data.valueColumnId">
      <mat-header-cell *matHeaderCellDef> {{ data.valueColumnLabel }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.value }} </mat-cell>
    </ng-container>

    <mat-header-row
      [ngClass]="data.keyColumnLabel || data.valueColumnLabel ? 'show-header' : 'hide-header'"
      *matHeaderRowDef="[data.keyColumnId, data.valueColumnId]"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: [data.keyColumnId, data.valueColumnId]"> </mat-row>
  </mat-table>
</ng-template>

<ng-template #detailsSubText let-data="data">
  <h3 *ngIf="data.title">{{ data.title }}</h3>
  <p class="details-text">{{ data.text }}</p>
</ng-template>
