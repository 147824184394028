import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { LoginComponent } from './components/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { OktaLoginCallbackComponent } from './components/okta-login-callback/okta-login-callback.component';

@NgModule({
  declarations: [LoginComponent, OktaLoginCallbackComponent],
  imports: [CommonModule, MatCardModule],
  providers: [AuthGuard, AuthService],
})
export class AuthenticationModule {}
