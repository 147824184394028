<div class="asc-option-select-container" (click)="[$event.stopPropagation()]">
  <div class="search-input-container">
    <app-asc-text-input
      [control]="searchControl"
      [name]="'search1'"
      type="search"
      [clear]="true"
      *ngIf="props.withSearch"
      (keyupEnter)="addIncomplete()"
      [placeholder]="props.inputPlaceholder ? props.inputPlaceholder : 'Search'"
    ></app-asc-text-input>
    <app-asc-icon-button *ngIf="props.addIncomplete" (btnClicked)="addIncomplete()">add</app-asc-icon-button>
  </div>

  <div class="options-container">
    <ng-container *ngIf="props.type === 'category'">
      <ng-container *ngIf="filteredSelected$ | async as sels">
        <div class="cat-sepearator-label" *ngIf="sels.length > 0">Selected</div>
        <button (click)="removeOption(sel)" *ngFor="let sel of sels" class="selector-button">
          <span>{{ props.optionLabelPredicate ? props.optionLabelPredicate(sel) : defaultLabelPredicate(sel) }}</span> <mat-icon>close</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="filteredUnselected$ | async as unsels">
        <div class="cat-sepearator-label" *ngIf="unsels.length > 0">Available</div>
        <button class="selector-button" *ngFor="let unsel of unsels" (click)="addOption(unsel)">
          <span>{{ props.optionLabelPredicate ? props.optionLabelPredicate(unsel) : defaultLabelPredicate(unsel) }}</span> <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="props.type === 'check-box'">
      <button class="selector-button" (click)="checkBoxClicked(sel)" *ngFor="let sel of checkBoxData$ | async">
        <span>{{ props.optionLabelPredicate ? props.optionLabelPredicate(sel.data) : defaultLabelPredicate(sel.data) }}</span>
        <mat-checkbox [disableRipple]="true" (click)="($event.preventDefault)" [checked]="sel.selected"></mat-checkbox>
      </button>
    </ng-container>
  </div>
</div>
