import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OngoingUploadsResponse } from 'src/app/ongoing-uploads/interfaces/OngoingUploadsResponse';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OngoingUploadsService {
  constructor(private httpClient: HttpClient) {}

  getOngoingUploads(pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string): Observable<OngoingUploadsResponse> {
    return this.httpClient.get<OngoingUploadsResponse>(`${environment.awsApi.apiBaseUrl}/files/ongoingUploads`, {
      params: createHttpParams({
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      }),
    });
  }
}
