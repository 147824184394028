export function replaceAll(base: string, search: string, replace: string) {
  return base.split(search).join(replace);
}

/**
 * Transforms camel or snake case into a normalized string with spacing.
 * @param camelOrSnake string in camel or snake case @example "error_message"
 * @returns normalized string @example "Error Message"
 */
export function toNormalizedString(camelOrSnake: string) {
  if (camelOrSnake.includes('_')) {
    return camelOrSnake
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    return camelOrSnake.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase());
  }
}
