<div class="c-page-heading-text">
  <button
    id="home_button"
    mat-icon-button
    aria-label="Reply Icon"
    (click)="goHome()"
    style="position: fixed; left: 5px"
    appAscTooltip="Go to Device List"
  >
    <mat-icon aria-hidden="false" aria-label="reply" style="margin-bottom: 20px; color: black" (click)="goHome()">home </mat-icon>
  </button>
  <p class="c-page-heading-text-element-list">{{ label }}</p>
</div>
