import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AscTabbedPageComponent } from '../../../shared/components/asc-tabbed-page/asc-tabbed-page.component';

@Component({
  selector: 'app-software-management',
  templateUrl: './software-management.component.html',
  styleUrls: ['./software-management.component.css'],
})
export class SoftwareManagementComponent extends AscTabbedPageComponent implements OnInit {
  constructor(activeRoute: ActivatedRoute, router: Router, private titleService: Title) {
    super(activeRoute, router);
  }

  ngOnInit(): void {
    this.titleService.setTitle('ASC - Software Management');
  }

  goHome(): void {
    this.router.navigate(['devicelist']);
  }
}
