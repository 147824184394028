import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorResponse } from '../interfaces/ErrorResponse';

const DEFAULT_MESSAGES: { [k in HttpStatusCode]?: string } = {
  400: 'The UI made a bad request to the server',
  401: 'Authorization failed',
  403: 'Access to requested resource denied',
  404: 'Requested resource not found',
  405: 'There was a problem with the request sent to the server',
  412: 'Preconditions required for this request were not met',
  413: 'Request Entity Too Large',
  416: 'Requested Range Not Satisfiable',
  500: 'An unexpected error was returned from the server',
  504: 'Request took too long to resolve',
};

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private toastr: ToastrService) {}

  handleHttpError(e: HttpErrorResponse, contextMessage: string): void {
    let message = e.error?.message;
    if (message === '' || message === 'null') {
      message = null;
    }
    const defaultMessage = DEFAULT_MESSAGES[e.status as HttpStatusCode] ?? 'Error';
    this.toastr.error([contextMessage, message || defaultMessage].join(': '), 'Error');
  }
  httpErrorToString(e: HttpErrorResponse | Error, contextMessage?: string): string {
    let message: string | null = '';
    let status: number | undefined = undefined;

    if (e instanceof HttpErrorResponse) {
      message = e.error.message;

      status = e.status;
    } else if (e instanceof Error) {
      message = e.message;
    }
    if (message === '' || message === 'null') {
      message = null;
    }
    const defaultMessage = status ? DEFAULT_MESSAGES[status as HttpStatusCode] : 'Error';
    return `${contextMessage ? contextMessage + ':' : ''} ${message || defaultMessage}`;
  }

  httpErrorToErrorResponse(e: HttpErrorResponse | Error, contextMessage?: string): ErrorResponse {
    let response: ErrorResponse;
    let status: number | undefined;
    if (e instanceof HttpErrorResponse) {
      response = e.error as ErrorResponse;
    } else if (e instanceof Error) {
      response = {
        message: e.message,
        errorCode: 'CLIENT_ERROR',
        parameters: [],
        path: 'none',
      };
    } else {
      response = {
        message: DEFAULT_MESSAGES[status as HttpStatusCode] || 'Error',
        errorCode: 'CLIENT_ERROR',
        parameters: [],
        path: 'none',
      };
    }

    response.message = `${contextMessage ? contextMessage + ':' : ''} ${response.message}`;
    return response;
  }
}
