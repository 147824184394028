<app-asc-dialog-wrapper>
  <ng-container dialogTitle>Add new Device Type</ng-container>
  <ng-container dialogContent>
    <div class="add-device-content">
      <app-asc-text-input [control]="formGroup.controls.name" label="Device Type Name" placeholder="Device Type..."
        errorMessage="Enter a Device Type Name"></app-asc-text-input>
      <mat-checkbox [formControl]="formGroup.controls.updateDhs">Update DHS</mat-checkbox>
      <mat-checkbox [formControl]="formGroup.controls.unpackArchives">Unpack Archives</mat-checkbox>
      <mat-checkbox [formControl]="formGroup.controls.sMaxMatch">SMAX match</mat-checkbox>
      <mat-card class="remote-session-type">
        <mat-card-header>
          <mat-card-subtitle>
            <mat-card-title>Remote Session Types</mat-card-title>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-checkbox [formControl]="formGroup.controls.gateKeeper">Gate Keeper Connection</mat-checkbox>
          <mat-checkbox [formControl]="formGroup.controls.quickConnection">Quick Connection</mat-checkbox>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  <app-asc-button dialogActionButton type="primary" (btnClicked)="addDeviceType()" [disabled]="formGroup.invalid">Add
    Device Type</app-asc-button>
  <app-asc-button dialogCancelButton type="cancel" (btnClicked)="cancel()">Cancel</app-asc-button>
</app-asc-dialog-wrapper>