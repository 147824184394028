import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Device } from 'src/app/device/interfaces/Device';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { DeviceLogParserMonitoringDataSource } from '../../interfaces/DeviceLogParserMonitoringDataSource';
import { ParserMonitoringLog } from '../../interfaces/ParserMonitoringLog';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-device-log-parser-monitoring',
  templateUrl: './device-log-parser-monitoring.component.html',
  styleUrls: ['./device-log-parser-monitoring.component.css'],
})
export class DeviceLogParserMonitoringComponent implements OnInit, StorageOperations, OnDestroy {
  dataSource: DeviceLogParserMonitoringDataSource;
  pageables: ConfigPageEvent;
  sort: Sort;

  columns: ConfigureableTableColumn<ParserMonitoringLog>[] = [
    {
      sortable: true,
      name: 'parserId',
      header: 'Parser Name',
    },
    {
      sortable: true,
      name: 'fileId',
      header: 'File Name',
    },
    {
      sortable: true,
      name: 'code',
      header: 'Code',
    },
    {
      sortable: false,
      name: 'message',
      header: 'Message',
    },
    {
      sortable: true,
      name: 'eventType',
      header: 'Event Type',
    },
    {
      sortable: true,
      name: 'eventTimestamp',
      header: 'Event Timestamp',
    },
  ];

  @Input() device: Device;

  constructor(private loggingApi: LoggingService, private store: AppStorageService) {
    this.dataSource = new DeviceLogParserMonitoringDataSource(this.loggingApi);

    window.onbeforeunload = () => this.saveStorageData();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('device_parsermonitorelog_sort_column') || '',
      direction: this.store.getValue('device_parsermonitorelog_sort_direction') || '',
    };

    this.pageables = {
      pageSize: this.store.getValue('device_parsermonitorelog_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }

  loadData() {
    this.dataSource.loadMonitoringLog(
      this.device.id,
      this.pageables.pageSize,
      this.pageables.pageIndex,
      this.sort?.active && this.sort.direction ? this.sort.active : '',
      this.sort?.direction || ''
    );
  }

  saveStorageData(): void {
    this.store.setValue('device_parsermonitorelog_sort_column', this.sort.active);
    this.store.setValue('device_parsermonitorelog_sort_direction', this.sort.direction);
    this.store.setValue('device_parsermonitorelog_pagination_pagesize', this.pageables.pageSize);
  }
  ngOnDestroy(): void {
    this.saveStorageData();
  }
}
