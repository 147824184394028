<mat-expansion-panel class="c-expansion-panel mat-elevation-z0" [togglePosition]="'before'">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span matTooltip="Device Type"> {{ device.deviceType }} </span> <span class="bullet">•</span>
      <span matTooltip="Serial Number"> {{ device.serialNumber }}</span>
    </mat-panel-title>
    <mat-panel-description>
      <div class="device-status">
        <mat-icon *ngIf="device.parentDeviceId" style="margin-right: 10px" matTooltip="Satellite Device">satellite_alt</mat-icon>

        <ng-container *ngIf="device | deviceOnlineOffline as offOn">
          <mat-icon [ngStyle]="{ color: offOn.color, marginRight: '10px' }" [matTooltip]="offOn.label">{{ offOn.icon }}</mat-icon>
        </ng-container>
        {{ device.status }}
      </div>
      <div class="device-actions">
        <app-device-actions [device]="device"></app-device-actions>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="device-details-container">
    <ng-container *ngIf="device | deviceId as idProps">
      <div class="details-col">
        <div>ID:</div>
        <div *ngIf="idProps.hardwareId">Hardware ID:</div>
        <div *ngIf="idProps.regIdNeeded">Registration ID:</div>
      </div>
      <div class="details-col">
        <div [appClipboardCopy]="idProps.id">{{ idProps.id }}</div>
        <div [appClipboardCopy]="idProps.hardwareId" *ngIf="idProps.hardwareId">{{ idProps.hardwareId }}</div>
        <div [appClipboardCopy]="idProps.registrationId" *ngIf="idProps.regIdNeeded">
          {{ idProps.registrationId }} {{ idProps.expired ? '(expired)' : '' }}
        </div>
      </div>
    </ng-container>

    <div class="vertical-divider"></div>

    <div class="details-col">
      <p>Note:</p>
    </div>
    <div class="details-col">
      <p>{{ device.note || 'N/A' }}</p>
    </div>
    <ng-container *ngIf="device.parentDeviceId">
      <div class="vertical-divider"></div>
      <div class="details-col button-parent">
        <app-asc-inline-button cType="secondary" (btnClicked)="goToParent(device.parentDeviceId)">Parent</app-asc-inline-button>
      </div>
    </ng-container>
    <div class="vertical-divider"></div>
    <div class="details-col">
      <div>Update DHS:</div>
      <div>Allows Remote Access:</div>
      <div>Training Device:</div>
    </div>
    <div class="details-col">
      <mat-icon [ngStyle]="{ color: device.updateDHS ? 'green' : '#236fb8' }">{{ device.updateDHS ? 'check_circle' : 'cancel' }}</mat-icon>
      <mat-icon [ngStyle]="{ color: device.allowRemoteDesktopAccess ? 'green' : '#236fb8' }">{{
        device.allowRemoteDesktopAccess ? 'check_circle' : 'cancel'
      }}</mat-icon>
      <mat-icon [ngStyle]="{ color: device.trainingDevice ? 'green' : '#236fb8' }">{{ device.trainingDevice ? 'check_circle' : 'cancel' }}</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

<mat-menu #actionsMenu="matMenu">
  <ng-template matMenuContent let-device="device">
    <ng-container *ngIf="device | deviceActions as actions">
      <h4 class="menu-header">Action{{ actions.length > 1 ? 's' : '' }}</h4>
      <button mat-menu-item *ngFor="let action of actions">
        <mat-icon>{{ action.matIcon }}</mat-icon
        >{{ action.actionName }}
      </button>
      <mat-divider style="margin-bottom: 6px"></mat-divider>
    </ng-container>
  </ng-template>
</mat-menu>
