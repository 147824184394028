import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { DownloadableContent } from 'src/app/shared/interfaces/DownloadableContent';
import { SoftwareManagementService } from '../services/software-management.service';

export class DownloadableContentDataSource extends DataSourceBaseImpl<DownloadableContent> {
  constructor(private softwareApi: SoftwareManagementService) {
    super();
  }
  loadDownloadableContent(pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string, deviceType: string | undefined) {
    this.loadingSubject.next(true);
    this.softwareApi
      .getDownloadableContentList(pageSize, pageIndex, sortColumn, sortDirection, deviceType)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((software) => {
        if (software.content) {
          this.dataSubject.next(software.content);
        } else {
          this.dataSubject.next([]);
        }
        this.totalSizeSubject.next(software.totalSize);
      });
  }
}
