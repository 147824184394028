<div class="c-login-background" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" width="500px">
    <img class="c-login-company-logo" src="../../assets/alconLogo.png" style="padding: 25px" />

    <p *ngIf="useOktaAutomaticLogin" style="margin: 60px 0; text-align: center">Login with Okta in progress.<br />Please wait.</p>

    <mat-card *ngIf="showError || showSessionExpiryError" class="c-login-error-notice">
      <p *ngIf="showError" class="c-login-error-notice-text">Username and password did not match</p>
      <p *ngIf="showSessionExpiryError" class="c-login-error-notice-text">Session Expired. Please log in again</p>
    </mat-card>

    <div style="margin-top: 20px; font-size: small">Server Version: {{ ascVersion }}</div>
  </mat-card>
</div>
