import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { LoggingService } from '../services/logging.service';
import { ParserMonitoringLog } from './ParserMonitoringLog';

export class ParserLogParserMonitoringDataSource extends DataSourceBaseImpl<ParserMonitoringLog> {
  constructor(private loggingApi: LoggingService) {
    super();
  }

  loadMonitoringLog(
    parserId: number,
    deviceSerialNumber: string,
    fileName: string,
    eventTimeFrom: string,
    eventTimeUntil: string,
    eventDetectionTimeFrom: string,
    eventDetectionTimeUntil: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string
  ) {
    this.errorSubject.next(null);
    this.loadingSubject.next(true);
    this.clearData();
    this.loggingApi
      .getParsingLogsPerParser(
        parserId,
        deviceSerialNumber,
        fileName,
        eventTimeFrom,
        eventTimeUntil,
        eventDetectionTimeFrom,
        eventDetectionTimeUntil,
        pageSize,
        pageIndex,
        sortColumn,
        sortDirection
      )
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((resp) => {
        if (resp.content) {
          this.dataSubject.next(resp.content);
        }
        this.totalSizeSubject.next(resp.totalSize);
      });
  }

  clearData() {
    this.dataSubject.next([]);
    this.totalSizeSubject.next(0);
  }
}
