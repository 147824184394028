import { Pipe, PipeTransform } from '@angular/core';
import { Device, DeviceStatus } from '../interfaces/Device';

type OnOffDeviceIcon = { label: 'Online' | 'Offline'; icon: 'sensors' | 'sensors_off'; color: '#339BFF' | 'orange' | '#D2232A' };

@Pipe({
  name: 'deviceOnlineOffline',
})
export class DeviceOnlineOfflinePipe implements PipeTransform {
  transform(device: Device, ...args: unknown[]): OnOffDeviceIcon {
    switch (device.status) {
      case DeviceStatus.REGISTRATION_ID_ISSUED:
      case DeviceStatus.CERTIFICATE_ISSUED:
      case DeviceStatus.REGISTERED:
        return { label: 'Online', icon: 'sensors', color: '#339BFF' };

      case DeviceStatus.BLOCKED:
        return { label: 'Offline', icon: 'sensors_off', color: '#D2232A' };
      default:
        return { label: 'Offline', icon: 'sensors', color: 'orange' };
    }
  }
}
