import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
  declarations: [PageNotFoundComponent, AboutPageComponent],
  imports: [CommonModule, MarginalsModule, MaterialModule],
})
export class AdditionalPagesModule {}
