<sub-header label="Software Management"> </sub-header>

<div class="software-tabs">
  <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged()">
    <mat-tab label="Software Library">
      <ng-template matTabContent>
        <app-software-library> </app-software-library>
      </ng-template>
    </mat-tab>
    <mat-tab label="Device Assignment">
      <ng-template matTabContent>
        <app-software-per-devices> </app-software-per-devices>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
