<mat-card class="error-message-card mat-elevation-z4" [ngClass]="data.type.toLowerCase() + '-color'">
  <mat-card-header>
    <mat-card-title>
      <mat-icon class="type-icon" [ngClass]="data.type.toLowerCase() + '-color'">{{
        data.type === 'SUCCESS' ? 'check_circle' : data.type === 'ERROR' ? 'error' : data.type === 'WARNING' ? 'warning' : null
      }}</mat-icon>
      {{ data.title }}</mat-card-title
    >
    <mat-card-subtitle
      ><i>{{ data.timestamp | date : 'yyyy-MM-dd, HH:mm:ss' }}</i>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="card-content">
    {{ data.message }}
  </mat-card-content>
  <mat-card-actions class="card-actions">
    <mat-icon class="card-button" appAscTooltip="Copy Content" (click)="copyContent()">content_copy</mat-icon>
    <mat-icon class="card-button" appAscTooltip="Clear" (click)="deleteCard()">clear</mat-icon>
  </mat-card-actions>
</mat-card>
