import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, of } from 'rxjs';
import { DeviceType } from 'src/app/device/interfaces/DeviceType';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { DeviceApiService } from '../../services/device-api.service';

export interface UpdateDialogData {
  deviceType: DeviceType;
}

@Component({
  selector: 'app-update-device-type-dialog',
  templateUrl: './update-device-type-dialog.component.html',
  styleUrls: ['./update-device-type-dialog.component.css'],
})
export class UpdateDeviceTypeDialogComponent implements OnInit {
  formGroup: FormGroup<UpdateFormGroup> = new FormGroup<UpdateFormGroup>({
    gateKeeper: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    quickConnection: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    sMaxMatch: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    updateDhs: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
    unpackArchives: new FormControl<boolean>(false, { nonNullable: true, validators: [] }),
  });

  data: UpdateDialogData;
  loading = false;

  constructor(
    private deviceApi: DeviceApiService,
    @Inject(MAT_DIALOG_DATA) dialogData: UpdateDialogData,
    private toasts: ToastsService,
    private dialogRef: MatDialogRef<UpdateDeviceTypeDialogComponent, Partial<DialogResponse<unknown>>>
  ) {
    this.data = dialogData;
  }

  ngOnInit(): void {
    this.formGroup.controls.gateKeeper.setValue(this.data.deviceType.gateKeeperConnection);
    this.formGroup.controls.quickConnection.setValue(this.data.deviceType.quickConnection);
    this.formGroup.controls.sMaxMatch.setValue(this.data.deviceType.smaxMatch);
    this.formGroup.controls.updateDhs.setValue(this.data.deviceType.updateDhs);
    this.formGroup.controls.unpackArchives.setValue(this.data.deviceType.unpackUploadedArchives);
    this.formGroup.markAsDirty();
  }

  actionButtonClicked() {
    this.deviceApi
      .updateDeviceType(this.data.deviceType.deviceTypeId, {
        sMaxMatch: this.formGroup.controls.sMaxMatch.value,
        gateKeeperConnection: this.formGroup.controls.gateKeeper.value,
        quickConnection: this.formGroup.controls.quickConnection.value,
        updateDhs: this.formGroup.controls.updateDhs.value,
        unpackUploadedArchives: this.formGroup.controls.unpackArchives.value,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.toasts.raise({ title: 'Edit Device', message: `Could not update device type  ${this.data.deviceType.deviceType}` }, 'ERROR');
          this.dialogRef.close({ status: 'ERROR', code: err.status });
          return of();
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.toasts.raise({ title: 'Edit Device', message: `Updated device type ${this.data.deviceType.deviceType}` }, 'SUCCESS', false);
        this.dialogRef.close({ status: 'SUCCESS', code: 201 });
      });
  }

  closeButtonClicked() {
    this.dialogRef.close();
  }
}

interface UpdateFormGroup {
  gateKeeper: FormControl<boolean>;
  quickConnection: FormControl<boolean>;
  sMaxMatch: FormControl<boolean>;
  updateDhs: FormControl<boolean>;
  unpackArchives: FormControl<boolean>;
}
