import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import * as SparkMD5 from 'spark-md5';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { FileApiService } from './file-api.service';

@Injectable({
  providedIn: 'root',
})

// Keeps the all file uploads for this session to the devices cloud space
export class FileTransferService {
  finishedTransfer = new BehaviorSubject<number>(0);
  currentTransfers = new BehaviorSubject<number>(0);
  // stores the file transfers
  private deviceFileTransfers: Map<string, Array<FileTransfer>> = new Map<string, Array<FileTransfer>>();
  private queue: FileTransfer[] = [];

  constructor(private apiService: FileApiService, private toasts: ToastsService) {}

  addFileTransfer(deviceId: string, deviceType: string, file: File, type: string): void {
    console.log('Start a file transfer for file: ' + file.name);
    const newFileTransfer: FileTransfer = new FileTransfer(deviceId, deviceType, file, type, this.apiService, this.toasts);

    let fileTransferArray = this.deviceFileTransfers.get(deviceId);
    if (fileTransferArray) {
      newFileTransfer.id = fileTransferArray.length;
      fileTransferArray.push(newFileTransfer);
    } else {
      fileTransferArray = new Array<FileTransfer>();
      newFileTransfer.id = 0;
      fileTransferArray.push(newFileTransfer);
      this.deviceFileTransfers.set(deviceId, fileTransferArray);
    }
    this.queue.push(newFileTransfer);
    if (this.queue.length === 1) {
      this.startNextTransfer();
    }
  }

  public removeTransfer(transferId: number, deviceId: string) {
    const transfers = this.deviceFileTransfers.get(deviceId);
    if (!transfers) return;

    const index = transfers.findIndex((entry) => entry.id === transferId);
    if (index === -1) {
      return;
    }
    if (transfers[index].status === FileTransferStatus.Cancelled || transfers[index].status === FileTransferStatus.Failure) {
      transfers.splice(index, 1);
      this.deviceFileTransfers.set(deviceId, transfers);
    }
  }

  private startNextTransfer(): void {
    // get next file transfer, if any.
    if (this.queue.length > 0) {
      this.execute(this.queue[0]);
    }
  }

  private execute(fileTransfer: FileTransfer): void {
    //
    fileTransfer.upload().then(() => {
      this.queue.shift();
      this.finishedTransfer.next(this.finishedTransfer.value + 1);
      this.startNextTransfer();
    });
  }

  getTransfers(deviceId: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string): FileTransfers {
    const fileTransfers: FileTransfers = new FileTransfers();
    // copy the array
    const tmp = this.deviceFileTransfers.get(deviceId);
    if (tmp) {
      const start = pageIndex * pageSize;
      const end = start + pageSize;
      fileTransfers.transfers = tmp.slice(start, end);
      fileTransfers.totalTransfers = tmp.length;
    }

    return fileTransfers;
  }

  cancelTransfer(deviceId: string, elementNumber: number): void {
    const fileTransfers = this.deviceFileTransfers.get(deviceId);
    if (fileTransfers) {
      if (fileTransfers.length > elementNumber) {
        const fileTransfer = fileTransfers[elementNumber];
        fileTransfer.cancel();
      }
    }
  }
}

export class FileTransfers {
  transfers: FileTransfer[];
  totalTransfers: number;
}

export enum FileTransferStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure',
  Cancelled = 'Cancelled',
  NotFound = 'Not Found',
}

// a single file transfer (upload) to a device cloud space
export class FileTransfer {
  started: Date;
  progress = 0;
  md5: string;
  error: string;
  status: FileTransferStatus = FileTransferStatus.Pending;
  checksum: string;
  checksumAlgorithm: string;
  id: number;
  fileUid: string;
  lastModificationDate: string;

  constructor(
    private deviceId: string,
    private DeviceType: string,
    public file: File,
    public type: string,
    private apiService: FileApiService,
    private toasts: ToastsService
  ) {
    this.started = new Date();
  }

  convertTimestampToUTCDate(timestamp: number): string {
    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    // Convert the date to a UTC string (ISO 8601 format)
    const utcDateString = date.toISOString();

    return utcDateString;
  }

  // OnClick of button Upload
  async upload(): Promise<void> {
    if (this.status === FileTransferStatus.Cancelled) {
      return;
    }

    // calculate md5 checksum for the file metadata
    this.checksum = await this.computeMd5CheckSum(this.file);

    this.lastModificationDate = this.convertTimestampToUTCDate(this.file.lastModified);

    const metadata = {
      DEVICE_ID: this.deviceId,
      DEVICE_TYPE: this.DeviceType,
      FILE_SIZE: this.file.size,
      FILE_NAME: this.file.name,
      FILE_TYPE: this.type,
      FILE_MODIFICATION_DATE: this.lastModificationDate,
      CHECKSUM_ALGORITHM: 'MD5',
      CHECKSUM: this.checksum,
    };

    try {
      const initResp = await lastValueFrom(this.apiService.initMultiPartUpload(this.deviceId, metadata));
      this.status = FileTransferStatus.InProgress;
      const minChunkSize = initResp.minChunkSize || 40000;
      this.fileUid = initResp.fileUuid || '';
      let filePart = 1;

      for (let offset = 0; offset < this.file.size; offset += minChunkSize) {
        const slice = this.file.slice(offset, offset + minChunkSize);
        const partFile = new File([slice], this.file.name, {
          type: this.file.type,
        });
        const partChecksum = await this.computeMd5HashBase64encoded(slice);
        const partDetails = await lastValueFrom(
          this.apiService.getMultiPartUploadUrl(this.deviceId, this.fileUid, filePart, slice.size, partChecksum)
        );
        const status: any = this.status;
        // if the file transfer is cancelled exit here
        if (status === FileTransferStatus.Cancelled) {
          return;
        }
        await lastValueFrom(this.apiService.uploadMultiPart(partDetails.preSignedUrl || '', partFile, partChecksum, partFile.size.toString()));
        filePart++;
        this.progress = ((filePart * minChunkSize) / this.file.size) * 100;
      }

      await lastValueFrom(this.apiService.completeMultiPartUpload(this.deviceId, this.fileUid));
    } catch (error) {
      console.error('Unable to upload file', error);
      const status: any = this.status;
      // if the file transfer is cancelled exit here
      if (status === FileTransferStatus.Cancelled) {
        this.toasts.raise({ message: 'The tranfer of the file ' + this.file.name + ' was cancelled.', title: 'File Transfer' }, 'INFO', false);
      } else {
        this.status = FileTransferStatus.Failure;
        this.toasts.raise(
          { message: `Couldn't upload the File ${this.file.name}`, title: 'File Transfer', error: error as HttpErrorResponse },
          'ERROR'
        );
      }
      return;
    }

    this.status = FileTransferStatus.Success;
    this.toasts.raise({ message: 'File ' + this.file.name + ' uploaded', title: 'File Transfer' }, 'SUCCESS', false);
  }

  async cancel(): Promise<void> {
    try {
      console.log('Cancel file transfer ' + this.fileUid + ' for device ' + this.deviceId);
      if (this.status === FileTransferStatus.InProgress) {
        await lastValueFrom(this.apiService.cancelMultiPartUpload(this.deviceId, this.fileUid));
        this.status = FileTransferStatus.Cancelled;
      } else if (this.status === FileTransferStatus.Pending) {
        this.status = FileTransferStatus.Cancelled;
      }
    } catch (error) {
      console.error('Unable to upload file', error);
      this.status = FileTransferStatus.Failure;
      this.toasts.raise(
        {
          message: "Couldn't cancel the transfer of file " + this.file.name + ' for device ' + this.deviceId,
          title: 'File Transfer',
          error: error as Error,
        },
        'ERROR'
      );
    }
  }

  computeMd5HashBase64encoded(chunk: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(chunk);

      fileReader.onerror = (): void => {
        reject('MD5 computation failed - error reading the file');
      };

      fileReader.onloadend = (e: any): void => {
        spark.append(e.target.result);
        resolve(btoa(spark.end(true)));
      };
    });
  }

  // generates a md5 hash for a complete file
  // returns the hash as a string in form of '7CF530335B8547945F1A48880BC421B2'
  computeMd5CheckSum(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      fileReader.onerror = (): void => {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunkStart: number): void {
        const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunkStart, chunkEnd));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = (e: any): void => {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // returns the hexdigest form (looking like
          // '7CF530335B8547945F1A48880BC421B2')
          resolve(spark.end().toUpperCase());
        }
      };

      processChunk(0);
    });
  }
}
