import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { AscOptionSelectSearchProps, MultiSingleControl } from '../../interfaces/AscOptionSelectSearchProps';
import { AscTextInputComponent } from '../asc-text-input/asc-text-input.component';

@Component({
  selector: 'app-asc-option-select-search',
  templateUrl: './asc-option-select-search.component.html',
  styleUrls: ['./asc-option-select-search.component.css'],
})
export class AscOptionSelectSearchComponent<T = unknown> implements OnInit, OnDestroy {
  @Input() optionSelectProps: AscOptionSelectSearchProps<'single' | 'multiple', T>;
  @Input() optionData: T[];
  @Input() options$: Observable<T[]>;
  isError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filteredData: T[];
  filteredData$: Observable<T[]>;

  searchControl: FormControl<string | null> = new FormControl('');
  minWidth = 240;

  // Subscriptions
  filterSub: Subscription;

  @ViewChild('searchInput') searchInput: AscTextInputComponent;

  ngOnInit(): void {
    if (!this.optionSelectProps) {
      console.error('No properties provided');
      return;
    }

    this.setFilteredData();

    if (!this.optionSelectProps.searchable) {
      return;
    }

    this.searchControl = new FormControl(null);
    this.searchControl.valueChanges.subscribe((val) => {
      this.setFilteredData(val);
    });
  }

  optionSelected(option: T) {
    this.optionSelectProps.control.setValue(
      this.optionSelectProps.multiple
        ? [option, ...(this.optionSelectProps.control as MultiSingleControl<'multiple', T>).value]
        : (this.optionSelectProps.control as MultiSingleControl<'single', T>).value
    );
    this.searchControl.setValue(this.optionSelectProps.selectLabelPredicate(option));
  }

  setFilteredData(searchVal?: string | null) {
    if (!searchVal) {
      this.filteredData$ = this.options$;
      return;
    }
    this.filteredData$ = this.options$.pipe(
      map((options) => options.filter((option) => this.optionSelectProps.filterPredicate(searchVal, option))),
      map((filtered) => {
        if (!this.optionSelectProps.searchInputToOption === undefined) {
          return filtered;
        }
        let matching: T | null = null;
        filtered.forEach((inp) => {
          matching =
            this.optionSelectProps.searchInputToOption && !this.optionSelectProps.multiple
              ? this.optionSelectProps.searchInputToOption(searchVal, inp)
              : null;
        });
        if (matching && !this.optionSelectProps.multiple) {
          this.optionSelectProps.control.setValue(matching);
        }

        return filtered;
      })
    );
  }

  getTriggerData(data: T[] | T): string {
    if (!Array.isArray(data)) {
      return this.optionSelectProps.selectLabelPredicate(data);
    }
    return data.reduce((prev, curr, index) => {
      if (index < 2) {
        prev += `${this.optionSelectProps.selectLabelPredicate(curr)}${index === 0 && index < data.length - 1 ? ', ' : ' '}`;
        return prev;
      }
      if (index === data.length - 1) {
        prev = `${prev} + ${index - 1} others`;
        return prev;
      }
      return prev;
    }, '');
  }

  clearInput() {
    this.optionSelectProps.control.setValue(null);
  }

  blurInput() {
    this.optionSelectProps.control.markAsTouched();
  }

  checkError() {
    this.optionSelectProps.control.valueChanges.subscribe(() => {
      this.isError$.next(!this.optionSelectProps.control.valid);
    });
  }

  ngOnDestroy(): void {
    this.filterSub.unsubscribe();
  }
}
