import { SortDirection } from '@angular/material/sort';
import { catchError, finalize, map, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { DeviceApiService } from '../services/device-api.service';
import { Device } from './Device';

export class DeviceListDataSource extends DataSourceBaseImpl<Device> {
  constructor(private deviceApiService: DeviceApiService) {
    super();
  }

  loadDevices(
    searchTexts: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: SortDirection,
    assignedDlc?: { assignedDlcUuid?: string; notAssignedDlcUuid?: string },
    deviceTypes?: string[]
  ) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.totalSizeSubject.next(0);
    this.dataSubject.next([]);
    this.deviceApiService
      .getDeviceList(
        {
          deviceTypes: deviceTypes,
          searchText: searchTexts,
          pageIndex: pageIndex,
          pageSize: pageSize,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
          assignedDlcUuid: assignedDlc?.assignedDlcUuid,
          notAssignedDlcUuid: assignedDlc?.notAssignedDlcUuid,
        },
        'v2'
      )
      .pipe(
        map((deviceList) => {
          if (!deviceList.content) {
            return deviceList;
          }
          return deviceList;
        }),
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((resp) => {
        if (resp.content) {
          this.dataSubject.next(resp.content);
        }
        this.totalSizeSubject.next(resp.totalSize);
      });
  }
}
