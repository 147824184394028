import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OngoingUploadsContent } from 'src/app/ongoing-uploads/interfaces/OngoingUploadsContent';
import { OngoingUploadsService } from '../../services/ongoing-uploads.service';

@Component({
  selector: 'app-ongoing-uploads',
  templateUrl: './ongoing-uploads.component.html',
  styleUrls: ['./ongoing-uploads.component.css'],
})
export class OngoingUploadsComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private titleService: Title, private apiService: OngoingUploadsService) {}
  isError = false;
  isLoading = false;
  displayedColumns = ['fileName', 'deviceId', 'deviceType', 'customer', 'uploadStart', 'totalFileSize', 'currentUploadSize', 'actions'];
  dataSource = new MatTableDataSource<OngoingUploadsContent>();

  // paginator properties
  pageEvent: PageEvent;
  totalSize: number;
  totalPages: number;
  tableInfoText = 'No data';

  // sort properties
  sortColumn = '';
  sortDirection = '';

  pageSize = 10;
  pageIndex = 0;

  // eslint-disable-next-line
  // @ts-ignore
  timeoutIdentifier: NodeJS.Timeout;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnDestroy(): void {
    clearTimeout(this.timeoutIdentifier);
  }

  ngOnInit(): void {
    // calling the api
    this.isLoading = true;

    // initialize session variables if not
    if (sessionStorage.getItem('ongoingUploadsPageSize') == null && sessionStorage.getItem('ongoingUploadsPageIndex') == null) {
      sessionStorage.setItem('ongoingUploadsPageSize', '10');
      sessionStorage.setItem('ongoingUploadsPageIndex', '0');
    } else {
      this.pageSize = parseInt(sessionStorage.getItem('ongoingUploadsPageSize') || '10', 10);
      this.pageIndex = parseInt(sessionStorage.getItem('ongoingUploadsPageIndex') || '0', 10);
    }

    this.getOngoingUploadsData();
  }

  getOngoingUploadsData(): void {
    this.apiService.getOngoingUploads(this.pageSize, this.pageIndex, this.sortColumn, this.sortDirection).subscribe({
      next: (data) => {
        console.log('----Received data from ongoing uploads api----');
        if (data.content) {
          this.dataSource.data = data.content;
        } else {
          this.dataSource.data = [];
        }
        this.totalSize = data.totalSize;
        this.isLoading = false;
        this.isError = false;

        this.timeoutIdentifier = setTimeout(() => {
          this.getOngoingUploadsData();
        }, 3000);
      },
      error: (error) => {
        console.log('Error in ongoing uploads API', error);
        this.tableInfoText = 'Sorry, something went wrong !!';
        this.isError = true;
        this.isLoading = false;
      },
    });
  }

  getTimeZone(): string {
    return '(' + moment().tz(moment.tz.guess()).format('z') + ')';
  }

  paginatorClicked(event?: PageEvent): void {
    this.pageSize = event?.pageSize !== undefined ? event?.pageSize : 0;
    this.pageIndex = event?.pageIndex !== undefined ? event?.pageIndex : 0;
    sessionStorage.setItem('ongoingUploadsPageSize', this.pageSize.toString());
    sessionStorage.setItem('ongoingUploadsPageIndex', this.pageIndex.toString());
    clearTimeout(this.timeoutIdentifier);
    this.getOngoingUploadsData();
  }

  sortClicked(): void {
    if (this.sort.direction === '') {
      this.sortColumn = '';
      this.sortDirection = '';
    } else {
      this.sortColumn = this.sort.active;
      this.sortDirection = this.sort.direction;
    }
    clearTimeout(this.timeoutIdentifier);
    this.getOngoingUploadsData();
  }

  goHome(): void {
    this.router.navigate(['devicelist']).then();
  }

  calculatePercentage(totalSize: number, currentSize: number): number {
    return Math.ceil((currentSize / totalSize) * 100);
  }
}
