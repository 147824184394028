import { Component } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, of } from 'rxjs';

import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { DeviceApiService } from '../../services/device-api.service';

@Component({
  selector: 'app-add-device-type-dialog',
  templateUrl: './add-device-type-dialog.component.html',
  styleUrls: ['./add-device-type-dialog.component.css'],
})
export class AddDeviceTypeDialogComponent {
  formGroup: FormGroup<AddDeviceTypeForm> = new FormGroup({
    name: new FormControl('', { nonNullable: true, validators: Validators.required }),
    updateDhs: new FormControl<boolean>(false, { nonNullable: true }),
    unpackArchives: new FormControl<boolean>(false, { nonNullable: true }),
    sMaxMatch: new FormControl<boolean>(false, { nonNullable: true }),
    gateKeeper: new FormControl<boolean>(false, { nonNullable: true }),
    quickConnection: new FormControl<boolean>(false, { nonNullable: true }),
  });
  constructor(
    private dialogRef: MatDialogRef<unknown, Partial<DialogResponse>>,
    private deviceApi: DeviceApiService,
    private toasts: ToastsService
  ) {}

  closeDialog(action: 'CANCEL' | 'ERROR' | 'SUCCESS') {
    this.dialogRef.close({ status: action });
  }
  addDeviceType() {
    if (this.formGroup.invalid) return;
    this.deviceApi
      .addDeviceType(
        this.formGroup.controls.name.value,
        '',
        false,
        this.formGroup.controls.updateDhs.value,
        this.formGroup.controls.unpackArchives.value,
        this.formGroup.controls.sMaxMatch.value,
        this.formGroup.controls.gateKeeper.value,
        this.formGroup.controls.quickConnection.value
      )
      .pipe(
        catchError((err) => {
          this.toasts.raise(
            { title: 'Add Device Type', message: 'Could not add new Device Type with name ' + this.formGroup.controls.name.value, error: err },
            'ERROR'
          );
          this.closeDialog('ERROR');
          return of();
        })
      )
      .subscribe(() => {
        this.toasts.raise({ title: 'Add Device Type', message: 'Added new Device Type with name ' + this.formGroup.controls.name.value }, 'SUCCESS');
        this.closeDialog('SUCCESS');
      });
  }
  cancel() {
    this.closeDialog('CANCEL');
  }
}

interface AddDeviceTypeForm {
  name: FormControl<string>;
  updateDhs: FormControl<boolean>;
  unpackArchives: FormControl<boolean>;
  sMaxMatch: FormControl<boolean>;
  gateKeeper: FormControl<boolean>;
  quickConnection: FormControl<boolean>;
}
