import { DateRangeRelative } from '../interfaces/DateRangeRelative';

export const MINUTE_RANGE: DateRangeRelative[] = [
  {
    seconds: 300,
    label: '5',
    unit: 'minute',
  },
  {
    seconds: 900,
    label: '15',
    unit: 'minute',
  },
  {
    seconds: 1800,
    label: '30',
    unit: 'minute',
  },
  {
    seconds: 2700,
    label: '45',
    unit: 'minute',
  },
];

export const HOUR_RANGE: DateRangeRelative[] = [
  {
    seconds: 3600,
    label: '1',
    unit: 'hour',
  },
  {
    seconds: 7200,
    label: '2',
    unit: 'hour',
  },
  {
    seconds: 21600,
    label: '6',
    unit: 'hour',
  },
  {
    seconds: 43200,
    label: '12',
    unit: 'hour',
  },
];
export const DAY_RANGE: DateRangeRelative[] = [
  {
    seconds: 86_400,
    label: '1',
    unit: 'day',
  },
  {
    seconds: 172_800,
    label: '2',
    unit: 'day',
  },
  {
    seconds: 345_600,
    label: '4',
    unit: 'day',
  },
  {
    seconds: 518_400,
    label: '6',
    unit: 'day',
  },
];

export const WEEK_RANGE: DateRangeRelative[] = [
  {
    seconds: 604_800,
    label: '1',
    unit: 'week',
  },
  {
    seconds: 1_209_600,
    label: '2',
    unit: 'week',
  },
  {
    seconds: 1_814_400,
    label: '3',
    unit: 'week',
  },
  {
    seconds: 2_419_200,
    label: '4',
    unit: 'week',
  },
];

export const SECOND_MULTIPLIERS = {
  TO_MINUTES: 60,
  TO_HOURS: 3600,
  TO_DAYS: 86_400,
  TO_WEEKS: 604_800,
};
