import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { OngoingUploadsComponent } from './components/ongoing-uploads/ongoing-uploads.component';
import { OngoingUploadsService } from './services/ongoing-uploads.service';

@NgModule({
  declarations: [OngoingUploadsComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, MarginalsModule, SharedModule],
  providers: [OngoingUploadsService],
})
export class OngoingUploadsModule {}
