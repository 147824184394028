<mat-label *ngIf="label">{{ label }}</mat-label>
<mat-form-field appearance="outline" placeholder="test">
  <mat-icon matPrefix *ngIf="prefixIcon">{{ prefixIcon }}</mat-icon>
  <textarea *ngIf="type === 'textarea'" rows="{{rows}}" matInput [formControl]="control"></textarea>
  <input
    [readonly]="useAsTrigger"
    *ngIf="!autocomplete && !matDatePicker && type !== 'textarea'"
    [type]="inputType"
    matInput
    [formControl]="this.control"
    [placeholder]="placeholder"
    (keyup.enter)="onEnter()"
    [value]="valueTransformer(this.control.value)"
  />
  <input
    *ngIf="autocomplete"
    [type]="inputType"
    matInput
    [formControl]="this.control"
    [placeholder]="placeholder"
    (keyup.enter)="onEnter()"
    [matAutocomplete]="autocomplete"
    [value]="valueTransformer(this.control.value)"
  />
  <input
    *ngIf="matDatePicker"
    [type]="inputType"
    matInput
    [formControl]="this.control"
    [placeholder]="placeholder"
    (keyup.enter)="onEnter()"
    [matDatepicker]="matDatePicker"
  />
  <mat-datepicker-toggle matPrefix *ngIf="this.matDatePicker" [for]="matDatePicker"></mat-datepicker-toggle>

  <mat-icon matSuffix *ngIf="suffixIcon">{{ suffixIcon }}</mat-icon>
  <mat-icon matSuffix *ngIf="clear || matDatePicker" class="clear-icon-button" (click)="[$event.stopPropagation(), control.setValue(null), clearedInput()]"
    >close</mat-icon
  >
  <mat-error *ngIf="this.control.invalid">
    {{ errorMessage }}
  </mat-error>
  <ng-content select="[selectOptions]"></ng-content>
</mat-form-field>
