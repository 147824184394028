import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { DeviceApiService } from '../services/device-api.service';
import { DeviceActivity } from './DeviceActivity';

export class DeviceActivityLogDataSource extends DataSourceBaseImpl<DeviceActivity> {
  constructor(private deviceApi: DeviceApiService) {
    super();
  }
  public loadDeviceActivity(
    deviceId: string,
    activityType: string | null,
    from: Date | null,
    until: Date | null,
    range: number | null,
    limit: number | null
  ) {
    this.loadingSubject.next(true);
    this.dataSubject.next([]);
    this.errorSubject.next(null);
    this.deviceApi
      .getDeviceActivityLog(deviceId, activityType, from, until, range, limit)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((resp) => {
        this.dataSubject.next(resp);
      });
  }
}
