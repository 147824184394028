<app-asc-text-input
  #searchInput
  class="search-input"
  (cleared)="clearInput()"
  name="optionSelect"
  [label]="optionSelectProps.label"
  [control]="searchControl"
  *ngIf="optionSelectProps.searchable"
  type="search"
  [matMenuTriggerFor]="optionsMenu"
  [clear]="true"
  (blured)="blurInput()"
>
</app-asc-text-input>

<div class="input-error-wrapper" *ngIf="optionSelectProps.error">
  <div *ngIf="isError$ | async">{{ optionSelectProps.error }}</div>
</div>

<mat-menu #optionsMenu="matMenu" class="asc-dropdown" [ngStyle]="{ minWidth: minWidth + 'px' }">
  <ng-template matMenuContent>
    <div class="select-option" *ngFor="let option of filteredData$ | async" (click)="optionSelected(option)">
      <mat-icon class="search-icon" *ngIf="optionSelectProps.searchable">search</mat-icon> {{ optionSelectProps.selectLabelPredicate(option) }}
    </div>
  </ng-template>
</mat-menu>
