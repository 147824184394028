import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { SortDirection } from '@angular/material/sort';
import moment, { Moment } from 'moment';
import { Observable, shareReplay } from 'rxjs';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { DateRangePickerResponse, DateRangeRelativeResponse } from 'src/app/asc-forms/interfaces/DateRangePickerResponse';
import { DateRangeUtils } from 'src/app/asc-forms/interfaces/DateRangeUtils';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { toNormalizedString } from 'src/app/utils/string-utils';
import { UserActivity } from '../../interfaces/UserActivity';
import { UserActivityColumn, UserActivityDataSource } from '../../interfaces/UserActivityDataSource';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-user-activity-log',
  templateUrl: './user-activity-log.component.html',
  styleUrls: ['./user-activity-log.component.css'],
})
export class UserActivityLogComponent implements OnDestroy, StorageOperations {
  tableColumns: ConfigureableTableColumn<UserActivity>[] = [
    {
      name: 'timestamp',
      header: 'Timestamp',
      sortable: false,
    },
    {
      name: 'user',
      header: 'User',
      sortable: false,
    },
    {
      name: 'activity',
      header: 'Activity',
      sortable: false,
    },
    {
      name: 'message',
      header: 'Message',
      sortable: false,
    },
  ];
  filterFormGroup: FormGroup<FilterFormGroup>;
  activityTypeControl = new FormControl<string | null>(null);
  userControl = new FormControl<string | null>(null);
  activityTypes$: Observable<string[]>;
  activityTypeConfig: AscAutocompleteProperties<string> = {
    humanizeOption: (val) => val,
    equals: (a, b) => a === b,
    filterPredicate: (val, input) => val.toLowerCase().startsWith(input.toLowerCase()),
    placeholder: 'Activity Type',
  };

  sortActive: UserActivityColumn | '' = '';
  sortDirection: SortDirection = 'asc' as SortDirection;

  dataSource: UserActivityDataSource;

  selectedRange: { label: string; seconds: number } | null = null;
  timeRangeControl = new FormControl('');

  dateRange: DateRange<Moment | null> = new DateRange(null, null);
  relativeRange: DateRangeRelativeResponse | null = null;
  rangeButtonLabel: string;
  datePickerIndex = 0;
  dateRangeUtils: DateRangeUtils;
  noDataMessage: string | undefined = 'Select a time range and start the search.';
  rowExpandable = (activity: UserActivity) => {
    if (!activity.activityDetails) return false;
    return Object.keys(activity.activityDetails).filter((key) => key !== 'message').length > 0;
  };
  columnIdentifier = (activity: UserActivity) => `${activity.activityType}-${activity.user}-${activity.timestamp.getTime()}`;

  constructor(private loggingService: LoggingService, private datePipe: DatePipe, private store: AppStorageService) {
    this.activityTypes$ = this.loggingService.getUserActivityTypes().pipe(shareReplay());
    this.dateRangeUtils = new DateRangeUtils(this.datePipe);
    this.dataSource = new UserActivityDataSource(this.loggingService);

    this.getStorageData();
    window.onbeforeunload = () => this.saveStorageData();
  }

  loadData(fromDate: Date | null, toDate: Date | null, rangeSeconds: number | null, activityType: string | null, userName: string | null) {
    this.noDataMessage = undefined;
    this.dataSource.loadUserActivity(activityType, userName, null, fromDate, toDate, rangeSeconds, 1000);
  }

  getStorageData(): void {
    const index = this.store.getValue<number>('user-activity-log_date-range-picker-index');
    if (index) this.datePickerIndex = index;
    const startDateStr = this.store.getValue<string>('user-activity-log_date-range_start');
    const endDateStr = this.store.getValue<string>('user-activity-log_date-range_end');
    if (startDateStr && endDateStr) {
      this.dateRange = new DateRange(moment(new Date(startDateStr)), moment(new Date(endDateStr)));
      if (this.datePickerIndex === 0) this.timeRangeControl.setValue(this.dateRangeUtils.getAbsoluteLabel(this.dateRange));
    }
    const relativeRange = this.store.getValue<DateRangeRelativeResponse>('user-activity-log_date-range_relative');
    if (relativeRange) {
      this.relativeRange = relativeRange;
      if (this.datePickerIndex === 1) this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    }
  }
  saveStorageData(): void {
    this.store.setValue('user-activity-log_date-range-picker-index', this.datePickerIndex, 'number', true);
    if (this.dateRange.end && this.dateRange.start) {
      this.store.setValue('user-activity-log_date-range_start', this.dateRange.start.toDate().toISOString(), 'string', true);
      this.store.setValue('user-activity-log_date-range_end', this.dateRange.end.toDate().toISOString(), 'string', true);
    }
    if (this.relativeRange) {
      this.store.setValue('user-activity-log_date-range_relative', this.relativeRange, 'object', true);
    }
  }

  ngOnDestroy(): void {
    this.saveStorageData();
  }

  applyFilter() {
    this.loadUserActivitiesFiltered();
  }

  loadUserActivitiesFiltered() {
    this.dataSource.loadUserActivity(
      this.filterFormGroup.controls.userActivity.value || '',
      this.filterFormGroup.controls.userName.value || null,
      null,
      null,
      null,
      null,
      1000
    );
  }

  getSessionStorageData(): SessionStorageDataUserActivities {
    const { userActicityType, userName } = {
      userActicityType: sessionStorage.getItem('userActivityType'),
      userName: sessionStorage.getItem('userName'),
    };

    return {
      userActicity: userActicityType || null,
      userName: userName || null,
    };
  }

  startSearch() {
    if (this.datePickerIndex === 0 && this.dateRange.start && this.dateRange.end)
      this.loadData(this.dateRange.start.toDate(), this.dateRange.end.toDate(), null, this.activityTypeControl.value, this.userControl.value);
    if (this.datePickerIndex === 1 && this.relativeRange)
      this.loadData(null, null, this.relativeRange.seconds, this.activityTypeControl.value, this.userControl.value);
  }
  getRelativeRange(relative: DateRangePickerResponse<'relative'>) {
    this.relativeRange = relative.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    this.datePickerIndex = 1;
  }
  getAbsoluteRange(absolute: DateRangePickerResponse<'absolute'>) {
    this.dateRange = absolute.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getAbsoluteLabel(this.dateRange));
    this.datePickerIndex = 0;
  }
  enableSearchButton() {
    return (this.datePickerIndex === 0 && this.dateRange.start && this.dateRange.end) || (this.datePickerIndex === 1 && this.relativeRange);
  }
  getExpandableData(activity: UserActivity) {
    if (!activity.activityDetails) return [];
    return Object.keys(activity.activityDetails)
      .filter((key) => key !== 'message')
      .map((key) => ({ key: toNormalizedString(key), value: activity.activityDetails[key] }));
  }
}

interface SessionStorageDataUserActivities {
  userActicity: string | null;
  userName: string | null;
}

interface FilterFormGroup {
  userActivity: FormControl<string | null>;
  userName: FormControl<string | null>;
}
