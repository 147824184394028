import { DeviceStatus, NextDeviceStatusFromCurrent } from './interfaces/Device';

export const NEXT_DEVICE_STATUS: NextDeviceStatusFromCurrent = {
  PROVISIONED: [
    {
      actionName: 'Connect',
      nextStatus: DeviceStatus.REGISTRATION_ID_ISSUED,
      action: 'CONNECT',
      matIcon: 'power',
    },
    {
      actionName: 'Attach',
      nextStatus: DeviceStatus.PROVISIONED,
      deviceOrSatellite: 'SATELLITE',
      matIcon: 'login',
      action: 'ATTACH',
    },
    {
      actionName: 'Deactivate',
      nextStatus: DeviceStatus.BLOCKED,
      action: 'DEACTIVATE',
      matIcon: 'block',
    },
    {
      actionName: 'Detach',
      nextStatus: DeviceStatus.PROVISIONED,
      deviceOrSatellite: 'SATELLITE',
      matIcon: 'logout',
      action: 'DETTACH',
    },
    {
      actionName: 'Edit',
      nextStatus: DeviceStatus.PROVISIONED,
      matIcon: 'edit',
      action: 'UPDATE',
    },
  ],
  BLOCKED: [
    {
      actionName: 'Activate',
      nextStatus: DeviceStatus.PROVISIONED,
      matIcon: 'power_settings_new',
      action: 'ACTIVATE',
    },
    {
      actionName: 'Decommission',
      nextStatus: DeviceStatus.DECOMISSIONED,
      matIcon: 'delete',
      action: 'DECOMISSION',
    },
  ],

  REGISTRATION_ID_ISSUED: [
    {
      actionName: 'Reactivate',
      nextStatus: DeviceStatus.REGISTRATION_ID_ISSUED,
      matIcon: 'refresh',
      action: 'REACTIVATE',
    },
    {
      actionName: 'Cancel',
      nextStatus: DeviceStatus.PROVISIONED,
      matIcon: 'cancel',
      action: 'CANCEL',
    },
    {
      actionName: 'Edit',
      nextStatus: DeviceStatus.REGISTRATION_ID_ISSUED,
      matIcon: 'edit',
      action: 'UPDATE',
    },
  ],

  CERTIFICATE_ISSUED: [
    {
      actionName: 'Reactivate',
      nextStatus: DeviceStatus.REGISTRATION_ID_ISSUED,
      matIcon: 'refresh',
      action: 'REACTIVATE',
    },
    {
      actionName: 'Cancel',
      nextStatus: DeviceStatus.PROVISIONED,
      matIcon: 'cancel',
      action: 'CANCEL',
    },
    {
      actionName: 'Edit',
      nextStatus: DeviceStatus.CERTIFICATE_ISSUED,
      matIcon: 'edit',
      action: 'UPDATE',
    },
  ],
  REGISTERED: [
    {
      actionName: 'Disconnect',
      nextStatus: DeviceStatus.PROVISIONED,
      matIcon: 'power_off',
      action: 'DISCONNECT',
    },
    {
      actionName: 'Edit',
      nextStatus: DeviceStatus.REGISTERED,
      matIcon: 'edit',
      action: 'UPDATE',
    },
  ],
};
