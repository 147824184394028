import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {}
  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // skip adding Authorization for preSignedUrl upload API
    if (httpRequest.headers.get('Content-MD5') || httpRequest.headers.get('x-no-auth')) {
      return next.handle(httpRequest);
    } else {
      const token = this.auth.getToken();
      const authReq = httpRequest.clone({
        headers: httpRequest.headers.set('Authorization', token ? token : '').set('content-type', 'application/json'),
      });

      return next.handle(authReq).pipe(
        catchError(
          (err) =>
            new Observable<HttpEvent<never>>((observer) => {
              if (err instanceof HttpErrorResponse && err.status === 401) {
                this.router.navigate(['login']).then();
              }
              observer.error(err);
              observer.complete();
            })
        )
      );
    }
  }
}
