import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Moment } from 'moment';
import { Observable, Subscription, catchError, of, shareReplay } from 'rxjs';
import { AscAutocompleteProperties } from 'src/app/asc-forms/interfaces/AscAutocompleteProperties';
import { DateRangePickerResponse, DateRangeRelativeResponse } from 'src/app/asc-forms/interfaces/DateRangePickerResponse';
import { DateRangeUtils } from 'src/app/asc-forms/interfaces/DateRangeUtils';
import { Device } from 'src/app/device/interfaces/Device';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AppStorageService } from 'src/app/shared/app-storage.service';
import { ConfigPageEvent } from 'src/app/shared/components/asc-configurable-table/asc-configurable-table.component';
import { DetailsDialogComponent, DetailsDialogData } from 'src/app/shared/components/details-dialog/details-dialog.component';
import { ConfigureableTableColumn } from 'src/app/shared/interfaces/ConfigureableTableColumn';
import { StorageOperations } from 'src/app/shared/interfaces/StorageOperations';
import { DhsJobStatusHelper, getDhsJobInfo } from '../../interfaces/DhsJobUtils';
import { FailedJobsDataSource } from '../../interfaces/FailedJobsDataSource';
import { FailedJob } from '../../interfaces/Job';
import { FailedJobsApiService } from '../../services/failed-jobs-api.service';

@Component({
  selector: 'app-device-failed-job',
  templateUrl: './device-failed-job.component.html',
  styleUrls: ['./device-failed-job.component.css'],
})
export class DeviceFailedJobComponent implements OnInit, StorageOperations, OnDestroy {
  @Input() device: Device;

  dataSource: FailedJobsDataSource;

  jobTypeSearchProps: AscAutocompleteProperties<string> = {
    humanizeOption: (val) => val,
    filterPredicate: (val, search) => val.toLowerCase().includes(search.toLowerCase()),
    equals: (a, b) => a === b,
    placeholder: 'Job Type',
    multiple: true,
  };
  jobTypeControl = new FormControl<string[]>([]);
  jobTypes$: Observable<string[]>;

  errorTypeSearchProps: AscAutocompleteProperties<string> = {
    humanizeOption: (val) => val,
    filterPredicate: (val, search) => val.toLowerCase().includes(search.toLowerCase()),
    equals: (a, b) => a === b,
    placeholder: 'Error Type',
  };
  addExpandableRow: (job: FailedJob) => boolean = (job) => job.type === 'DHS_NOTIFICATION';
  errorTypeControl = new FormControl<string>('');
  fileNameControl = new FormControl<string>('');
  errorTypes$: Observable<string[]>;

  subs$: Subscription = new Subscription();

  pageables: ConfigPageEvent;
  sort: Sort;
  timeRangeControl = new FormControl('');
  dateRange: DateRange<Moment | null> = new DateRange(null, null);
  relativeRange: DateRangeRelativeResponse | null = null;
  rangeButtonLabel: string;
  datePickerIndex = 0;
  dateRangeUtils: DateRangeUtils;

  failedAfter: string | undefined = undefined;
  failedBefore: string | undefined = undefined;
  getDhsJobInfo = getDhsJobInfo;
  dhsHelper: DhsJobStatusHelper;

  selectedRange: { label: string; seconds: number } | null = null;

  tableColumns: ConfigureableTableColumn<FailedJob>[] = [
    {
      sortable: true,
      name: 'type',
      header: 'Job Type',
    },
    {
      sortable: true,
      name: 'errorType',
      header: 'Error Type',
    },
    {
      sortable: true,
      name: 'failedAt',
      header: 'Failed At',
    },
    {
      sortable: false,
      name: 'actions',
      header: 'Actions',
    },
  ];

  constructor(
    private failedJobsApi: FailedJobsApiService,
    private store: AppStorageService,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private toasts: ToastsService
  ) {
    this.dhsHelper = new DhsJobStatusHelper(this.failedJobsApi);
    this.dateRangeUtils = new DateRangeUtils(this.datePipe);
    this.dataSource = new FailedJobsDataSource(failedJobsApi);
    this.getStorageData();
    this.jobTypes$ = this.failedJobsApi.getJobTypes().pipe(shareReplay());
    this.errorTypes$ = this.failedJobsApi.getJobErrorTypes().pipe(shareReplay());

    this.subs$.add(
      this.jobTypeControl.valueChanges.subscribe(() => {
        this.loadData();
      })
    );
    this.subs$.add(
      this.errorTypeControl.valueChanges.subscribe(() => {
        this.loadData();
      })
    );
  }
  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataSource.loadFailedJobs(
      { page: this.pageables.pageIndex, size: this.pageables.pageSize, sort: `${this.sort.active},${this.sort.direction}` },
      {
        deviceSerialNumber: this.device.serialNumber,
        deviceType: this.device.deviceType,
        jobType: this.jobTypeControl.value || undefined,
        failedAfter: this.failedAfter,
        failedBefore: this.failedBefore,
        errorType: this.errorTypeControl.value || undefined,
        fileName: this.fileNameControl.value || undefined,
      }
    );
  }
  getStorageData(): void {
    this.sort = {
      active: this.store.getValue('device_failedjobs_sort_column') || '',
      direction: this.store.getValue('device_failedjobs_sort_direction') || '',
    };
    this.pageables = {
      pageSize: this.store.getValue('device_failedjobs_pagination_pagesize') || 10,
      pageIndex: 0,
      pageSizeOptions: [10, 20, 50],
    };
  }
  saveStorageData(): void {
    this.store.setValue('device_failedjobs_sort_column', this.sort.active, 'string', true);
    this.store.setValue('device_failedjobs_sort_direction', this.sort.direction, 'string', true);
    this.store.setValue('device_failedjobs_pagination_pagesize', this.pageables.pageSize, 'number', true);
  }

  pagChange(pagEvent: PageEvent) {
    this.pageables = {
      ...this.pageables,
      pageSize: pagEvent.pageSize,
      pageIndex: pagEvent.pageIndex,
    };
    this.loadData();
  }
  sortChange(sortEvent: Sort) {
    this.sort = sortEvent;
    this.loadData();
  }

  showDetails(job: FailedJob) {
    const failedAt = this.datePipe.transform(job.failedAt, 'yyyy-MM-dd, HH:mm');
    const dialogConfig: MatDialogConfig<DetailsDialogData<FailedJob>> = {
      minWidth: '50vw',
      data: {
        dialogTitle: 'Details',
        components: [
          {
            type: 'table',
            title: 'General',
            keyColumnId: 'key_1',
            keyColumnLabel: '',
            valueColumnId: 'value_1',
            valueColumnLabel: '',
            data: [
              {
                value: job.type,
                label: 'Job Type',
              },
              {
                value: job.deviceType,
                label: 'Device Type',
              },
              {
                value: job.deviceSerialNumber,
                label: 'Serial Number',
              },
              {
                value: failedAt ? failedAt : job.failedAt,
                label: 'Failed At',
              },
            ],
          },

          {
            type: 'table',
            title: 'Error',
            keyColumnId: 'key_3',
            keyColumnLabel: '',
            valueColumnId: 'value_3',
            valueColumnLabel: '',
            data: [
              {
                value: job.errorType,
                label: 'Error ID',
              },
              {
                label: 'Error Message',
                value: job.errorMessage,
              },
            ],
          },
        ],
      },
    };
    if (job.fileName && job.fileType && dialogConfig.data)
      dialogConfig.data.components = [
        dialogConfig.data.components[0],
        {
          type: 'table',
          title: 'File',
          keyColumnId: 'key_2',
          keyColumnLabel: '',
          valueColumnId: 'value_2',
          valueColumnLabel: '',
          data: [
            {
              label: 'Name',
              value: job.fileName,
            },
            {
              label: 'Type',
              value: job.fileType,
            },
          ],
        },
        dialogConfig.data.components[1],
      ];
    this.matDialog.open(DetailsDialogComponent, dialogConfig);
  }

  restartJob(job: FailedJob) {
    this.failedJobsApi
      .restartJob(job.id)
      .pipe(
        catchError((err) => {
          this.toasts.raise({ message: `Failed to restart job with ID ${job.id}`, title: 'Restart Job', error: err }, 'ERROR');
          return of();
        })
      )
      .subscribe(() => {
        this.toasts.raise({ message: `Successfully restarted job with ID ${job.id}`, title: 'Restart Job' }, 'SUCCESS', false);
        if (job.type === 'DHS_NOTIFICATION') this.dhsHelper.getJobStatusInterval(job.id);
        this.loadData();
      });
  }

  deleteJob(job: FailedJob) {
    this.failedJobsApi
      .deleteFailedJob(job.id)
      .pipe(
        catchError((err) => {
          this.toasts.raise({ message: `Failed to delete job with ID ${job.id}`, title: 'Delete Job', error: err }, 'ERROR');
          return of();
        })
      )
      .subscribe(() => {
        this.toasts.raise({ message: `Successfully deleted job with ID ${job.id}`, title: 'Delete Job' }, 'SUCCESS', false);
        this.loadData();
        if (job.type === 'DHS_NOTIFICATION') this.dhsHelper.getJobStatus(job.id);
      });
  }
  getRelativeRange(relative: DateRangePickerResponse<'relative'>) {
    this.relativeRange = relative.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    this.datePickerIndex = 1;
    if (relative.range?.seconds) {
      this.failedAfter = new Date(new Date().getTime() - relative.range?.seconds * 1000).toISOString();
      this.failedBefore = new Date().toISOString();
    }
    this.loadData();
  }
  getAbsoluteRange(absolute: DateRangePickerResponse<'absolute'>) {
    this.dateRange = absolute.range;
    this.timeRangeControl.setValue(this.dateRangeUtils.getAbsoluteLabel(this.dateRange));
    this.datePickerIndex = 0;
    if (this.dateRange.start && this.dateRange.end) {
      this.failedAfter = this.dateRange.start.toDate().toISOString();
      this.failedBefore = this.dateRange.end.toDate().toISOString();
    }
    this.loadData();
  }

  clearDateRange() {
    this.dateRange = new DateRange(null, null);
    this.relativeRange = null;
    this.timeRangeControl.setValue(this.dateRangeUtils.getRelativeLabel(this.relativeRange));
    this.failedAfter = undefined;
    this.failedBefore = undefined;
    this.loadData();
  }

  rowExpansionChange(row: { element: FailedJob; expanded: boolean }) {
    if (!row.expanded) return;
    this.dhsHelper.getJobStatus(row.element.id);
  }

  ngOnDestroy(): void {
    this.saveStorageData();
    this.subs$.unsubscribe();
  }
}
