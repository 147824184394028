import { Directive, HostListener, Input } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[appAscTooltip]',
  providers: [MatTooltip],
})
export class AscTooltipDirective {
  tooltip: MatTooltip;
  @Input() appAscTooltip: string;
  @Input() showDelay = 0;
  @Input() hideDelay = 0;
  @Input() tooltipPosition: TooltipPosition = 'below';
  show = false;
  constructor(tooltip: MatTooltip) {
    this.tooltip = tooltip;
    this.tooltip.position = this.tooltipPosition;
    this.tooltip.disabled = true;
  }
  @HostListener('mouseenter') mouseenter() {
    this.tooltip.message = this.appAscTooltip;
    this.tooltip.disabled = true;
    this.show = true;
    setTimeout(() => {
      if (!this.show) return;
      this.tooltip.disabled = false;
      this.tooltip.show();
    }, this.showDelay);
    this.tooltip.show(this.showDelay);
  }
  @HostListener('mouseleave') mouseleave() {
    this.show = false;
    setTimeout(() => {
      this.tooltip.hide();
      this.tooltip.disabled = true;
    }, this.hideDelay);
  }
}
