import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFileSize',
})
export class FormatFileSizePipe implements PipeTransform {
  transform(sizeInBytes?: number | unknown): string {
    if (!sizeInBytes || Number.isNaN(sizeInBytes)) return '';
    const _sizeInBytes = sizeInBytes as number;
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let power = Math.round(Math.log(_sizeInBytes) / Math.log(1024));
    power = Math.min(power, units.length - 1);

    const size = _sizeInBytes / Math.pow(1024, power); // size in new units
    const formattedSize = Math.round(size * 1000) / 1000; // keep up to 2 decimals
    const unit = units[power];

    return `${formattedSize} ${unit}`;
  }
}
