import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DataSourceBase<D = any, Err = Error> {
  loadingSubject: BehaviorSubject<boolean>;
  isLoading$: Observable<boolean>;

  dataSubject: BehaviorSubject<D[]>;
  totalSizeSubject: BehaviorSubject<number>;
  totalSize$: Observable<number>;

  errorSubject: BehaviorSubject<Err | null>;
  isError$: Observable<Err | null>;
}

export abstract class DataSourceBaseImpl<D = unknown, Err = HttpErrorResponse> extends MatTableDataSource<D> implements DataSourceBase<D, Err> {
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean>;
  dataSubject: BehaviorSubject<D[]> = new BehaviorSubject<D[]>([]);
  data$: Observable<D[]>;
  totalSizeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalSize$: Observable<number> = new BehaviorSubject<number>(0);
  errorSubject: BehaviorSubject<Err | null> = new BehaviorSubject<Err | null>(null);
  isError$: Observable<Err | null>;

  constructor() {
    super();
    this.isError$ = this.errorSubject.asObservable();
    this.totalSize$ = this.totalSizeSubject.asObservable();
    this.isLoading$ = this.loadingSubject.asObservable();
    this.data$ = this.dataSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(): void {
    this.errorSubject.complete();
    this.loadingSubject.complete();
    this.dataSubject.complete();
    this.totalSizeSubject.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(): BehaviorSubject<D[]> {
    return this.dataSubject;
  }
}
