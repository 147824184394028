import { DropDown } from '../../software-management/interfaces/DropDown';

export enum ActivationStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum DownloadPolicy {
  AUTOMATIC = 'AUTOMATIC',
  CUSTOMER_INITIATED = 'CUSTOMER_INITIATED',
  FSE_INITIATED = 'FSE_INITIATED',
  DISABLE_DOWNLOAD = 'DISABLE_DOWNLOAD',
}

export const downloadPolicyDropDown: DropDown<DownloadPolicy>[] = [
  { value: DownloadPolicy.AUTOMATIC, viewValue: 'Automatic' },
  { value: DownloadPolicy.CUSTOMER_INITIATED, viewValue: 'Customer Initiated' },
  { value: DownloadPolicy.FSE_INITIATED, viewValue: 'FSE Initiated' },
  { value: DownloadPolicy.DISABLE_DOWNLOAD, viewValue: 'Download Disabled' },
];

export enum ExecutionPolicy {
  AUTOMATIC = 'AUTOMATIC',
  CUSTOMER_INITIATED = 'CUSTOMER_INITIATED',
  ALCON_PERSONNEL_INITIATED = 'ALCON_PERSONNEL_INITIATED',
}

export const executionPolicyDropDown: DropDown<ExecutionPolicy>[] = [
  { value: ExecutionPolicy.AUTOMATIC, viewValue: 'Automatic' },
  { value: ExecutionPolicy.CUSTOMER_INITIATED, viewValue: 'Customer Initiated' },
  { value: ExecutionPolicy.ALCON_PERSONNEL_INITIATED, viewValue: 'Alcon Personnel Initiated' },
];

export interface DownloadableContent {
  displayName: string | null;
  summary: string | null;
  activationStatus: ActivationStatus;
  fileUuid?: string;
  fileName: string | null;
  fileType: string | null;
  fileVersion: string | null;
  fileSize: number;
  deviceTypes: string[];
  applicabilityCriteria: { [key: string]: string };
  checksum: string | null;
  downloadPolicy: DownloadPolicy | null;
  executionPolicy: ExecutionPolicy | null;
  uploadStatus?: string;
  countOfAssignedDevices?: number;
}
