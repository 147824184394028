<div class="config-table" class="mat-elevation-z2">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
    [matSortActive]="this.initSortCol"
    [matSortDirection]="this.initSortDirection"
  >
    <ng-container *ngFor="let column of columns; let i = index">
      <ng-container [matColumnDef]="column.name">
        <ng-container [ngSwitch]="column.sortable">
          <ng-container *ngSwitchCase="true">
            <mat-header-cell *matHeaderCellDef mat-sort-header [appAscTooltip]="column.tooltip ? column.tooltip : ''">{{
              column.header
            }}</mat-header-cell>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="getHeaderCell(i); let headerCell">
                <ng-container [ngTemplateOutlet]="headerCell"></ng-container>
              </ng-container>
              <ng-container *ngIf="getHeaderCell(i) === undefined">
                {{ column.header }}
              </ng-container>
            </mat-header-cell>
          </ng-container>
        </ng-container>
        <ng-template #normalCell let-header="header"></ng-template>
        <mat-cell *matCellDef="let element">
          <!-- Injects passed templates -->
          <ng-container [ngSwitch]="column.name">
            <ng-container *ngSwitchCase="'_expandable_col_'">
              <mat-icon *ngIf="addExpandableRow(element)">{{ expandedRows[colIdentifier(element)] ? 'expand_less' : 'expand_more' }}</mat-icon>
            </ng-container>
            <ng-container
              *ngSwitchDefault
              [ngTemplateOutlet]="columnCells[expandable ? i - 1 : i]"
              [ngTemplateOutletContext]="{ tableElem: element }"
            ></ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="_expandable_row_">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="expand-container" [@detailExpand]="expandedRows[colIdentifier(element)] ? 'expanded' : 'collapsed'">
          <ng-container
            [ngTemplateOutlet]="expandable"
            [ngTemplateOutletContext]="{ tableElem: element, rowExpanded: expandedRows[colIdentifier(element)] }"
          ></ng-container>
        </div>
      </td>
    </ng-container>

    <!-- ROWS -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ hovered: row.hovered, expandableRow: expandable ? true : false }"
      class="table-element-row"
      [class.table-expanded-row]="expandedRows[colIdentifier(row)]"
      (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false"
      (click)="toggleExpandRow(row, expandedRows[colIdentifier(row)])"
    >
    </mat-row>
    <ng-container *ngIf="expandable">
      <tr mat-row *matRowDef="let row; columns: ['_expandable_row_']" class="detail-row"></tr>
    </ng-container>

    <tr *matNoDataRow>
      <ng-container *ngIf="(this.dataSource.isLoading$ | async) === false">
        <div ngClass="c-table-info-error" *ngIf="this.dataSource.isError$ | async" class="c-table-info">
          {{ this.dataSourceError$ | async }}
        </div>
        <div class="c-table-info" *ngIf="(this.dataSource.totalSize$ | async) === 0 && (this.dataSource.isError$ | async) === null">
          {{ noDataMessage || 'No data available!' }}
        </div>
      </ng-container>
      <ng-container *ngIf="(this.dataSource.isLoading$ | async) === true">
        <div class="spinner-wrapper" *ngIf="this.dataSource.isLoading$ | async">
          <mat-spinner class="data-loading-spinner"></mat-spinner>
        </div>
      </ng-container>
    </tr>
  </table>

  <mat-paginator
    *ngIf="pageables"
    [pageSizeOptions]="pageables.pageSizeOptions"
    [pageIndex]="pageables.pageIndex"
    [pageSize]="pageables.pageSize"
    [length]="dataSource.totalSize$ | async"
    showFirstLastButtons
  ></mat-paginator>
</div>
