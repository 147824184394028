import { HttpEvent } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, catchError, lastValueFrom, map, of } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { AscConfirmDialogComponent } from 'src/app/shared/components/asc-confirm-dialog/asc-confirm-dialog.component';
import { AscConfirmDialogData } from 'src/app/shared/interfaces/AscConfirmDialogData';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { DropDown } from 'src/app/software-management/interfaces/DropDown';
import { ErrorHandlerService } from '../../../error-handling/services/error-handler.service';
import {
  ActivationStatus,
  DownloadPolicy,
  DownloadableContent,
  ExecutionPolicy,
  downloadPolicyDropDown,
  executionPolicyDropDown,
} from '../../../shared/interfaces/DownloadableContent';
import { UploadGetPartResponse } from '../../interfaces/UploadGetPartResponse';
import { ChecksumAlgorithm, ChecksumValidationService } from '../../services/checksum-validation.service';
import { SoftwareManagementService } from '../../services/software-management.service';

interface ApplicableCriteriaFormGroup {
  key: FormControl<string>;
  value: FormControl<string>;
}
interface SoftwareLibraryUploadFormGroup {
  displayName: FormControl<string | null>;
  file: FormControl<File | null>;
  fileVersion: FormControl<string | null>;
  fileType: FormControl<string | null>;
  summary: FormControl<string | null>;
  deviceTypes: FormControl<string[]>;
  downloadPolicy: FormControl<DropDown<DownloadPolicy> | null>;
  executionPolicy: FormControl<DropDown<ExecutionPolicy> | null>;
  checksum: FormControl<string | null>;
  applicabilityCriteria: FormArray<FormGroup<ApplicableCriteriaFormGroup>>;
}

@Component({
  selector: 'app-software-library-upload-dialog',
  templateUrl: './software-library-upload-dialog.component.html',
  styleUrls: ['./software-library-upload-dialog.component.css'],
})
export class SoftwareLibraryUploadDialogComponent implements OnInit {
  // Variable to store shortLink from api response
  loading = false; // Flag variable
  file: File;
  displayName: string;
  summary: string;
  fileType: string;
  fileVersion: string;
  deviceTypes: string[];
  checksum: string;
  noFileUploaded = false;
  formComplete = false;
  md5: string;
  uploadProgress = 0;
  uploadStep = '';

  fileTypes: DropDown[] = [
    { value: 'Device Config', viewValue: 'Device Config' },
    { value: 'Software Inventory', viewValue: 'Software Inventory' },
    { value: 'Software Major Update', viewValue: 'Software Major Update' },
    { value: 'Software Minor Update', viewValue: 'Software Minor Update' },
  ];

  downloadPolicy = downloadPolicyDropDown;

  executionPolicy = executionPolicyDropDown;

  availableDeviceTypes$: Observable<DropDown[]>;
  availableDevicesLoadingError$ = new Subject<boolean>();

  applicabilityCriteria: KeyValue[] = [
    { key: 'Operating System', value: '' },
    { key: 'Memory Required', value: '' },
    { key: 'Disk Space Required', value: '' },
  ];

  formGroup: FormGroup<SoftwareLibraryUploadFormGroup>;
  editMode: boolean;

  constructor(
    public dialogRef: MatDialogRef<SoftwareLibraryUploadDialogComponent>,
    private apiService: SoftwareManagementService,
    private sharedApiService: SharedApiService,
    private checksumValidationService: ChecksumValidationService,
    private toasts: ToastsService,
    private errorHandlerService: ErrorHandlerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { editMode: boolean; downloadableContent: DownloadableContent }
  ) {
    this.editMode = this.data.editMode === true;
  }

  ngOnInit(): void {
    this.getDeviceTypeData();
    this.createFormGroup();
  }

  getCriteriaGroupName(index: number) {
    return `criteriaGroup_${index}`;
  }

  getCriteriaFormcontrolName(keyOrValue: 'key' | 'value', index: number) {
    return `${keyOrValue}_${index}`;
  }

  //creating formGroup with provided data
  createFormGroup() {
    const dlc = this.data.downloadableContent;
    let applicableCriteria: KeyValue[];
    if (this.editMode) {
      applicableCriteria = dlc?.applicabilityCriteria
        ? Object.keys(dlc.applicabilityCriteria).map((key) => {
            return { key: key, value: dlc.applicabilityCriteria[key] } as KeyValue;
          })
        : [];
    } else {
      applicableCriteria = this.applicabilityCriteria;
    }
    this.formGroup = new FormGroup<SoftwareLibraryUploadFormGroup>({
      displayName: new FormControl<string | null>(dlc?.displayName || null, { nonNullable: false, validators: Validators.required }),
      file: new FormControl(null, { validators: this.editMode ? null : Validators.required }),
      fileVersion: new FormControl<string | null>(dlc?.fileVersion || null, { nonNullable: false, validators: Validators.required }),
      fileType: new FormControl<string | null>(dlc?.fileType || null, { nonNullable: false, validators: Validators.required }),
      summary: new FormControl<string | null>(dlc?.summary || null, { nonNullable: false, validators: Validators.required }),
      deviceTypes: new FormControl<string[]>(dlc?.deviceTypes || [], { nonNullable: true, validators: Validators.required }),
      downloadPolicy: new FormControl<DropDown<DownloadPolicy> | null>(this.downloadPolicy.find((dp) => dp.value === dlc?.downloadPolicy) || null, {
        nonNullable: true,
        validators: Validators.required,
      }),
      executionPolicy: new FormControl<DropDown<ExecutionPolicy> | null>(
        this.executionPolicy.find((ep) => ep.value === dlc?.executionPolicy) || null,
        { nonNullable: true, validators: Validators.required }
      ),
      checksum: new FormControl<string | null>(
        { value: dlc?.checksum || '', disabled: false },
        { nonNullable: false, validators: Validators.required }
      ),
      applicabilityCriteria: new FormArray(
        applicableCriteria.map(
          (ac) =>
            new FormGroup<ApplicableCriteriaFormGroup>({
              key: new FormControl<string>(ac.key, { nonNullable: true }),
              value: new FormControl<string>(ac.value, { nonNullable: true }),
            })
        )
      ),
    });
    this.formGroup.markAsUntouched;
  }

  getMatError(controlName: keyof SoftwareLibraryUploadFormGroup) {
    return this.formGroup.get(controlName)?.invalid;
  }

  onChange(event: any): void {
    this.noFileUploaded = false;
    this.formGroup.controls.file.setValue(event.target.files[0]);
    this.formGroup.controls.file.markAsDirty();
    this.file = event.target.files[0];
  }
  removeCriteria(): void {
    this.formGroup.controls.applicabilityCriteria.removeAt(this.formGroup.controls.applicabilityCriteria.length - 1);
  }

  addCriteria(): void {
    this.formGroup.controls.applicabilityCriteria.push(
      new FormGroup({
        key: new FormControl<string>('', { nonNullable: true }),
        value: new FormControl<string>('', { nonNullable: true }),
      })
    );
  }

  validateForm(): boolean {
    return this.formGroup.valid;
  }

  getDeviceTypeData(): void {
    this.availableDeviceTypes$ = this.sharedApiService.getDeviceTypeList().pipe(
      map((deviceTypes) => {
        if (deviceTypes.content) {
          return deviceTypes.content
            .map((element) => ({ value: element.deviceType, viewValue: element.deviceType }))
            .sort((a, b) => (a.viewValue.toLowerCase() > b.viewValue.toLowerCase() ? 1 : -1));
        }
        return [];
      }),
      catchError((err) => {
        console.error('Error in device type list API', err);
        this.availableDevicesLoadingError$.next(true);
        this.toasts.raise({ message: "Couldn't load device types", title: 'Load Device Types', error: err }, 'ERROR');
        return of();
      })
    );
  }

  extractApplicableCriteria(): { [key: string]: string } {
    return this.formGroup.controls.applicabilityCriteria.controls.reduce((prev, curr) => {
      prev[curr.controls.key.value] = curr.controls.value.value;
      return prev;
    }, {} as { [key: string]: string });
  }

  displayFn(selectedOption: any) {
    return selectedOption ? selectedOption.viewValue : undefined;
  }

  warnIfFormNotValid(action: 'upload' | 'update') {
    if (!this.validateForm()) {
      const dialogConfig: MatDialogConfig<AscConfirmDialogData<unknown, unknown>> = {
        data: {
          title: `Warning`,
          message: `Required fields are missing. Do you really want to ${action === 'upload' ? 'upload' : 'update'} the file?`,
          dialogType: 'warning',
          action: 'parent-action',
          actionLabel: action === 'upload' ? 'Upload' : 'Update',
        },
      };
      const dialogRef = this.dialog.open(AscConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp.status === 'ACTION') {
          action === 'upload' ? this.onUpload() : this.onUpdate();
        }
      });
    } else {
      action === 'upload' ? this.onUpload() : this.onUpdate();
    }
  }

  onUpdate(): void {
    this.loading = true;
    const criteriaObject = this.extractApplicableCriteria();

    const downloadableContent: DownloadableContent = {
      fileUuid: this.data.downloadableContent.fileUuid,
      fileName: this.data.downloadableContent.fileName,
      fileSize: this.data.downloadableContent.fileSize,
      activationStatus: this.data.downloadableContent.activationStatus,
      displayName: this.formGroup.controls.displayName.value,
      summary: this.formGroup.controls.summary.value,
      fileType: this.formGroup.controls.fileType.value,
      fileVersion: this.formGroup.controls.fileVersion.value,
      deviceTypes: this.formGroup.controls.deviceTypes.value,
      applicabilityCriteria: criteriaObject,
      checksum: this.formGroup.controls.checksum.value ? this.formGroup.controls.checksum.value.toUpperCase() : null,
      downloadPolicy: this.formGroup.controls.downloadPolicy.value?.value || null,
      executionPolicy: this.formGroup.controls.executionPolicy.value?.value || null,
    };

    this.apiService.updateDownloadableContent(downloadableContent).subscribe({
      next: () => {
        this.loading = false;
        this.toasts.raise({ message: 'Content has been updated', title: 'Update Downloadable Content' }, 'SUCCESS', false);
        this.dialogRef.close('SUCCESS');
      },
      error: (error) => {
        this.toasts.raise({ message: 'Unable to update content', title: 'Update Content', error: error }, 'ERROR');
        this.loading = false;
      },
    });
  }

  // OnClick of button Upload
  async onUpload(): Promise<void> {
    this.loading = true;
    const criteriaObject = this.extractApplicableCriteria();
    const file = this.formGroup.controls.file.value;
    let checksumEntered = this.formGroup.controls.checksum.value as string;
    if (!file) {
      this.toasts.raise({ message: 'No file was provided', title: 'Upload File' }, 'ERROR');
      return;
    }
    let checksum = 'NOT_A_VALID_CHECKSUM';
    try {
      checksum = await this.checksumValidationService.calculateChecksum(ChecksumAlgorithm.CRC32, file, (progress) => {
        // multiply with 0.1: 10% of progress bar for calc checksum, 90% of progress bar for upload file parts
        this.uploadProgress = progress * 0.1;
      });
    } catch (err: unknown) {
      this.uploadProgress = 10;
      this.toasts.raise({ message: `Error while calculate checksum: ${err}`, title: 'Calculate Checksum' }, 'WARNING');
    }
    checksumEntered = parseInt(checksumEntered, 16).toString(16);
    if (checksum.toUpperCase() !== checksumEntered.toUpperCase()) {
      this.toasts.raise({ message: `Provided checksum does not match`, title: 'Calculate Checksum' }, 'WARNING');
    }
    this.uploadStep = 'Uploading File';
    //checksum calc done: 10 percent of progress bar filled
    this.uploadProgress = 10;

    const downloadableContent: DownloadableContent = {
      displayName: this.formGroup.controls.displayName.value,
      summary: this.formGroup.controls.summary.value,
      fileType: this.formGroup.controls.fileType.value,
      activationStatus: ActivationStatus.ACTIVE,
      fileName: file.name,
      fileVersion: this.formGroup.controls.fileVersion.value,
      fileSize: file.size,
      deviceTypes: this.formGroup.controls.deviceTypes.value,
      applicabilityCriteria: criteriaObject,
      checksum: this.formGroup.controls.checksum.value ? this.formGroup.controls.checksum.value.toUpperCase() : null,
      downloadPolicy: this.formGroup.controls.downloadPolicy.value?.value || null,
      executionPolicy: this.formGroup.controls.executionPolicy.value?.value || null,
    };

    try {
      const initResp = await lastValueFrom(
        this.apiService.initDownloadableContentUpload({
          fileName: downloadableContent.fileName as string,
          fileVersion: downloadableContent.fileVersion as string,
          displayName: downloadableContent.displayName as string,
          checksum: downloadableContent.checksum as string,
          fileSize: downloadableContent.fileSize,
        })
      ).catch((error) => {
        this.toasts.raise({ message: 'Failed to initalized file upload', title: 'File Upload', error: error }, 'ERROR');
        throw error;
      });
      const chunkSize = initResp.minChunkSize || 100000;
      const fileUuid = initResp.fileUuid || '';
      const uploadId = initResp.uploadId || '';
      let filePart = 1;
      let previousPartUploadPromise: Promise<void> | null = null;

      for (let offset = 0; offset < file.size; offset += chunkSize) {
        const part = await this.getFilePart(file, offset, offset + chunkSize).catch((e) => {
          this.toasts.raise({ message: 'Could not get part upload URL', title: 'File Upload', error: e }, 'ERROR');
          throw e;
        });
        const partDetailsPromise = lastValueFrom(
          this.apiService.getDownloadableContentUploadPartUrl(fileUuid, uploadId, filePart, part.size, part.checksum)
        );

        let partDetails: UploadGetPartResponse;
        if (previousPartUploadPromise) {
          const result = await Promise.all([partDetailsPromise, previousPartUploadPromise]);

          partDetails = result[0];
        } else {
          partDetails = await partDetailsPromise;
        }
        previousPartUploadPromise = this.uploadFilePart(partDetails.preSignedUrl || '', part.file, part.checksum, (event) => {
          if (event.type == 1) {
            // multiply with 0.9: 10% of progress bar for calc checksum, 90% of progress bar for upload file parts
            // add 10: 10 percent of progress already reserved for checksum calculation
            this.uploadProgress = ((offset + event.loaded) / file.size) * 100 * 0.9 + 10;
          }
        }).catch((e) => {
          this.errorHandlerService.handleHttpError(e, 'Failed to upload file part');
          throw e;
        });
        filePart++;
      }
      if (previousPartUploadPromise) await previousPartUploadPromise;
      downloadableContent.fileUuid = fileUuid;
      await lastValueFrom(this.apiService.completeDownloadableContentUpload(fileUuid, uploadId, downloadableContent)).catch((e) => {
        this.errorHandlerService.handleHttpError(e, 'Failed to upload file part');
        throw e;
      });
      this.uploadProgress = 100;
    } catch (error) {
      this.loading = false;
      return;
    }

    this.loading = false;
    this.toasts.raise({ message: 'File ' + this.file.name + ' uploaded', title: 'Upload Files' }, 'SUCCESS', false);
    this.dialogRef.close('SUCCESS');
  }

  uploadFilePart(preSignedUrl: string, partFile: File, partChecksum: string, updateProgress: (event: HttpEvent<object>) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.uploadDownloadableContentPart(preSignedUrl, partFile, partChecksum).subscribe({
        next: updateProgress,
        error: (err) => reject(err),
        complete: () => resolve(),
      });
    });
  }

  async getFilePart(
    inputFile: File,
    startOffset: number,
    endOffset: number
  ): Promise<{
    file: File;
    size: number;
    checksum: string;
  }> {
    const slice = inputFile.slice(startOffset, endOffset);
    const file = new File([slice], inputFile.name, { type: inputFile.type }); // change: depends only on input file
    const checksum = await this.checksumValidationService.calculateChecksum(ChecksumAlgorithm.CRC32, slice, () => {
      console.log('Progress');
    });
    const fromHexString = (hexString: string): Uint8Array => {
      const bytes = hexString.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16)) || [];
      return Uint8Array.from(bytes);
    };
    const crc = fromHexString(checksum);

    return { size: slice.size, file, checksum: btoa(String.fromCharCode(...crc)) };
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  trackBy(index: number): number {
    return index;
  }
}

interface KeyValue {
  key: string;
  value: string;
}
