import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, of } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { DialogResponse } from 'src/app/shared/interfaces/DialogResponse';
import { Device } from '../../interfaces/Device';
import { DeviceApiService } from '../../services/device-api.service';

export interface UpdateDialogData {
  device: Device;
}

@Component({
  selector: 'app-update-device-dialog',
  templateUrl: './update-device-dialog.component.html',
  styleUrls: ['./update-device-dialog.component.css'],
})
export class UpdateDeviceDialogComponent {
  formGroup: FormGroup<UpdateFormGroup> = new FormGroup<UpdateFormGroup>({
    updateDhs: new FormControl<boolean>(false, { nonNullable: true }),
    allowRemoteDesktopAccess: new FormControl<boolean>(false, { nonNullable: true }),
    note: new FormControl('', { nonNullable: true }),
  });

  data: UpdateDialogData;
  loading = false;

  constructor(
    private deviceApi: DeviceApiService,
    @Inject(MAT_DIALOG_DATA) dialogData: UpdateDialogData,
    private toasts: ToastsService,
    private dialogRef: MatDialogRef<UpdateDeviceDialogComponent, Partial<DialogResponse<unknown>>>,
    private deviceService: DeviceApiService
  ) {
    this.data = dialogData;
    this.loading = true;
    this.formGroup.controls.updateDhs.disable();
    this.formGroup.controls.note.setValue(this.data.device.note);
    this.formGroup.controls.updateDhs.setValue(this.data.device.updateDHS);
    this.formGroup.controls.allowRemoteDesktopAccess.setValue(this.data.device.allowRemoteDesktopAccess);
    this.checkIfParentAllowsUpdateDhs();
  }
  checkIfParentAllowsUpdateDhs() {
    if (!this.data.device.parentDeviceId) {
      this.loading = false;
      this.formGroup.controls.updateDhs.enable();
      return;
    }
    this.deviceService
      .getDevice(this.data.device.parentDeviceId, 'v2')
      .pipe(
        catchError(() => {
          if (this.data.device.updateDHS) {
            this.formGroup.controls.updateDhs.enable();
          } else {
            this.formGroup.controls.updateDhs.disable();
          }
          this.toasts.raise(
            { title: 'Cannot get parent device', message: 'Cannot retrieve neccessary information about the parent device to allow updating DHS.' },
            'WARNING'
          );
          return of();
        })
      )
      .subscribe((parent) => {
        this.loading = false;
        this.formGroup.controls.updateDhs.enable();
        // Disable update DHS checkbox for satellite if parents update DHS is set to false
        // Allows unchecking in case update DHS is active for the satellite but not for the parent.
        if (!parent.updateDHS && !this.data.device.updateDHS) {
          this.formGroup.controls.updateDhs.disable();
        }
      });
  }

  actionButtonClicked() {
    this.loading = true;
    this.deviceApi
      .updateDevice(
        {
          id: this.data.device.id,
          note: this.formGroup.controls.note.value,
          allowRemoteDesktopAccess: this.formGroup.controls.allowRemoteDesktopAccess.value,
          updateDHS: this.formGroup.controls.updateDhs.value,
        },
        'v2'
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.toasts.raise(
            { title: 'Edit Device', message: `Could not update device ${this.data.device.deviceType} • ${this.data.device.serialNumber}` },
            'ERROR'
          );
          this.dialogRef.close({ status: 'ERROR', code: err.status });
          return of();
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.toasts.raise(
          { title: 'Edit Device', message: `Updated device ${this.data.device.serialNumber} • ${this.data.device.deviceType}` },
          'SUCCESS',
          false
        );
        this.dialogRef.close({ status: 'SUCCESS', code: 201 });
      });
  }

  closeButtonClicked() {
    this.dialogRef.close();
  }
}

interface UpdateFormGroup {
  updateDhs: FormControl<boolean>;
  allowRemoteDesktopAccess: FormControl<boolean>;
  note: FormControl<string>;
}
