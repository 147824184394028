import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';
import { OktaAuthInit } from '../interfaces/OktaAuthInit';
import { OktaToken } from '../interfaces/OktaToken';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private httpClient: HttpClient) {}

  getAuthUrl(redirectUri: string): Observable<OktaAuthInit> {
    return this.httpClient.post<OktaAuthInit>(`${environment.awsApi.apiBaseUrl}/login/init`, null, {
      params: createHttpParams({ redirect_uri: redirectUri }),
    });
  }
  createTokenFromCode(code: string, state: string, redirectUri: string): Observable<OktaToken> {
    return this.httpClient.post<OktaToken>(
      `${environment.awsApi.apiBaseUrl}/login/token`,
      { code, state },
      { params: createHttpParams({ redirect_uri: redirectUri }) }
    );
  }
}
