import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-asc-icon-button',
  templateUrl: './asc-icon-button.component.html',
  styleUrls: ['./asc-icon-button.component.css'],
})
export class AscIconButtonComponent {
  @Output() btnClicked = new EventEmitter();
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() disabled = false;

  onClick(event: MouseEvent) {
    this.btnClicked.emit(event);
  }
}
