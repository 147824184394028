import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileTransferService } from 'src/app/device-file/services/app-filetransfer.service';
import { minLengthArray } from 'src/app/utils/forms-utils';

/** Error when invalid control is dirty, touched, or submitted. */

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css'],
})
export class FileUploadDialogComponent {
  // Variable to store shortLink from api response
  files: MyFile[];
  noFileUploaded = false;
  formComplete = false;
  uploadProgress = 0;

  formGroup: FormGroup<FileUploadFormGroup>;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { deviceId: string; deviceType: string },
    private fileTransferService: FileTransferService
  ) {
    this.formGroup = new FormGroup({
      files: new FormControl<MyFile[] | null>(null, [Validators.required, minLengthArray(1)])
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange(event: any): void {
    this.noFileUploaded = false;
    this.formGroup.controls.files.setValue(event.target.files);
    this.formGroup.controls.files.markAsDirty();
    this.files = event.target.files;
  }

  // OnClick of button Upload
  async onUpload(): Promise<void> {
    if (this.formGroup.controls.files.invalid) {
      this.dialogRef.close();
      return;
    }

    const files = this.formGroup.controls.files.value || [];
    Array.from(files).forEach((file) => {
      this.fileTransferService.addFileTransfer(this.data.deviceId, this.data.deviceType, file, 'Log File');
    });

    this.dialogRef.close(200);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

interface MyFile extends File {
  webkitRelativePath: string;
}

interface FileUploadFormGroup {
  files: FormControl<MyFile[] | null>;
}
