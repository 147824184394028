import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';
import { FailedJobListPageableProperties, FailedJobsListResponse } from '../interfaces/FailedJobsList';
import { FailedJob } from '../interfaces/Job';
import { JobStatus } from '../interfaces/JobStatus';

@Injectable({
  providedIn: 'root',
})
export class FailedJobsApiService {
  constructor(private httpClient: HttpClient) {}

  getFailedJobs(props: FailedJobListPageableProperties) {
    if (props.sort?.split(',')[0] === '' || props.sort?.split(',')[1] === '') {
      props.sort = '';
    }
    return this.httpClient.get<FailedJobsListResponse>(encodeURI(`${environment.awsApi.apiBaseUrl}/jobs/failed`), {
      params: createHttpParams(props),
    });
  }

  getJobTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.awsApi.apiBaseUrl}/jobs/jobtypes`);
  }

  getJobErrorTypes(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.awsApi.apiBaseUrl}/jobs/errortypes`);
  }

  restartJob(jobId: number): Observable<FailedJob> {
    return this.httpClient.put<FailedJob>(`${environment.awsApi.apiBaseUrl}/jobs/failed/${jobId}`, {});
  }

  deleteFailedJob(jobId: number): Observable<Response> {
    return this.httpClient.delete<Response>(`${environment.awsApi.apiBaseUrl}/jobs/failed/${jobId}`);
  }

  getDhsJobStatus(jobId: number): Observable<JobStatus> {
    return this.httpClient.get<JobStatus>(`${environment.awsApi.apiBaseUrl}/jobs/${jobId}/status`);
  }
}
