import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AscFormsModule } from '../asc-forms/asc-forms.module';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { DeviceFilestorageComponent } from './components/device-filestorage/device-filestorage.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { MessageSnackbarComponent } from './components/message-snackbar/message-snackbar.component';

@NgModule({
  declarations: [FileUploadDialogComponent, DeviceFilestorageComponent, MessageSnackbarComponent],
  imports: [CommonModule, MarginalsModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule, AscFormsModule],
  exports: [DeviceFilestorageComponent],
})
export class DeviceFileModule {}
