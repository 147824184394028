import { catchError, finalize, of } from 'rxjs';
import { LoggingService } from 'src/app/logging/services/logging.service';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { UserActivity } from './UserActivity';

export type UserActivityColumn = keyof UserActivity | 'timestamp';

export class UserActivityDataSource extends DataSourceBaseImpl<UserActivity> {
  constructor(private loggingService: LoggingService) {
    super();
  }
  public loadUserActivity(
    activityType: string | null,
    userName: string | null,
    deviceId: string | null,
    from: Date | null,
    until: Date | null,
    range: number | null,
    limit: number | null
  ) {
    this.loadingSubject.next(true);
    this.dataSubject.next([]);
    this.errorSubject.next(null);
    this.loggingService
      .getUserActivityLog(activityType, deviceId, userName, from, until, range, limit)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((resp) => {
        this.dataSubject.next(resp);
      });
  }
}
