import { catchError, finalize, of } from 'rxjs';
import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { FailedJobsApiService } from '../services/failed-jobs-api.service';
import { FailedJobListPageableProperties, FailedJobListProperties } from './FailedJobsList';
import { FailedJob } from './Job';

export type FailedJobsColumn = keyof FailedJob | 'actions';
export class FailedJobsDataSource extends DataSourceBaseImpl<FailedJob> {
  constructor(private failedJobsApi: FailedJobsApiService) {
    super();
  }

  loadFailedJobs(pageable: FailedJobListPageableProperties = { page: 0, size: 10 }, filters: FailedJobListProperties = {}) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.dataSubject.next([]);

    filters = { ...filters, jobType: Array.isArray(filters.jobType) && filters.jobType.length < 1 ? undefined : filters.jobType };

    this.failedJobsApi
      .getFailedJobs({ ...pageable, ...filters })
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((jobsList) => {
        this.totalSizeSubject.next(jobsList.totalSize);
        this.dataSubject.next(jobsList.content);
      });
  }
}
