<mat-spinner *ngIf="isLoading && !isError" class="c-loading-spinner"></mat-spinner>

<div class="c-main-container" [hidden]="isLoading">
  <div style="display: flex; align-items: flex-end">
    <button mat-mini-fab disabled appAscTooltip="Search Table" style="margin: 20px; margin-left: 100px">
      <mat-icon class="mat-18">filter_alt</mat-icon>
    </button>
    <mat-form-field>
      <mat-label>Device Id</mat-label>
      <input matInput placeholder="Type & press enter" (keyup.enter)="searchDeviceTable()" [(ngModel)]="searchText" />
      <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchInput()" appAscTooltip="Reset search">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <p style="margin-block: auto">&nbsp;</p>
  </div>
  <div class="mat-elevation-z8" style="margin: 0px 100px 25px 100px">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortClicked($event)">
      <!-- Device ID Column -->
      <ng-container matColumnDef="deviceId">
        <mat-header-cell *matHeaderCellDef id="device_id_header" class="c-table-header-style" mat-sort-header> Device ID </mat-header-cell>
        <mat-cell *matCellDef="let element" style="margin-right: 5px">
          {{ element.deviceId }}
        </mat-cell>
      </ng-container>

      <!-- Serial number Column -->
      <ng-container matColumnDef="serialNumber">
        <mat-header-cell *matHeaderCellDef id="serialNumber_header" class="c-table-header-style" mat-sort-header> Serial Number </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.serialNumber }}
        </mat-cell>
      </ng-container>

      <!-- Device Type Column -->
      <ng-container matColumnDef="deviceType">
        <mat-header-cell *matHeaderCellDef id="deviceType_header" class="c-table-header-style" mat-sort-header> Device Type </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.deviceType }}
        </mat-cell>
      </ng-container>

      <!-- Assigned Column -->
      <ng-container matColumnDef="assigned">
        <mat-header-cell *matHeaderCellDef id="assigned_header" class="c-table-header-style" mat-sort-header> Assigned Software </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.assigned }}
        </mat-cell>
      </ng-container>

      <!-- Unassigned Column -->
      <ng-container matColumnDef="unassigned">
        <mat-header-cell *matHeaderCellDef id="unassigned_header" class="c-table-header-style" mat-sort-header>
          Not assigned Software
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.unassigned }}
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef id="actions_header" class="c-table-header-style"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-raised-button
            class="c-primary-button-active"
            style="margin-left: 10px; min-width: 70px"
            appAscTooltip="View which software is assigned or unassigned"
            (click)="showDetails(element)"
          >
            Details
          </button>
        </mat-cell>
      </ng-container>

      <div [ngClass]="{ 'c-table-info-error': isError }" *matNoDataRow class="c-table-info">
        {{ tableInfoText }}
      </div>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 50]"
      [pageSize]="this.pageSize"
      [pageIndex]="this.pageIndex"
      showFirstLastButtons
      [length]="this.totalSize"
      (page)="paginatorClicked($event)"
      class="c-paginator"
    >
    </mat-paginator>
  </div>
</div>
