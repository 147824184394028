import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AscFormsModule } from '../asc-forms/asc-forms.module';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { DeviceFileUploadLogComponent } from './components/device-file-upload-log/device-file-upload-log.component';
import { DeviceLogParserMonitoringComponent } from './components/device-log-parser-monitoring/device-log-parser-monitoring.component';
import { LogParserManagementComponent } from './components/log-parser-management/log-parser-management.component';
import { LogParserMonitoringComponent } from './components/log-parser-monitoring/log-parser-monitoring.component';
import { UserActivityLogComponent } from './components/user-activity-log/user-activity-log.component';

@NgModule({
  declarations: [
    LogParserMonitoringComponent,
    LogParserManagementComponent,
    DeviceFileUploadLogComponent,
    DeviceLogParserMonitoringComponent,
    UserActivityLogComponent,
  ],
  imports: [CommonModule, MaterialModule, MarginalsModule, FormsModule, ReactiveFormsModule, SharedModule, AscFormsModule],
  exports: [DeviceFileUploadLogComponent],
})
export class LoggingModule {}
