import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FailedJobsTabComponent } from './components/failed-jobs-tab/failed-jobs-tab.component';
import { FailedJobManagementTabsComponent } from './components/failed-job-management-tabs/failed-job-management-tabs.component';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserActivitiesTabComponent } from './components/user-activities-tab/user-activities-tab.component';
import { SharedModule } from '../shared/shared.module';
import { DeviceFailedJobComponent } from './components/device-failed-job/device-failed-job.component';
import { AscFormsModule } from '../asc-forms/asc-forms.module';

@NgModule({
  declarations: [FailedJobsTabComponent, FailedJobManagementTabsComponent, UserActivitiesTabComponent, DeviceFailedJobComponent],
  imports: [CommonModule, MarginalsModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule, AscFormsModule],
  providers: [DatePipe],
  exports: [DeviceFailedJobComponent],
})
export class FailedJobManagementModule {}
