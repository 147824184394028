import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AscFormsModule } from '../asc-forms/asc-forms.module';
import { MarginalsModule } from '../marginals/marginals.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { ShowDetailsDialogComponent } from './components/show-details-dialog/show-details-dialog.component';
import { SoftwareLibraryUploadDialogComponent } from './components/software-library-upload-dialog/software-library-upload-dialog.component';
import { SoftwareLibraryComponent } from './components/software-library/software-library.component';
import { SoftwareManagementComponent } from './components/software-management/software-management.component';
import { SoftwarePerDevicesComponent } from './components/software-per-devices/software-per-devices.component';

@NgModule({
  declarations: [
    SoftwareManagementComponent,
    SoftwareLibraryComponent,
    SoftwarePerDevicesComponent,
    ShowDetailsDialogComponent,
    ShowDetailsDialogComponent,
    SoftwareLibraryUploadDialogComponent,
  ],
  imports: [CommonModule, MaterialModule, MarginalsModule, FormsModule, ReactiveFormsModule, SharedModule, AscFormsModule],
})
export class SoftwareManagementModule {}
