import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {
    this.toastr.error("The page you are looking for doesn't exist", 'Page not found', {
      timeOut: 600000,
      positionClass: 'toast-center-center',
      easing: 'ease-in',
      easeTime: 0,
      tapToDismiss: false,
      extendedTimeOut: 600000,
    });
  }

  ngOnDestroy(): void {
    this.toastr.clear();
  }

  goBack() {
    window.history.go(-1);
  }
}
