import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizeList',
})
export class HumanizeListPipe<T = any> implements PipeTransform {
  transform(values: T[], toString: (val: T) => string, cutAt: number): unknown {
    return values.reduce((prev, curr, index) => {
      if (values.length === 1) {
        return toString(values[0]);
      }
      if (index === values.length - 1 && index >= cutAt) {
        return `${prev} + ${values.length - cutAt}`;
      }
      if (index < cutAt && index < cutAt - 1) return `${prev} ${toString(curr)}, `;
      if (index === cutAt - 1) return `${prev} ${toString(curr)} `;
      return prev;
    }, '');
  }
}
