import { Injectable } from '@angular/core';
import * as SparkMD5 from 'spark-md5';
import { Crc32 } from '@aws-crypto/crc32';
import { Crc32c } from '@aws-crypto/crc32c';
import { Sha1 } from '@aws-crypto/sha1-browser';
import { Sha256 } from '@aws-crypto/sha256-browser';

@Injectable({
  providedIn: 'root',
})
export class ChecksumValidationService {
  CHUNK_SIZE = 10485760;

  /**
   * @param { Blob } blob
   * @returns { string } A string containing the checksum in hex format
   *
   */
  calculateChecksum(algorithm: ChecksumAlgorithm, blob: Blob, progressCb: (progress: number) => void): Promise<string> {
    switch (algorithm) {
      case ChecksumAlgorithm.CRC32: {
        return this.calculateHash(blob, new Crc32Digester(), progressCb);
      }
      case ChecksumAlgorithm.CRC32C: {
        return this.calculateHash(blob, new Crc32CDigester(), progressCb);
      }
      case ChecksumAlgorithm.MD5: {
        return this.calculateHash(blob, new Md5Digester(), progressCb);
      }
      case ChecksumAlgorithm.SHA1: {
        return this.calculateHash(blob, new Sha1Digester(), progressCb);
      }
      case ChecksumAlgorithm.SHA256: {
        return this.calculateHash(blob, new Sha256Digester(), progressCb);
      }
      default: {
        throw 'Unknown algorithm';
      }
    }
  }

  calculateHash(blob: Blob, digester: Digester, progressCb: (progress: number) => void): Promise<string> {
    let chain = Promise.resolve();
    for (let offset = 0; offset < blob.size; offset += this.CHUNK_SIZE) {
      const slice = blob.slice(offset, offset + this.CHUNK_SIZE);
      chain = chain.then(() => {
        progressCb((offset / blob.size) * 100);
        return this.processSlice(slice, digester);
      });
    }
    return chain.then(() => digester.digest());
  }

  private processSlice(slice: Blob, digester: Digester): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(slice);

      fileReader.onerror = (): void => {
        reject('hash computation failed - error reading the file');
      };

      fileReader.onload = (e: ProgressEvent<FileReader>): void => {
        if (e.target?.result instanceof ArrayBuffer) {
          digester.update(e.target?.result);
          resolve();
        } else {
          reject('Wrong data type when calculating checksum');
        }
      };
    });
  }

  /**
   * @param { Blob } blob
   * @returns { string } A string containing the checksum in base64 format
   *
   */
  calculateMd5(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(blob);

      fileReader.onerror = (): void => {
        reject('MD5 computation failed - error reading the file');
      };

      fileReader.onload = (e: ProgressEvent<FileReader>): void => {
        if (e.target?.result instanceof ArrayBuffer) {
          spark.append(e.target.result);
          resolve(btoa(spark.end(true)));
        } else {
          reject('Wrong data type when calculating checksum');
        }
      };
    });
  }
}
export enum ChecksumAlgorithm {
  CRC32 = 'CRC32',
  CRC32C = 'CRC32C',
  MD5 = 'MD5',
  SHA1 = 'SHA1',
  SHA256 = 'SHA256',
}

abstract class Digester {
  abstract update(buffer: ArrayBuffer): void;
  abstract digest(): Promise<string>;

  i2hex(i: number): string {
    return ('0' + i.toString(16)).slice(-2);
  }
}

class Md5Digester extends Digester {
  sparkMd5 = new SparkMD5.ArrayBuffer();

  digest(): Promise<string> {
    return Promise.resolve(this.sparkMd5.end(false));
  }

  update(buffer: ArrayBuffer): void {
    this.sparkMd5.append(buffer);
  }
}

class Crc32Digester extends Digester {
  crc32 = new Crc32();

  digest(): Promise<string> {
    return Promise.resolve(this.crc32.digest().toString(16)).then((checksum) => checksum.padStart(8, '0'));
  }

  update(buffer: ArrayBuffer): void {
    this.crc32.update(new Uint8Array(buffer));
  }
}

class Crc32CDigester extends Digester {
  crc32c = new Crc32c();

  digest(): Promise<string> {
    return Promise.resolve(this.crc32c.digest().toString(16)).then((checksum) => checksum.padStart(8, '0'));
  }

  update(buffer: ArrayBuffer): void {
    this.crc32c.update(new Uint8Array(buffer));
  }
}

class Sha1Digester extends Digester {
  sha1 = new Sha1();

  digest(): Promise<string> {
    return this.sha1.digest().then((result) => Array.from(result).map(this.i2hex).join(''));
  }

  update(buffer: ArrayBuffer): void {
    this.sha1.update(new Uint8Array(buffer));
  }
}

class Sha256Digester extends Digester {
  sha256 = new Sha256();

  digest(): Promise<string> {
    return this.sha256.digest().then((result) => Array.from(result).map(this.i2hex).join(''));
  }

  update(buffer: ArrayBuffer): void {
    this.sha256.update(new Uint8Array(buffer));
  }
}
