import { DataSourceBaseImpl } from 'src/app/shared/interfaces/DataSourceBase';
import { FileUploadLog } from './FileUploadLog';
import { LoggingService } from '../services/logging.service';
import { SortDirection } from '@angular/material/sort';
import { catchError, finalize, of } from 'rxjs';

export class DeviceFileUploadLogDataSource extends DataSourceBaseImpl<FileUploadLog> {
  constructor(private logApi: LoggingService) {
    super();
  }
  loadLogs(deviceId: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: SortDirection) {
    this.loadingSubject.next(true);
    this.errorSubject.next(null);
    this.logApi
      .getFileUploadLog(deviceId, pageSize, pageIndex, sortColumn, sortDirection)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of();
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((resp) => {
        if (resp.content) {
          this.dataSubject.next(resp.content);
        }
        this.totalSizeSubject.next(resp.totalSize);
      });
  }
}
