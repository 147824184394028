<app-asc-configurable-table
  [dataSource]="dataSource"
  [columns]="tableColumns"
  [columnCells]="[fileNameTemp, fileSizeTemp, upStartTemp, upEndTemp, upSpeedTemp]"
  [pageables]="pageables"
  [sortProps]="sort"
  (paginatorChange)="pagChange($event)"
  (sortChange)="sortChange($event)"
></app-asc-configurable-table>

<ng-template #fileNameTemp let-tableElem="tableElem">
  {{ tableElem.name.substring(tableElem.name.lastIndexOf('/') + 1) }}
</ng-template>
<ng-template #fileSizeTemp let-tableElem="tableElem">
  {{ tableElem.totalFileSize | formatFileSize }}
</ng-template>
<ng-template #upStartTemp let-tableElem="tableElem">
  {{ tableElem.uploadStart | date : 'yyyy-MM-dd, HH:mm:ss' }}
</ng-template>
<ng-template #upEndTemp let-tableElem="tableElem">
  {{ tableElem.uploadEnd | date : 'yyyy-MM-dd, HH:mm:ss' }}
</ng-template>
<ng-template #upSpeedTemp let-tableElem="tableElem">
  {{ tableElem.uploadSpeed }}
</ng-template>
