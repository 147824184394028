<div class="case_number_container">
  <app-asc-text-input [placeholder]="'Case Number'" prefixIcon="security" [control]=" caseNumber"
    [appAscTooltip]="'Authorize assign or revoke by a case number'"></app-asc-text-input>
</div>

<app-asc-configurable-table [dataSource]="dataSource" [pageables]="pageables" [sortProps]="sort" [columns]="columns"
  [columnCells]="[dNameTemp, fNameTemp, fTypeTemp, fileVersionTemp, fileSizeTemp, actionTemp]"
  (sortChange)="sortChange($event)" (paginatorChange)="pagChange($event)"></app-asc-configurable-table>

<ng-template #dNameTemp let-tableElem="tableElem">{{ tableElem.displayName }}</ng-template>
<ng-template #fNameTemp let-tableElem="tableElem">{{ tableElem.fileName }}</ng-template>
<ng-template #fTypeTemp let-tableElem="tableElem">{{ tableElem.fileType }}</ng-template>
<ng-template #fTypeTemp let-tableElem="tableElem">{{ tableElem.fileType }}</ng-template>
<ng-template #fileVersionTemp let-tableElem="tableElem">{{ tableElem.fileVersion }}</ng-template>
<ng-template #fileSizeTemp let-tableElem="tableElem">{{ tableElem.fileSize | formatFileSize }}</ng-template>
<ng-template #actionTemp let-tableElem="tableElem">
  <div class="actions-wrapper">
    <mat-slide-toggle style="margin-left: 8px" [disabled]="disableToggle" (toggleChange)="toggled(tableElem)"
      [appAscTooltip]="tableElem.assigned ? 'Assgined' : 'Not Assgined'"
      [checked]="tableElem.assigned"></mat-slide-toggle>
    <app-asc-icon-button [disabled]="!tableElem.assigned || tableElem.downloading" [appAscTooltip]="'Download'"
      (btnClicked)="downloadFile(tableElem)">download</app-asc-icon-button>
    <mat-icon *ngIf="tableElem.downloading">downloading</mat-icon>
  </div>
</ng-template>