<div>
  <mat-toolbar style="display: flex; justify-content: center">
    <h3>Upload File</h3>
  </mat-toolbar>
</div>

<div>
  <p style="margin: 30px; text-align: center">Choose a file to upload to the software library</p>
</div>
<form [formGroup]="formGroup">
  <div style="margin: 30px 90px; display: flex; flex-direction: column; justify-content: center">
    <div style="display: flex; flex-direction: row">
      <div style="flex: 3; display: flex; flex-direction: column; margin-right: 10px">
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <div>
            <input style="margin-left: 70px" type="file" *ngIf="!editMode" (change)="onChange($event)" />
            <mat-error
              *ngIf="this.formGroup.controls.file.dirty && this.formGroup.controls.file.invalid"
              style="display: flex; justify-content: center; font-size: small; margin-top: 5px"
            >
              Please choose a File
            </mat-error>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <input matInput placeholder="Display Name" name="displayName" [formControl]="this.formGroup.controls.displayName" />
            <mat-error *ngIf="this.getMatError('displayName')"> Please enter the display name </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <textarea [formControl]="this.formGroup.controls.summary" matInput placeholder="Summary" rows="3"></textarea>
            <mat-error *ngIf="this.getMatError('summary')"> Please enter a summary </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: space-around; margin-top: 50px">
          <mat-form-field appearance="fill">
            <mat-label>File Type</mat-label>
            <input type="text" placeholder="File Type" matInput [formControl]="this.formGroup.controls.fileType" [matAutocomplete]="auto" />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let y of fileTypes" [value]="y.value">{{ y.viewValue }}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="this.getMatError('fileType')"> Please choose a File type </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <input matInput placeholder="Version" name="version" [formControl]="this.formGroup.controls.fileVersion" />
            <mat-error *ngIf="this.getMatError('fileVersion')"> Please enter the version </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <mat-label>Download Policy</mat-label>
            <input
              type="text"
              placeholder="Download Policy"
              matInput
              [formControl]="this.formGroup.controls.downloadPolicy"
              [matAutocomplete]="auto1"
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let y of downloadPolicy" [value]="y">
                {{ y.viewValue }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="this.getMatError('downloadPolicy')"> Please select the download policy </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field>
            <mat-label>Execution Policy</mat-label>
            <input
              type="text"
              placeholder="Execution Policy"
              matInput
              [formControl]="this.formGroup.controls.executionPolicy"
              [matAutocomplete]="auto2"
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let y of executionPolicy" [value]="y">
                {{ y.viewValue }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="this.getMatError('executionPolicy')"> Please select the download policy </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div style="flex: 1"></div>
      <div style="flex: 3; display: flex; flex-direction: column; margin-left: 10px">
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field *ngIf="!editMode">
            <input matInput placeholder="Checksum" name="checksum" [formControl]="this.formGroup.controls.checksum" />
            <mat-error *ngIf="this.getMatError('checksum')"> Please enter the CRC32 checksum of the file </mat-error>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <mat-form-field *ngIf="availableDeviceTypes$ | async as _deviceTypes; else deviceTypesLoadingOrError">
            <mat-label>Device Types</mat-label>
            <mat-select name="deviceTypes" [formControl]="this.formGroup.controls.deviceTypes" multiple>
              <mat-option *ngFor="let deviceType of _deviceTypes" [value]="deviceType.value">
                {{ deviceType.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="this.getMatError('deviceTypes')"> Please enter a device type </mat-error>
          </mat-form-field>
          <ng-template #deviceTypesLoadingOrError>
            <mat-error *ngIf="availableDevicesLoadingError$ | async; else progressBar">Something went wrong: Can not get Device Types</mat-error>
          </ng-template>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; margin-top: 40px">
          <div>Applicability Criteria:</div>

          <div
            *ngFor="let applicableGroup of this.formGroup.controls.applicabilityCriteria.controls"
            style="display: flex; flex-direction: row; flex: 1; margin-top: 20px"
            [formGroup]="applicableGroup"
          >
            <mat-form-field style="margin-right: 20px">
              <input matInput placeholder="Key" formControlName="key" />
            </mat-form-field>
            <mat-form-field style="margin-right: 20px">
              <input matInput placeholder="Value" formControlName="value" />
            </mat-form-field>
          </div>

          <div>
            <button type="button" button mat-mini-fab appAscTooltip="Add one more criteria" style="margin-top: 20px" (click)="addCriteria()">
              <mat-icon class="mat-18">add_circle</mat-icon>
            </button>
            <button
              type="button"
              button
              mat-mini-fab
              appAscTooltip="Remove latest criteria"
              style="margin-top: 20px; margin-left: 20px"
              (click)="removeCriteria()"
            >
              <mat-icon class="mat-18">remove_circle</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 40px" *ngIf="loading">
      {{ uploadStep }}
    </div>
    <div style="display: flex; justify-content: center; margin-top: 40px">
      <mat-progress-bar *ngIf="loading" [value]="uploadProgress" [mode]="this.editMode ? 'indeterminate' : 'determinate'"></mat-progress-bar>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      <button mat-raised-button class="c-primary-button-active" *ngIf="!editMode" (click)="warnIfFormNotValid('upload')" [disabled]="this.loading">
        Upload
      </button>
      <button mat-raised-button class="c-primary-button-active" *ngIf="editMode" (click)="warnIfFormNotValid('update')" [disabled]="this.loading">
        Update
      </button>
      <button mat-raised-button class="c-secondary-button-active" style="margin-left: 50px; text-align: center" (click)="closeDialog()">Close</button>
    </div>
  </div>
</form>

<ng-template #progressBar>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
