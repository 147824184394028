import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription, catchError, finalize, map, of, shareReplay } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { DialogDefaultActions } from 'src/app/shared/interfaces/DialogDefaultActions';
import { AttachDeviceDialogData } from '../../interfaces/AttachDialogData';
import { Device } from '../../interfaces/Device';
import { DeviceApiService } from '../../services/device-api.service';

@Component({
  selector: 'app-attach-device-dialog',
  templateUrl: './attach-device-dialog.component.html',
  styleUrls: ['./attach-device-dialog.component.css'],
})
export class AttachDeviceDialogComponent implements DialogDefaultActions, OnDestroy {
  child: Device;
  parents$ = new BehaviorSubject<Device[] | null>(null);

  loading$ = new BehaviorSubject<boolean>(false);

  selectedParent$ = new BehaviorSubject<null | Device>(null);

  notFound: boolean;
  title: string;

  searchControl = new FormControl('', { nonNullable: true, validators: [Validators.required] });

  subs$: Subscription = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<unknown>,
    private deviceApi: DeviceApiService,
    private toasts: ToastsService,
    @Inject(MAT_DIALOG_DATA) data: AttachDeviceDialogData
  ) {
    this.child = data.child;
    this.title = `Attach Device`;

    this.subs$.add(
      this.searchControl.valueChanges.subscribe(() => {
        this.notFound = false;
      })
    );
  }

  actionButtonClicked(): void {
    const parent = this.selectedParent$.getValue();

    if (!parent) {
      this.toasts.raise({ title: this.title, message: 'Please select a valid parent device' }, 'ERROR');
      return;
    }
    this.loading$.next(true);
    this.deviceApi
      .attachDevice(parent.id, this.child.id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.toasts.raise(
            {
              title: this.title,
              message: `Could not attach device ${this.child.deviceType} • ${this.child.serialNumber} to device ${parent.deviceType} • ${parent.serialNumber}`,
              error: err,
            },
            'ERROR'
          );
          this.dialogRef.close();
          return of();
        }),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe(() => {
        this.toasts.raise(
          {
            title: this.title,
            message: `Attached device ${this.child.serialNumber} • ${this.child.deviceType} to device ${parent.serialNumber} • ${parent.deviceType}`,
          },
          'SUCCESS',
          false
        );

        this.dialogRef.close('SUCCESS');
      });
  }
  closeButtonClicked(): void {
    this.dialogRef.close();
  }

  searchDevice(input: string) {
    this.loading$.next(true);
    this.selectedParent$.next(null);
    this.parents$.next(null);

    this.deviceApi
      .getDeviceList({
        serialNumber: input,
        pageSize: 20,
        pageIndex: 0,
      })
      .pipe(
        shareReplay(),
        map((devices) => {
          this.loading$.next(true);
          if (!devices.content) {
            this.notFound = true;
            return null;
          }
          this.notFound = false;
          return devices.content;
        }),
        catchError(() => {
          return of();
        }),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((resp) => {
        if (!resp) return;

        if (resp.length === 1) {
          this.selectedParent$.next(resp[0]);
        } else {
          this.parents$.next(resp);
        }
      });
  }

  removeParentDevice() {
    this.searchControl.setValue('');
    this.selectedParent$.next(null);
  }

  selectParent(parent: Device) {
    this.selectedParent$.next(parent);
  }

  ngOnDestroy() {
    this.subs$.unsubscribe();
  }
}
