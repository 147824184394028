<sub-header label="Parser monitoring"></sub-header>

<div class="c-flex-wrapper-log-parser">

  <div class="c-main-container">
    <div style="width: 95%; display: flex; margin: 20px auto 40px; justify-content: flex-start; gap: 10px" [formGroup]="filterForm">
      <app-asc-text-input
        [clear]="true"
        (cleared)="clearData()"
        placeholder="Serial Number"
        (keyupEnter)="loadParsingEvents()"
        [control]="filterForm.controls.deviceSerialNumber"
        type="search"
      ></app-asc-text-input>
      <app-asc-text-input
        [clear]="true"
        (cleared)="clearData()"
        placeholder="File Name"
        (keyupEnter)="loadParsingEvents()"
        [control]="filterForm.controls.fileName"
        type="search"
      ></app-asc-text-input>
      <ng-container>
        <app-asc-text-input [matDatePicker]="fromDatePicker" placeholder="From Date (yyyy-mm-dd)" [control]="filterForm.controls.fromEventDate">
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </app-asc-text-input>

        <app-asc-text-input [matDatePicker]="toDatePicker" placeholder="To Date (yyyy-mm-dd)" [control]="filterForm.controls.toEventDate">
          <mat-datepicker #toDatePicker></mat-datepicker>
        </app-asc-text-input>
      </ng-container>

      <ng-container>
        <app-asc-text-input [matDatePicker]="fromEventDetectionDatePicker" placeholder="From Event Detection Date (yyyy-mm-dd)" [control]="filterForm.controls.fromEventDetectionDate">
          <mat-datepicker #fromEventDetectionDatePicker></mat-datepicker>
        </app-asc-text-input>

        <app-asc-text-input [matDatePicker]="toEventDetectionDatePicker" placeholder="To Event Detection Date (yyyy-mm-dd)" [control]="filterForm.controls.toEventDetectionDate">
          <mat-datepicker #toEventDetectionDatePicker></mat-datepicker>
        </app-asc-text-input>
      </ng-container>


      <p style="margin-block: auto">&nbsp;</p>
    </div>
    <app-asc-configurable-table
      class="parsing-event-table"
      [dataSource]="dataSource"
      [columns]="columns"
      [columnCells]="[eventId, deviceSerialNumber,fileName,code,message,eventType,eventTimestamp, eventDetectionTimestamp]"
      [pageables]="pageables"
      [errorMessageFromDS]="true"
      [sortProps]="sort"
      (paginatorChange)="pageChange($event)"
      (sortChange)="sortChange($event)"
      >
    </app-asc-configurable-table>
  </div>

  <ng-template let-tableElem="tableElem" #eventId>
    {{ tableElem.id }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #deviceSerialNumber>
  {{ tableElem.deviceSerialNumber }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #fileName>
    {{ tableElem.fileName }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #code>
  {{ tableElem.code }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #message>
    {{ tableElem.message }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #eventType>
  {{ tableElem.eventType }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #eventTimestamp>
    {{ tableElem.eventTimestamp }}
  </ng-template>
  <ng-template let-tableElem="tableElem" #eventDetectionTimestamp>
    {{ tableElem.eventDetectionTimestamp | date : 'yyyy-MM-dd HH:mm:ss'}}
  </ng-template>

</div>
