import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { BehaviorSubject, catchError, filter, map, of, repeat, switchMap, take } from 'rxjs';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { MessageSnackbarComponent, MessageSnackbarData } from '../components/message-snackbar/message-snackbar.component';
import { FileApiService } from './file-api.service';

@Injectable({
  providedIn: 'root',
})
export class PackagedDownloadService {
  snackBarData = new BehaviorSubject<MessageSnackbarData>({
    message: 'Packaging Files...',
    icon: 'cached',
    iconColor: undefined,
  });
  snackbarRef: MatSnackBarRef<MessageSnackbarComponent> | undefined;
  constructor(private snackBar: MatSnackBar, private fileApi: FileApiService, private toasts: ToastsService) {}

  startPackagingFilesForDownload(selectedFileIdsForDownload: number[], withMetadata?: boolean | null, caseNumber?: string | null) {
    if (!this.snackbarRef) this.snackbarRef = this.snackBar.openFromComponent(MessageSnackbarComponent, { data: this.snackBarData });
    return this.fileApi.startPackagedFilesDownload(selectedFileIdsForDownload, !!withMetadata, caseNumber).pipe(
      catchError(() => {
        this.snackBarData.next({ message: 'Error while packaging files.', icon: 'error', iconColor: '#d2232a' });
        setTimeout(() => {
          if (this.snackbarRef) this.snackbarRef.dismiss();
        }, 3000);
        return of();
      })
    );
  }
  getPackagingFilesStatus(taskId: string, packageName: string | null) {
    if (!this.snackbarRef) this.snackbarRef = this.snackBar.openFromComponent(MessageSnackbarComponent, { data: this.snackBarData });
    return this.fileApi
      .getPackagedDownloadStatus(taskId, packageName)
      .pipe(
        map((stat) => {
          return stat;
        }),
        repeat({ delay: 5000 }),
        filter((status) => status.status !== 'RUNNING'),
        take(1),
        catchError(() => {
          this.snackBarData.next({ message: 'Error while packaging files.', icon: 'error', iconColor: '#d2232a' });
          setTimeout(() => {
            if (this.snackbarRef) this.snackbarRef.dismiss();
          }, 3000);
          return of();
        })
      )
      .pipe(
        switchMap((downloadStatus) => {
          if (!downloadStatus.preSignedDownloadUrl) {
            this.snackBarData.next({ message: 'No download URL was provided', icon: 'error', iconColor: '#d2232a' });
            setTimeout(() => {
              if (this.snackbarRef) this.snackbarRef.dismiss();
            }, 3000);
            this.toasts.raise({ message: 'No download URL was provided', title: 'Failed to download packaged files' }, 'ERROR');
            return of();
          }
          this.snackBarData.next({ message: 'Start downloading package...', icon: 'download', iconColor: '#339bff' });
          setTimeout(() => {
            if (this.snackbarRef) this.snackbarRef.dismiss();
          }, 4000);

          return this.downloadPackage(downloadStatus.preSignedDownloadUrl, packageName);
        })
      );
  }

  private downloadPackage(preSignedUrl: string, packageName: string | null) {
    return this.fileApi.getBlobFromPreSignedUrl(preSignedUrl).pipe(
      map((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        if (packageName) {
          a.download = packageName.split('.')[packageName.split('.').length - 1] === 'zip' ? packageName : packageName + '.zip';
        }

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();
      })
    );
  }
}
